import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';

export class VolumeRange extends Component {
    /**
     * simple component that handles the volume range
     */
    render() {
        const {step, min, max, volume, id} = this.props
        return (
            <div style={volumeControlStyle}>
                <Grid container spacing={2}>
                    <Grid item>
                    <VolumeDown />
                    </Grid>
                    <Grid item xs>
                        <Slider value={volume} step={step} min={min} max={max} onChange={(event, newValue)=>this.props.changingVolume(newValue, id)} aria-labelledby="discrete-slider-small-steps" marks valueLabelDisplay="auto" />
                    </Grid>
                    <Grid item>
                        <VolumeUp />
                    </Grid>
                    <Grid item>
                        <button className="btn btn-link" onClick={()=>this.props.onSubmit(id)}>Set</button>
                        <button className="btn btn-link" onClick={()=>this.props.onCancel(id)}>Cancel</button>

                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const volumeControlStyle = {
    backgroundColor: "#cdf",
    width: "400px",
    borderRadius: "5px",
    padding: "0px 10px",
    margin: "3px"
}

export default VolumeRange
