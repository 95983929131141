import React, { Component } from 'react'
import { Multiselect } from 'multiselect-react-dropdown';
import auth from './helpers/auth'
import axios from 'axios';
import qs from 'qs';

class Filters extends Component {
  /**
   * Renders the page that searches and shows the events.
   */

  // intialitation of the Refs of the multiselect inputs.
  multiselectRefCompanies = React.createRef();
  multiselectRefSites = React.createRef();
  multiselectRefFarms = React.createRef();
  multiselectRefGenerators = React.createRef();
  state = {
    alert: false,
    sitesDisabled: true,
    farmsDisabled: true,
    generatorsDisabled: true,
    optionData: [],
    selectedCompanies: [],
    selectedSites: [],
    selectedFarms: [],
    selectedGenerators: [],
    showGenerators: this.props.showGenerators,
    data: this.props.data,
  }

  /**
   * Removes all the selected items of the companis' multiselect
   */
  resetCompaniesValues() {
    this.multiselectRefCompanies.current.resetSelectedValues();
    this.resetSitesValues()
    let newArray = this.state.optionData
    newArray.map(comp => comp['children'] = [])
    this.setState({
      // sitesDisabled: auth.role_id > 0,
      farmsDisabled: true,
      generatorsData: true,
      selectedCompanies: [],
      optionData: newArray
    })
  }
  /**
   * Removes all the selected items of the sites' multiselect
   */
  resetSitesValues() {
    this.multiselectRefSites.current.resetSelectedValues();
    this.resetFarmsValues()
    this.resetGeneratorsValues()
    let newArray = this.state.optionData
    newArray.map(comp => comp['children'].map(site => site['children'] = []))
    this.setState({
      farmsDisabled: true,
      selectedSites: [],
      optionData: newArray
    })
  }
  /**
   * Removes all the selected items of the farms' multiselect
   */
  resetFarmsValues() {
    this.multiselectRefFarms.current.resetSelectedValues();
    this.resetGeneratorsValues()
    let newArray = this.state.optionData
    newArray.map(comp => comp['children'].map(site => site['children'].map(farm => farm['children'] = [])))
    this.setState({
      generatorsDisabled: true,
      selectedFarms: [],
      optionData: newArray
    })
  }
  /**
   * Removes all the selected items of the generators' multiselect
   */
  resetGeneratorsValues() {
    if (this.props.showGenerators) {
      this.multiselectRefGenerators.current.resetSelectedValues();
      this.setState({
        selectedGenerators: []
      })
    }
  }
  /**
   * Removes all the selected items of the all multiselects
   */
  resetAll() {
    if (auth.role_id <= 1)
      this.resetCompaniesValues()
    this.resetSitesValues()
    this.resetFarmsValues()
    this.resetGeneratorsValues()
    this.setState({
      events: [],
      totalEvents: 0,
      pagingControl: {
        pageSize: 20,
        currentPage: 0,
        lastPage: 0
      },
      showDetails: false,
      statistics: {
        detections: 0,
        deterrences: 0,
        shutdowns: 0
      }
    })
  }
  componentDidMount() {
    // smoothToTop() //Commented for "show more details" rerendering
    this.fetchInitialData()
  }
  /**
   * Fetches the inital data. If the user is admin, the companies are fetched
   * otherwise the sites.
   */
  fetchInitialData() {
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    // let route = auth.role_id > 1 ? "sites" : "companies-lite"
    let route = "companies-lite"
    axios.get(process.env.REACT_APP_API_URL + route, config)
      .then(response => {
        let items = [];
        response.data.map((item) => {
          item['children'] = [];
          items.push(item)
        })
        console.log("Initial data: ", items)
        this.setState({
          optionData: items
        })
        if (auth.role_id > 1) {
          //fetch also the sites
          let newSelectedCompanies = []
          items.map(comp => {
            this.fetchData('company', comp)
            newSelectedCompanies.push(comp)
          })
          this.setState({
            selectedCompanies: newSelectedCompanies
          })
        }
      })
      .catch(error => {
        console.log("Error on fetching initial data")
        console.log(error)
      });
  }
  /**
   * When the user selects an item from a category, then in the next category, the children of the item should be
   * fetched in order to be displayed as a optional values. This is what is happening in this page.
   * @param {String} category The category of the item (companies, sites, farms)
   * @param {Object} item The item
   */
  fetchData = (category, item) => {
    /*  
        ROUTES 
        /companies/{cid}/sites
        /sites/{sid}/farms
        /farms/{fid}/generators
    */
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
    };
    let route;
    if (category === "company") {
      route = "companies/" + item.id + "/sites"
    }
    else if (category === "site") {
      route = "sites/" + item.id + "/farms"
    }
    else if (category === "farm") {
      route = "farms/" + item.id + "/generators"
    }
    axios.get(process.env.REACT_APP_API_URL + route, config)
      .then(response => {
        if (response.data.length === 0) {
          const childname = category === "company" ? "sites" : category === "site" ? "farms" : category === "farm" ? "generators" : "'?'"
          this.setAlert("warning", "No " + childname + " are found for the " + category + ": " + item.name)
          return
        }
        response.data.map((incomingItem) => {
          incomingItem['view_name'] = item['name'] + ": " + incomingItem['name'] // <p><span style={spanStyle}>{item['name'] +":"}</span> {incomingItem['name']}</p>
          incomingItem['children'] = []
          item['children'].push(incomingItem)
        })

        let newArray;
        if (category === "company") {
          //we got sites
          const elementsIndex = this.state.optionData.findIndex(element => element.id === item.id)
          newArray = [...this.state.optionData]
          newArray[elementsIndex]['children'] = item['children']//newArray[elementsIndex] = {...newArray[elementsIndex], children: item['children']}
        }
        else if (category === "site") {
          //we got farms
          const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
          const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.id)
          newArray = [...this.state.optionData]
          newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = item['children']  //{...newArray[elementsIndex]['children'][elementsSitesIndex], children: item['children']} 
        }
        else if (category === "farm") {
          //we got generators
          const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
          const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.site_id)
          const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === item.id)
          newArray = [...this.state.optionData]
          newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = item['children'] //{...newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex], children: item['children']} 
        }
        this.setState({
          optionData: newArray,
        }, () => {
          console.log("New state: ", this.state)
          this.checkDisables()
        })
      })
      .catch(error => {
        console.log("Error on fetching data")
        console.log(error)
      });
  }
  /**
   * When the user searches without to fullfil all the multiselectors, then the datatree of the 
   * selected item should be fetched and added. This is what this fucntion does. The datatree starts 
   * from the latest item category that is filled.
   */
  fetchAllData = () => {
    const { selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
    let params = {}
    if (selectedGenerators.length > 0) {
      return
    }
    else if (selectedFarms.length > 0) {
      const farmsIds = selectedFarms.map(farm => farm.id)
      params["farm_ids"] = farmsIds
    }
    else if (selectedSites.length > 0) {
      const siteIds = selectedSites.map(site => site.id)
      params["site_ids"] = siteIds
    }
    else if (selectedCompanies.length > 0) {
      const compIds = selectedCompanies.map(comp => comp.id)
      params["comp_ids"] = compIds
    } else {
      this.setAlert("warning", "Please, fill the reaquest fields.")
      return
    }
    const requestRoute = process.env.REACT_APP_API_URL + "datatree"
    axios.get(requestRoute, {
      headers: { Authorization: `Bearer ${auth.token}` },
      params,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    }).then(response => {
      let results = response.data
      if (selectedFarms.length > 0) {
        let upldatedSelectedFarms = selectedFarms
        let newSelectedGens = selectedGenerators
        upldatedSelectedFarms.forEach(farm => farm['children'] = results.filter(f => farm.id === f.id)[0]['generators'])
        results.map(farm => farm['generators'].map(gen => newSelectedGens.push(gen)))
        this.setState({
          newSelectedFarms: upldatedSelectedFarms,
          selectedGenerators: newSelectedGens
        })
      }
      else if (selectedSites.length > 0) {
        let updatedSites = selectedSites
        let newSelectedGens = selectedGenerators
        let newSelectedFarms = selectedFarms
        updatedSites.forEach(site => site['children'] = results.filter(s => site.id === s.id)[0]['farms'])
        results.map(site => site["farms"].map(farm => {
          newSelectedFarms.push(farm)
          farm['children'] = farm['generators']
          farm['children'].map(gen => {
            newSelectedGens.push(gen)
          })
        }))
        this.setState({
          selectedSites: updatedSites,
          selectedGenerators: newSelectedGens,
          selectedFarms: newSelectedFarms
        })
      }
      else if (selectedCompanies.length > 0) {
        let updatedCompanies = selectedCompanies
        let newSelectedGens = selectedGenerators
        let newSelectedFarms = selectedFarms
        let newSelectedSites = selectedSites
        updatedCompanies.forEach(comp => comp['children'] = results.filter(c => comp.id === c.id)[0]['sites'])
        results.map(comp => {
          comp['children'] = comp['sites']
          comp['children'].map(site => {
            newSelectedSites.push(site)
            site['children'] = site['farms']
            site['children'].map(farm => {
              newSelectedFarms.push(farm)
              farm['children'] = farm['generators']
              farm['children'].map(gen => {
                newSelectedGens.push(gen)
              })
            })
          })
        })
        this.setState({
          selectedCompaines: updatedCompanies,
          selectedGenerators: newSelectedGens,
          selectedFarms: newSelectedFarms,
          selectedSites: newSelectedSites
        })
      }
    })
      .catch(error => {
        // this.setAlert("danger", "Errorfull request")
        console.log("Got error on loading data tree request")
        console.log(error.response)
      })
  }

  /**
   * Checks which of the multiselects should be disabled or not. 
   */
  checkDisables = () => {

    let { selectedCompanies, selectedSites, selectedFarms } = this.state
    let disables = {
      sitesDisabled: !selectedCompanies.length > 0,
      farmsDisabled: !selectedSites.length > 0,
      generatorsDisabled: !selectedFarms.length > 0
    }
    return disables
  }
  /**
   * Updates and syncs the selected item in the states onSelect event
   * @param {String} category 
   * @param {Array} selectedList 
   * @param {Object} selectedItem 
   */
  onSelect = (category, selectedList, selectedItem) => {
    if (category === 'generator') {
      let selected = this.state.selectedGenerators;
      selected.push(selectedItem)
      this.setState({
        selectedGenerators: selected,
      }, () => { this.renderGeneratorSelect() })
      return
    }
    this.fetchData(category, selectedItem)
    // until now the below is useless
    if (category === "company") {
      let selected = this.state.selectedCompanies;
      selected.push(selectedItem)
      this.setState({
        selectedCompanies: selected
      })
    }
    else if (category === "site") {
      let selected = this.state.selectedSites;
      selected.push(selectedItem)
      this.setState({
        selectedSites: selected
      })
    }
    else if (category === "farm") {
      let selected = this.state.selectedFarms;
      selected.push(selectedItem)
      this.setState({
        selectedFarms: selected
      }, () => { this.renderFarmSelect() });
    }


  }

  renderGeneratorSelect() {
    if (typeof (this.props.onGeneratorSelect) !== "undefined") {
      this.props.onGeneratorSelect(this.state.selectedCompanies, this.state.selectedSites, this.state.selectedFarms, this.state.selectedGenerators);
    }
  }
  renderGeneratorRemove(removeItem) {
    if (typeof (this.props.onGeneratorRemove) !== "undefined") {
      this.props.onGeneratorRemove(removeItem);
    }
  }

  renderFarmSelect() {
    console.log(this.props.onFarmSelect);
    if (typeof (this.props.onFarmSelect) !== "undefined") {
      this.props.onFarmSelect(this.state.selectedCompanies, this.state.selectedSites, this.state.selectedFarms);
    }
  }
  /**
   * Updates and syncs the selected item in the states onRemove event
   * @param {String} category 
   * @param {Array} selectedList 
   * @param {Object} selectedItem 
   */
  onRemove = (category, selectedList, removedItem) => {
    if (category === "company") {
      const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.id)
      let newArray = [...this.state.optionData]
      newArray[elementsIndex]['children'] = []
      let newSelectedCompanies = this.state.selectedCompanies
      newSelectedCompanies = newSelectedCompanies.filter(comp => comp.id !== removedItem.id)
      this.setState({
        optionData: newArray,
        selectedCompanies: newSelectedCompanies
      }, () => {
        console.log("New state: ", this.state)
        // this.checkDisables()
      })
      this.resetSitesValues()
    }
    else if (category === "site") {
      const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
      const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.id)
      let newArray = [...this.state.optionData]
      newArray = [...this.state.optionData]
      newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = []
      let newSelectedSites = this.state.selectedSites
      newSelectedSites = newSelectedSites.filter(site => site.id !== removedItem.id)
      this.setState({
        optionData: newArray,
        selectedSites: newSelectedSites
      }, () => {
        console.log("New state: ", this.state)
        // this.checkDisables()
      })
      this.resetFarmsValues()
    }
    else if (category === "farm") {
      const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
      const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.site_id)
      const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === removedItem.id)
      let newArray = [...this.state.optionData]
      newArray = [...this.state.optionData]
      newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = []
      let newSelectedFarms = this.state.selectedFarms
      newSelectedFarms = newSelectedFarms.filter(farm => farm.id !== removedItem.id)
      this.setState({
        optionData: newArray,
        selectedFarms: newSelectedFarms
      }, () => {
        console.log("New state: ", this.state)
        // this.checkDisables()
        //this.props.onFarmSelect(this.state.selectedCompanies, this.state.selectedSites, this.state.selectedFarms);
      })
      this.resetGeneratorsValues()
    }
    else if (category === "generator") {
      let newSelectedGenerators = this.state.selectedGenerators
      newSelectedGenerators = newSelectedGenerators.filter(gen => gen.id !== removedItem.id)
      this.setState({
        selectedGenerators: newSelectedGenerators
      }, () => {
        console.log("New state: ", this.state)
        // this.checkDisables()
      })
      this.renderGeneratorRemove(removedItem);

    }
  }
  render() {
    const { optionData, selectedCompanies, selectedSites, selectedFarms,
      selectedGenerators, showGenerators } = this.state
    const { sitesDisabled, farmsDisabled, generatorsDisabled } = this.checkDisables()
    let searchDisabled = true
    if (auth.role_id <= 1)
      searchDisabled = selectedCompanies.length <= 0
    else
      searchDisabled = selectedSites.length <= 0

    /** if (this.props.singleSelect) {
       const sitesData = []
       optionData.map(company => company['children'].map(site => sitesData.push(site)))
       const farmsData = []
       sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
     } else {
       const sitesData = []
       optionData.map(company => company['children'].map(site => sitesData.push(site)))
       const farmsData = []
       sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
     } **/
    const sitesData = []
    optionData.map(company => company['children'].map(site => sitesData.push(site)))
    const farmsData = []
    sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
    const generatorsData = []
    farmsData.map(farm => farm['children'].map(gen => generatorsData.push(gen)))
    return (
      <div className="row">
        {auth.role_id <= 1 && <div className={"col mb-3"}>
          <Multiselect
            options={optionData}
            onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "company") : this.onSelect.bind(this, "company")}
            onRemove={this.onRemove.bind(this, "company")}
            displayValue="name"
            placeholder="Company"
            closeIcon="cancel"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRefCompanies}
            singleSelect={(this.props.singleSelect) ?? false}
            selectedValues={(this.props.selectedCompany) ?? []}
          />
        </div>}
        <div className={sitesDisabled ? "col disabled" : "col mb-3"}>
          <Multiselect
            options={sitesData}
            selectedValues={selectedSites.map(site => site)}
            onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "site") : this.onSelect.bind(this, "site")}
            onRemove={this.onRemove.bind(this, "site")}
            displayValue={auth.role_id > 1 ? "name" : "view_name"}
            placeholder="Site"
            closeIcon="cancel"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRefSites}
            singleSelect={(this.props.singleSelect) ?? false}
          />
          {sitesDisabled && <p style={warningStyle}> Unavailable: please select a Company </p >}
          {selectedSites.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetSitesValues()}>Clear</button>}

        </div>
        <div className={farmsDisabled ? "col disabled" : "col mb-3"}>
          <Multiselect
            options={farmsData}
            selectedValues={selectedFarms.map(farm => farm)}
            onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "farm") : this.onSelect.bind(this, "farm")}
            onRemove={this.onRemove.bind(this, "farm")}
            displayValue="view_name"
            placeholder="Farm"
            closeIcon="cancel"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRefFarms}
            singleSelect={(this.props.singleSelect) ?? false}
          />
          {farmsDisabled && <p style={warningStyle}> Unavailable: please select a Site </p >}
          {selectedFarms.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetFarmsValues()}>Clear</button>}

        </div>
        {showGenerators &&
          <div className={generatorsDisabled ? "col disabled" : "col mb-3"}>
            <Multiselect
              options={generatorsData}
              selectedValues={selectedGenerators.map(gen => gen)}
              onSelect={(this.props.singleSelect) ? this.onSelectSingle.bind(this, "generator") : this.onSelect.bind(this, "generator")}
              onRemove={this.onRemove.bind(this, "generator")}
              displayValue="view_name"
              placeholder="Generator"
              closeIcon="cancel"
              avoidHighlightFirstOption={true}
              ref={this.multiselectRefGenerators}
              singleSelect={(this.props.singleSelect) ?? false}
            />
            {generatorsDisabled && <p style={warningStyle}> Unavailable: please select a Farm </p >}
            {selectedGenerators.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetGeneratorsValues()}>Clear</button>}
          </div>
        }
      </div>
    )
  }
}
export default Filters;

const warningStyle = { color: "#7a7979" }