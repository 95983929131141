import React, { Component } from "react";
import LittleSpinner from './LittleSpinner'

class SiteCard extends Component {
    /**
     * Site that contains details about a site.
     */
    render() {
        const type = this.props.class
        const { header, number, footer, statisticsSpinner } = this.props


        const successStyle = (type === "success") ? "success-text" : "";
        const warningStyle = (type === "warning") ? "warning-text" : ""

        return (
            <div className={`card mb-3 mx-auto bg-${type}`} style={CardStyle}>

                <div className={`card-header bg-${type} border-${type} text-left text-dark  ${successStyle} ${warningStyle}`}>
                    {header}
                </div>

                <div className="card-body text-white">
                    <div className="card-text text-right">{statisticsSpinner && <LittleSpinner />}</div>
                    <h4 className="card-text text-right">{!statisticsSpinner && number}</h4>
                </div>

                <div className={` ${footer ? "card-footer text-right" : ""}`}>
                    <small className="text-muted">{footer}</small>
                </div>

            </div>
        );
    }
}

const CardStyle = {
    maxWidth: "25rem"
};


export default SiteCard;
