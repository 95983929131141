import React, { Component } from "react";
import FarmsPerSite from "../FarmsPerSite";
import { DateRangePicker } from '../DateRangePicker'
import Filters from '../Filters';

import axios from 'axios'
import qs from 'qs';

import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import Spinner from '../Spinner'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlay } from "@fortawesome/free-solid-svg-icons";

export class CompanyData extends Component {
    /**
    * Contains for each site of the company, a generator summary components and
    * a event summary components which can be parameterised to a date range
    */
    state = {
        showCalendar: false,
        dateRange: [],
        company_data: {},
        spinner: true,
        error: false,
        data: {},
    }

    showCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }
    onChangeDate = (date) => {
        if (date) {
            this.setState({
                dateRange: date
            }, () => this.fetchOverAllData())
        } else {
            this.showCalendar();
        }
    }
    componentDidMount() {
        smoothToTop();
        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()
        this.setState({
            dateRange: [yesterday, today]
        }, () => this.fetchOverAllData())
    }

    fetchOverAllData() {
        //set the correct day
        const { dateRange } = this.state
        let params = {}
        params["from"] = dateRange[0].getFullYear() + "-" + (dateRange[0].getMonth() + 1) + "-" + dateRange[0].getDate()
        params["to"] = dateRange[1].getFullYear() + "-" + (dateRange[1].getMonth() + 1) + "-" + dateRange[1].getDate()
        console.log("overall data for date range: ", params['from'], "--", params['to'])
        this.setState({
            spinner: true
        })

        //set the header
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        };
        const cid = this.props.match.params.id
        axios.get(process.env.REACT_APP_API_URL + 'fetch_companys_data/' + cid, config)
            .then(response => {
                this.setState({
                    company_data: response.data,
                    spinner: false,
                    showCalendar: false
                }, () => { this.initFirstCompany() })
            })
            .catch(error => {
                console.log("Error found");
                console.log(error)
                this.setState({
                    error: true,
                    spinner: false

                })
            });
    }
    initFirstCompany() {
        console.log('in first company');
        let tmpCompanyData = { ...this.state.company_data };

        if (tmpCompanyData.sites.length > 0) {
            let companySites = tmpCompanyData.sites;
            tmpCompanyData.sites = [];
            tmpCompanyData.sites.push({ ...companySites[0] });
            if (tmpCompanyData.sites[0].farms.length > 0) {
                tmpCompanyData.sites[0].farms = [];
                tmpCompanyData.sites[0].farms.push({ ...companySites[0].farms[0] });
            }
        }
        console.log('company initial data');
        console.log(tmpCompanyData);
        this.setState({
            data: tmpCompanyData
        })

    }
    renderOverview = (selectedCompanies, selectedSites, selectedFarms) => {
        let company = { ...this.state.company_data }
        let companyData = {};
        selectedCompanies.map((selectedCompany, selectedCompanyIndex) => {
            if (selectedCompany.id == company.company_id) {
                companyData.sites = [];
                selectedSites.map((selectedSite, selectedSiteIndex) => {
                    //companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms = [];
                    if (company.sites.length > 0) {
                        company.sites.map((site, siteIndex) => {
                            if (selectedSite.id == site.id && selectedSite.company_id == company.company_id) {
                                //companyData[selectedCompanyIndex].sites[selectedSiteIndex] = { ...site };
                                companyData.sites.push({ ...site });
                                companyData.sites[selectedSiteIndex].farms = [];
                                selectedFarms.map((selectedFarm, selectedFarmIndex) => {
                                    if (site.farms.length > 0) {
                                        site.farms.map((farm, farmIndex) => {
                                            if (selectedFarm.id == farm.id && selectedSite.company_id == company.company_id && selectedFarm.site_id == site.id) {
                                                companyData.sites[selectedSiteIndex].farms.push({ ...farm });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }

        });
        this.setState({
            data: companyData
        });
    }
    render() {
        const { data, company_data, spinner, error, showCalendar, dateRange } = this.state
        console.log(data);
        if (spinner)
            return (
                <Spinner />
            )

        if (error)
            return (
                <h2 className="no-data">No data found</h2>
            )

        let canAdd = '';
        if (auth.role_id <= 1) {
            canAdd =
                <div className="col text-right" style={addStyle}>
                    <div className="container" style={{ marginRight: "0" }}>
                        {auth.role_id <= 1 && <Link to={"/registersite/" + this.props.match.params.id} className="btn btn-primary ">
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="d-none d-lg-inline"> Add Site</span>
                        </Link>}
                    </div>
                </div>
        }

        let results =
            <div className="container-inner-wrapper">
                <div className="row">
                    <div>
                        <h1 className="col my-title "> {company_data.company_name}
                            <span className="date-picker-opener-with-title" >
                                {!showCalendar &&
                                    <button onClick={this.showCalendar} style={{ paddingLeft: "0px" }}>
                                        <div className="date-picker-opener">
                                            <span className="choose-date pull-left">Choose Date</span>
                                            <span className="pull-right"><FontAwesomeIcon icon={faPlay} size={"sm"} /></span>

                                        </div>
                                    </button>
                                }
                                {showCalendar &&
                                    <div>
                                        <DateRangePicker onChangeDate={this.onChangeDate} date={dateRange} />
                                    </div>
                                }
                            </span>
                        </h1>
                    </div>
                    {canAdd}
                </div>
                <hr />
                <Filters onFarmSelect={this.renderOverview} selectedCompany={[]} />
                {(data.sites && data.sites.length > 0) ?

                    data.sites.map(site =>
                        <FarmsPerSite site={site} item={"summaries"} id={site.id} key={site.id} company_name={data.company_name} changeCurrentPlaceholder={this.props.changeCurrentPlaceholder} />
                    )
                    : <h2 className="no-data">No sites found</h2>
                }

            </div>

        return (
            <div>
                {results}
            </div>
        )
    }
}


const addStyle = {
    paddingTop: "1.6%"
}


export default CompanyData
