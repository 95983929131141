import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class Warning extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="alert alert-warning"  role="alert"> {/* style={alertStyel} */}
                <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} style={{marginRight: "10px"}}/>
                Please, be careful while sending new configurations.
            </div>
         );
    }
}

const alertStyel = { margin: "0 20%", padding: "25px auto" }
 
export default Warning;