import React, { Component } from 'react'

export class Spinner extends Component {
    /**
     * Main application's spinner
     */
    render() {
        return (
            <div className="text-center text-light">
                <div className="spinner-border  my-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>

                <p> Fetching data..</p>
            </div>
        )
    }
}

export default Spinner
