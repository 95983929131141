import React, { Component } from 'react'
import axios from 'axios'
import auth from './helpers/auth'
import Spinner from './Spinner'
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faFire } from "@fortawesome/free-solid-svg-icons";



export class EventClipModal extends Component {

    state = {
        // view percentage length
        viewWidth: 0.8,
        spinner: true,
    }

    /**
     * On mount of the component a get request is done in order to get the clips that
     * can be displayed for the event
     */
    componentDidMount() {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        let eid = this.props.eventid
        const url = process.env.REACT_APP_API_URL + 'events/' + eid + '/clips'
        axios.get(url, config)
            .then(response => {
                console.log("event-modal resp:: ", response.data)
                const event = response.data.event
                const clips = response.data.clips
                this.setState({
                    eventid: eid,
                    event: event,
                    clips: clips,
                    error: false,
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                this.setState({
                    eventid: eid,
                    error: true,
                    errorLog: error.response.data.error,
                    spinner: false
                })
            });
    }

    /**
     * Renders the content of the modal
     */
    render() {
        const { spinner, eventid, clips, error } = this.state
        const evt = this.state.event

        if (spinner)
            return <Spinner />

        let modalBody
        if (!error) {
            modalBody =
                <div className="text-center" style={modalBodyStyle}>
                    <table className="table events-table" style={{ width: String(this.state.viewWidth * 100) + "%", textAlign: "center", margin: "0 auto" }}>
                        <tbody>
                            <tr style={bgColor}>
                                <th className="d-none d-md-table-cell">Generator</th>
                                <th className="d-none d-md-table-cell">Camera</th>
                                <th className="d-none d-md-table-cell">Timestamp</th>
                                <th className="d-none d-md-table-cell">Event Type</th>
                                <th className="d-none d-md-table-cell">Duration</th>
                            </tr>
                            <tr>
                                <td className="text-lg-center text-left"><span className="d-lg-none">Generator: </span>{evt.generator.name}</td>
                                <td className="text-lg-center text-left"><span className="d-lg-none">Camera: </span>{evt.camera.name}</td>
                                <td className="text-lg-center text-left"><span className="d-lg-none">Timestamp: </span>{evt.timestamp}</td>
                                <td className="text-lg-center text-left"><span className="d-lg-none">Event Type: </span>{evt.event_type}</td>
                                <td className="text-lg-center text-left"><span className="d-lg-none">Duration: </span>{evt.duration}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ margintTop: "25px" }}>
                        {clips.map(clip =>
                            <div style={{ margintTop: "25px" }}>
                                <ReactPlayer url={clip.url} width="50%" height="50%" style={videoPlayerStyle} controls={true} />
                                <table className="table events-table" style={{ width: "40%", textAlign: "center", margin: "15px auto" }}>
                                    <tbody>
                                        <tr style={bgColor}>
                                            <th className="d-none d-md-table-cell">Filename</th>
                                            <th className="d-none d-md-table-cell">Camera Type</th>
                                            <th className="d-none d-md-table-cell"></th>
                                        </tr>
                                        <tr>
                                            <td className="text-lg-center text-left"><span className="d-lg-none">Filename: </span>{clip.filename}</td>
                                            <td className="text-lg-center text-left"><span className="d-lg-none">Camera Type: </span>
                                                {clip.filename.includes('rgb') ? "RGB" : "Thermal"}
                                            </td>
                                            <td className="text-lg-center text-left">
                                                <FontAwesomeIcon
                                                    className="float-lg-right float-none"
                                                    icon={clip.filename.includes('rgb') ? faStar : faFire}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
        }
        else
            modalBody =
                <div style={modalBodyStyle}>
                    <p>{this.state.errorLog}</p>
                </div>
        return (
            <div className="text-center modalWindow" style={{ marginBlock: "50px" }}>
                <h1 className="my-title-centered"> Event #{eventid ? eventid : " - "} </h1>
                <hr styel={{ margin: "5% 0" }} />
                {modalBody}
                <button className="btn btn-danger" onClick={() => this.props.onRequestClose()}> Close </button>
            </div>
        )
    }
}


const modalBodyStyle = {
    marginTop: "50px",
}
const bgColor = {
    backgroundColor: "#f4f4f6"
}
const videoPlayerStyle = {
    marginBottom: "25px",
    marginTop: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#000"
}

export default EventClipModal
