import React, { Component } from 'react'
import UsersList from '../UsersList'
import DevsList from '../DevsList'

import axios from 'axios'
import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import { Company } from '../helpers/models'
import Spinner from '../Spinner'


class Users extends Component {
    /**
     * Renders the page that displays the user grouped by their role. The amdin
     * sees the all the users grouped by their companies. 
     * 
     * The admin and the client admin can remove a user from there or to add a new one
     * in each role.
     */
    state = {
        companies: {},
        developes: {},
        spinner: true,
        alert: false,
        alertMsg: '',
        alertClass: ''
    }

    setAlert = (type, msg, fetch = false) => {
        this.setState({
            alert: true,
            alertClass: "alert alert-" + type + " text-center",
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => {
            this.setState({
                alert: false,
                alertClass: '',
                alertMsg: '',
            })
            if (fetch)
                this.refetch()
        }, 2000)
    }

    componentDidMount() {
        smoothToTop()
        document.title = "BMS App - Users"

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const id = this.props.match.params.id;
        let route = id ? "companies/" + id + "/users" : "users"
        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                let companies = [];
                if (id) {
                    companies.push(new Company(response.data['simple']))
                }
                else {
                    response.data['simple'].map(company => {
                        companies.push(new Company(company))
                    })
                }
                let developers = response.data['developers'];

                this.setState({
                    companies: companies,
                    developers: developers,
                    spinner: false
                });
                smoothToTop();
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false

                })
            });
    }

    refetch = () => {
        smoothToTop()
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        this.setState({
            companies: {},
            developes: {},
            spinner: true
        });
        const id = this.props.match.params.id;
        let route = id ? "companies/" + id + "/users" : "users"
        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {

                let companies = [];
                if (id) {
                    companies.push(new Company(response.data['simple']))
                }
                else {
                    response.data['simple'].map(company => {
                        companies.push(new Company(company))
                    })
                }
                let developers = response.data['developers'];
                this.setState({
                    companies: companies,
                    developers: developers,
                    spinner: false
                });
                smoothToTop();
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false
                })
            });
    }

    render() {
        const { companies, developers, spinner, alertClass, alertMsg } = this.state
        if (spinner)
            return (
                <Spinner />
            )

        let results = <h2 className="no-data">No companies found</h2>
        if (companies.length) {
            results = companies.map((company) => (<UsersList company={company} key={company.id} setAlert={this.setAlert} />))
        }
        // let dev_results ={developers && <DevsList developers={developers} setAlert={this.setAlert} />}

        return (
            <div className="container-inner-wrapper">
                <h2 className="my-title"> Users </h2>
                <hr />
                {this.state.alert && <div style={alertStyle} className={alertClass} role="alert">{alertMsg}</div>}
                <div>
                    {results}
                </div>
                <div>
                    {auth.role_id <= 1 && !this.props.match.params.id && <DevsList developers={developers} setAlert={this.setAlert} />}
                </div>

            </div>
        )
    }
}

const alertStyle = {
    margin: "0 20%"
}

export default Users
