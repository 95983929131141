import React, { Component } from 'react'
import ReactPlayer from "react-player";
import auth from '../../helpers/auth'
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faFire } from "@fortawesome/free-solid-svg-icons";

export class ClipPreview extends Component {
    state = {
        buttonsEnable: true,
        videoUrl: this.props.videoUrl
    }
    /**
     * Implemets the request to create a video with the bounding box over the 
     * detection
     * @param {object} clip 
     */
     /***
    requestBBoxClip(clip){
        const clipId = clip.id
        const laClips = this.state.clips

        console.log("requesting bbox-clip for: ", clip)
        laClips.filter(clip => clip.id == clipId)[0].visualised.onRequest = true

        this.setState({
            clips: laClips,
            buttonsEnable: false
        })
        // Assuming clipID === mediaID
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        }
        axios.post(process.env.REACT_APP_API_URL + 'media/' + clipId + '/visualise_clip', {}, config)
            .then(response => {
                console.log("SUCCESS: got response")
                console.log(response.data)
                const laClips = this.state.clips

                laClips.filter(clip => clip.id == clipId)[0].visualised = { onRequest: false, isComplete: true, path: response.data['s3_path'] }
                this.setState({
                    clips: laClips,
                    buttonsEnable: true

                })
                // this.downloadVisClip(clip.filename, response.data['s3_path'])
            })
            .catch(error => {
                console.log("ERROR: got error")
                console.log(error)
                const laClips = this.state.clips

                laClips.filter(clip => clip.id == clipId)[0].visualised.onRequest = false 
                this.setState({
                    clips: laClips,
                    buttonsEnable: true

                })
            });
    }
    **/
    /**
     * Starts a download with the video
     * @param {string} clipname 
     * @param {string} s3Url 
     */
    downloadVisClip = (s3Url) => { 
        const link = document.createElement('a');
        link.href = s3Url;
        link.setAttribute('download', "cctv_video.dav"); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    /**
     * Displays the clip part of the event details
     */
    render() {
        const { buttonsEnable, videoUrl } = this.state
        return (
            <div >
                <div style={{"marginTop": "10px", "width": "100%"}}>
                    <h2 className="my-title2">Video</h2>
                </div>
                <div className="row">
                <ReactPlayer url={videoUrl} width="100%"  style={videoPlayerStyle} controls={true} />
                    
                </div>
                <div className="col">
                    <p style={{float: "right"}}> To download video click <button className="btn btn-link"
                                                                 onClick={() => this.downloadVisClip( videoUrl )}>here</button></p>
                </div>

            </div>
        )

    }
}

const bgColor = {
    backgroundColor: "#f4f4f6"
}
const videoPlayerStyle={
    marginBottom: "5px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor:"#000"
}

export default ClipPreview
