import React, { Component } from 'react'
import ReactPlayer from "react-player";
import auth from '../../helpers/auth'
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faFire } from "@fortawesome/free-solid-svg-icons";

export class ClipPreview extends Component {
    state = {
        buttonsEnable: true,
        clips: this.props.clips
    }
    /**
     * Implemets the request to create a video with the bounding box over the 
     * detection
     * @param {object} clip 
     */
    requestBBoxClip(clip) {
        const clipId = clip.id
        const laClips = this.state.clips

        console.log("requesting bbox-clip for: ", clip)
        laClips.filter(clip => clip.id == clipId)[0].visualised.onRequest = true

        this.setState({
            clips: laClips,
            buttonsEnable: false
        })
        // Assuming clipID === mediaID
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        }
        axios.post(process.env.REACT_APP_API_URL + 'media/' + clipId + '/visualise_clip', {}, config)
            .then(response => {
                console.log("SUCCESS: got response")
                console.log(response.data)
                const laClips = this.state.clips

                laClips.filter(clip => clip.id == clipId)[0].visualised = { onRequest: false, isComplete: true, path: response.data['s3_path'] }
                this.setState({
                    clips: laClips,
                    buttonsEnable: true

                })
                // this.downloadVisClip(clip.filename, response.data['s3_path'])
            })
            .catch(error => {
                console.log("ERROR: got error")
                console.log(error)
                const laClips = this.state.clips

                laClips.filter(clip => clip.id == clipId)[0].visualised.onRequest = false
                this.setState({
                    clips: laClips,
                    buttonsEnable: true

                })
            });
    }
    /**
     * Starts a download with the video
     * @param {string} clipname 
     * @param {string} s3Url 
     */
    downloadVisClip = (clipname, s3Url) => {
        const link = document.createElement('a');
        link.href = s3Url;
        link.setAttribute('download', "vis_" + clipname); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    /**
     * Displays the clip part of the event details
     */
    render() {
        const { buttonsEnable, clips } = this.state
        return (
            <div >
                <div style={{ "marginTop": "10px", "width": "100%" }}>
                    <h2 className="my-title2">Clips</h2>
                </div>
                <div className="row">
                    {clips.map(clip =>
                        <div style={{ margintTop: "25px" }} className="col-xl-6 col-md-6 col-sm-12" key={clip.id}>
                            <ReactPlayer url={clip.url} width="100%" style={videoPlayerStyle} controls={true} />
                            <table className="table events-table" style={{ width: "100%", textAlign: "center", margin: "5px auto" }}>
                                <tbody>
                                    <tr style={bgColor}>
                                        <th className="d-none d-md-table-cell">Filename</th>
                                        <th className="d-none d-md-table-cell">Camera Type</th>
                                        <th className="d-none d-md-table-cell"></th>
                                        <th className="d-none d-md-table-cell">Visualised Video</th>
                                    </tr>
                                    <tr>
                                        <td><span className="d-lg-none font-weight-bold">Filename: </span>{clip.filename}</td>
                                        <td>
                                            <span className="d-lg-none font-weight-bold">Camera Type: </span>{clip.filename.includes('rgb') ? "RGB" : "Thermal"}
                                        </td>
                                        <td>
                                            <FontAwesomeIcon
                                                className="float-lg-right float-none"
                                                icon={clip.filename.includes('rgb') ? faStar : faFire}
                                            />
                                        </td>
                                        <td>
                                            <span className="d-lg-none font-weight-bold">Visualised Video: </span>
                                            <button className={buttonsEnable ? "btn btn-primary btn-sm" : "btn btn-primary btn-sm disabled"}
                                                onClick={() => this.requestBBoxClip(clip)}>
                                                Request
                                                {
                                                    clip.visualised.onRequest && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginLeft: "5px" }} />
                                                }
                                            </button>
                                            {
                                                clip.visualised.onRequest && <p><span className="text-warning">This will take a while!</span></p>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {clip.visualised.isComplete &&
                                <ReactPlayer url={clip.visualised.path} width="100%" style={videoPlayerStyle} controls={true} />
                            }
                        </div>
                    )}
                </div>
                {/* <br/><br/><br/><br/><br/><br/><br/>
                <div className="row">
                    {pathS3RGB !== "" && <div  className="col-xl-6 col-md-6 col-sm-12">
                        <ReactPlayer url={pathS3RGB} width="100%" height="100%" style={videoPlayerStyle} controls={true} />
                    </div>}
                    {pathS3Thermal !== "" && <div className="col-xl-6 col-md-6 col-sm-12">
                        <ReactPlayer url={pathS3Thermal} width="100%" height="100%" style={videoPlayerStyle} controls={true} />
                    </div>}
                </div> */}

            </div>
        )

    }
}

const bgColor = {
    backgroundColor: "#f4f4f6"
}
const videoPlayerStyle = {
    marginBottom: "5px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#000"
}

export default ClipPreview
