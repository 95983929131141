import React, { Component } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router'
import FormUserDetails from './RegisterCompany/FormUserDetails'


import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'

export class RegisterUser extends Component {
    /**
     * A component to create and store a new user for a company
     */
    state = {
        registerType: this.props.registerType,
        alert: false,
        msg: '',
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        speciality: '',
        password: '',
        company: {},
        fetched: false,
        userToken: '',
        success:false,
    }

    componentDidMount(){
        smoothToTop();
        document.title = "BMS App - Register User"
    }

    handleChange = input => e => {
        this.setState({ [input]: e.target.value })
    }

    setPassword = (pswd) => {
        this.setState({ password: pswd })
    }

    handleErrorResponse = (error_response) => {
        console.log("ERROR: => ", error_response)
        let header = "Errors on:"
        let details = []

        for(var key in error_response){
            header += ' ' + key + ',';
            error_response[key].forEach( msg => {
                console.log(msg)
                details.push(msg)
            })
        }
        header = header.substring(0, header.length -1)
        let error_header = <p> {header} </p>
        let error_details = <ul> <strong>Details:</strong> {details.map(msg => <li>{msg}</li> )} </ul>
        let error_message = <div>{error_header} {error_details}</div>
        this.setAlert('alert alert-danger', error_message) // text-center
    }

    setAlert = (type, msg) => {
        this.setState({
            alert: true,
            alertClass: type,
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => this.setState({
            alert: false,
            alertClass: '',
            alertMsg: '',
        }), 3000)
    }

    findCompany = () => {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.get(process.env.REACT_APP_API_URL + 'companies/' + this.props.match.params.id, config)
            .then(response => {
                this.setState({
                    company: response.data,
                    fetched: true
                })
            })
            .catch(error => {
                console.log("ERROR: on fetching companies")
                console.log(error)
            });
    }

    registerUser = () => {
        const { registerType } = this.state
        const role = registerType === "user" ? 3 : 4 // 3-> simple user 4-> ornithologist
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const data = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            speciality: this.state.speciality,
            company_id: this.state.company.id,
            suggested_role: role
        }
        axios.post(process.env.REACT_APP_API_URL + 'users', data, config)
            .then(response => {
                const user_data = response.data
                this.setState({
                    userToken: user_data.access_token,
                });
                this.setAlert('alert alert-success', 'User ' + this.state.username + ' registered')
                setTimeout(
                    () => this.setState({success: true}),
                    1000
                )
                this.sendVerificationEmail()
            })
            .catch(error => {
                console.log(error.response.data)
                this.handleErrorResponse(error.response.data.error)
            });
    }

    sendVerificationEmail = () => {
        const userToken = this.state.userToken
        //validation request
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + userToken,
            }
        };
        console.log("Sending verification")
        axios.get(process.env.REACT_APP_API_URL + 'email/resend', config)
            .then(response => {
                console.log("success")
                this.setAlert('alert alert-success', 'Verification Email sent')
            })
            .catch(error => {
                console.log("ERROR: got error valid")
                console.log(error.response)
                this.setState({ sendVerificationEmailFlag: false })
                this.setAlert('alert alert-danger', 'Verification Email could not be sent')
            });
        this.setState({
            username: '',
            email: '',
            firstName: '',
            lastName: '',
            speciality: '',
            password: '',
        })

    }

    render() {
        if (!this.state.fetched)
            this.findCompany()
        const {
            username, firstName,
            lastName, email,
            speciality, password,
            registerType
        } = this.state

        const values = {
            username, firstName,
            lastName, email,
            speciality, password
        }

        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} role="alert">{this.state.alertMsg}</div> //alert alert-danger text-center

        let content;
        if (!this.state.success)
            content =                 
            <FormUserDetails
                handleChange={this.handleChange}
                setPassword={this.setPassword}
                setAlert={this.setAlert}
                register={this.registerUser}
                imgStyle={imgStyle}
                values={values}
                step={false}
            />
        else
            content = <Redirect to={"/companies/"+ this.props.match.params.id +"/users"}/>




        return (
            <div className="my-form" style={cardStyle}>
                <h2 className="text-center"> Register {registerType === "user" ? "User" : "Ornithologist"} </h2>
                <h4 className="text-center" style={this.props.secTitleStyle}>
                    {this.state.company.name}
                </h4>
                <br />
                <div>{alert}</div>
                {content}
            </div>
        )
    }
}

const cardStyle = {
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "5%",
    marginLeft: "20%",
    marginRight: "20%",
}

const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "200px",
    height: "200px",
    maxWidth: "100%",
    minWidth: "200px",
    margin: "0, 25%",
    marginBottom: "1%",
}

// const secTitleStyle = {
//     color: "#6a6a6a",
// }


export default RegisterUser
