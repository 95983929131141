import React, { Component } from 'react'
import axios from 'axios'
import auth from '../../helpers/auth'
import { Redirect } from 'react-router';

class Reset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: props.match.params.token,
            email: '',
            password: '',
            password_confirmation: '',
            redirect: false,
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const url = process.env.REACT_APP_API_URL + 'password/reset';
        const { token, email, password, password_confirmation } = this.state;

        if (password !== password_confirmation) {
            console.log("not same passowrd")
            this.setState({ err: 2 })
            return
        }
        else if (password.length < 8) {
            console.log("passowrd too small")
            this.setState({ err: 3 })
            return
        }
        axios.post(url, {
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation
        })
            .then(response => {
                this.setState({ err: 1 });
                auth.logout(() => console.log("logging out"));
            })
            .catch(error => {
                this.refs.password.value = "";
                this.refs.email.value = "";
                this.refs.confirm.value = "";
                this.setState({ err: 4 });
                console.log(error)
            });
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });


    }
    // this.props.match.params.token

    render() {

        let error = this.state.err;
        let msg, name;
        if (error === 1) {
            msg = "Password Successfully reset"
            name = 'alert alert-success'
            setTimeout( () => this.setState({redirect:true}), 3000);
        }
        else if (error === 2) {
            msg = "Passwords not the same"
            name = 'alert alert-danger'
        }
        else if (error === 3) {
            msg = "Passwords must be at least 8 characters"
            name = 'alert alert-danger'
        }
        else if (error === 4) {
            msg = "'Oops! Something went wrong'"
            name = 'alert alert-danger'
        }
        if(this.state.redirect)
            return( <Redirect to="\login"/>)

        return (
            <div className="my-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto">
                            <div className="card">

                                <div className="card-header">
                                    <h2 className="card-title">Reset Password</h2>
                                </div>

                                <div className="card-body">
                                    {/* className="col-md-offset-2 col-md-8 col-md-offset-2" */}
                                    <div>
                                        {error > 0 && <div className={name} role="alert">{msg}</div>}
                                    </div>
                                    <form className="form-horizontal" method="POST" onSubmit={this.onSubmit.bind(this)}>
                                        <div className="form-group mx-auto">
                                            <label htmlFor="email" className="col-md-8 control-label">E-Mail Address</label>

                                            <div>{/*className="col-md-6"*/}
                                                <input
                                                    id="email"
                                                    type="email"
                                                    className="form-control"
                                                    ref="email"
                                                    name="email"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.email}
                                                    required
                                                    autoFocus
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="password" className="col-md-8 control-label">New Password</label>
                                            <div>
                                                <input
                                                    id="password"
                                                    type="password"
                                                    className="form-control"
                                                    ref="password"
                                                    name="password"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.password}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="password-confirm" className="col-md-8 control-label">Confirm Password</label>
                                            <div>
                                                <input
                                                    id="password-confirm"
                                                    type="password"
                                                    className="form-control"
                                                    ref="confirm"
                                                    name="password_confirmation"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.password_confirmation}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-md-8 col-md-offset-4">
                                                <button type="submit" className="btn btn-primary">
                                                    Reset Password
                                               </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Reset