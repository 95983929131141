import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { smoothToTop } from '../helpers/misc';
import auth from '../helpers/auth';
import axios from 'axios';
import CompanyCard from '../CompanyCard'
import Spinner from '../Spinner'


export class AdminDashboard extends Component {
    /**
     * The dashboard rendered for the admin. It contains generic information 
     * for each company
     */

    state = {
        spinner: true,
        companies: {},
        error: false
    }

    componentDidMount() {
        document.title = "BMS App - Home"

        smoothToTop()

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const id = this.props.match.params.id;
        let route = id ? "companies/" + id : "companies"
        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                this.setState({
                    companies: response.data,
                    spinner: false,
                    error: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false,
                    error: true
                })
            });
    }

    render() {
        const { companies, spinner, error } = this.state
        const { id } = this.props.match.params
        if (spinner)
            return (
                <Spinner />
            )
        if (error)
            return (
                <h2> Something went Wrong.</h2>
            )

        let content;
        if (id) {
            content =
                <div style={{ margin: "0 20%" }}>
                    <div className="row">
                        <CompanyCard company={companies} />
                    </div>
                </div>
        }
        else {

            if (companies.length) {
                content =
                    <div style={{ margin: "0 20%" }}>
                        {companies.map((company) => (
                            <div className="row text-light" key={company.id}>
                                <CompanyCard company={company} key={company.id} />
                            </div>
                        ))}
                    </div>
            }
            else {
                content = <h2 className="no-data">No companies found</h2>
            }
        }
        return (
            <div className="container-inner-wrapper">
                <div className="row">
                    <h1 className="col my-title"> Companies </h1>
                    <div className="col text-right" style={addStyle}>
                        <div className="container">
                            {auth.role_id <= 1 &&
                                <Link to="/registercompany" className="btn btn-primary ">
                                    <FontAwesomeIcon icon={faPlus} style={{ margin: "auto" }} />
                                    <span className="d-none d-lg-inline">Add Company</span>
                                </Link>}
                        </div>
                    </div>
                </div>
                <hr />
                {content}

            </div >
        )
    }
}

const addStyle = {
    paddingTop: "1.6%",
}

export default AdminDashboard
