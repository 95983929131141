import React, { Component } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router'
import FormUserDetails from './RegisterCompany/FormUserDetails'
import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'



export class RegisterAdmin extends Component {
    /**
     * A component to create and store a new (client) admin for a company
     */

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            speciality: '',
            companyId: '',
            companyToken: '',
            companyName: '',
            alert: false,
            alertMsg: '',
            alertClass: '', 
            success: false
        }
        this._isMounted = false;
    }
   

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.setState({
            companyName: this.props.location.state.company.name,
            companyId: this.props.location.state.company.id,
            companyToken: this.props.location.state.company.token
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    setAlert = (type, msg) => {
        this._isMounted && this.setState({
            alert: true,
            alertClass: "alert alert-" + type + " text-center",
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => {
            this._isMounted && this.setState({
                alert: false,
                alertClass: '',
                alertMsg: '',
            })
        }, 3000)
    }

    handleErrorResponse = (error_response) => {
        console.log("ERROR: => ", error_response)
        let header = "Errors on:"
        let details = []

        for(var key in error_response){
            header += ' ' + key + ',';
            error_response[key].forEach( msg => {
                console.log(msg)
                details.push(msg)
            })
        }
        header = header.substring(0, header.length -1)
        let error_header = <p> {header} </p>
        let error_details = <ul> <strong>Details:</strong> {details.map(msg => <li>{msg}</li> )} </ul>
        let error_message = <div>{error_header} {error_details}</div>
        this.setAlert('alert alert-danger', error_message) // text-center
    }

    setPassword = (pswd) => {
        this._isMounted && this.setState({ password: pswd })
    }

    // Handle field change
    handleChange = input => e => {
        this._isMounted && this.setState({ [input]: e.target.value })
    }

    registerCompanyAdmin = () => {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        console.log("request to " + process.env.REACT_APP_API_URL + 'users')
        const data = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            speciality: this.state.speciality,
            company_id: this.state.companyId,
            company_token: this.state.companyToken,
        }
        axios.post(process.env.REACT_APP_API_URL + 'users', data, config)
            .then(response => {
                console.log("SUCCESS: user stored")
                const user_data = response.data
                this._isMounted && this.setState({
                    clientAdminToken: user_data.access_token,
                });
                this.setAlert('alert alert-success', 'User ' + this.state.username + ' registered')
                setTimeout(
                    () => this._isMounted && this.setState({success: true}),
                    1000
                )
            })
            .catch(error => {
                console.log("ERROR: got error")
                if( error.response === undefined )
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }


    render() {
        const {
            companyName, companyId, companyToken, username, firstName, lastName, email, speciality, password, clientAdminToken,
        } = this.state

        const values = {
            companyName, companyId, companyToken, username, firstName, lastName, email, speciality, password, clientAdminToken,
        }

        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} role="alert">{this.state.alertMsg}</div> //alert alert-danger text-center
        
        let content;
        if (!this.state.success)
            content =
            <div className="my-form" style={cardStyle}>
                <h2 className="text-center"> {companyName}  </h2>
                <h4 className="text-center" style={secTitleStyle}> Add Company's Administrator Details </h4>
                <br />
                <div>{alert}</div>
                <FormUserDetails
                    handleChange={this.handleChange}
                    setPassword={this.setPassword}
                    setAlert={this.setAlert}
                    register={this.registerCompanyAdmin}
                    imgStyle={imgStyle}
                    secTitleStyle={secTitleStyle}
                    values={values}
                    step={false}
                />
            </div>
        else
            content = <Redirect to={"/companies/"+ companyId +"/users"}/>

        return(
            <div>
                {content}
            </div>
        )
    }
}

const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "200px",
    height: "200px",
    maxWidth: "100%",
    minWidth: "200px",
    margin: "0, 25%",
    marginBottom: "1%",
}

const cardStyle = {
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "5%",
    marginLeft: "20%",
    marginRight: "20%",
}
const secTitleStyle = {
    color: "#6a6a6a",
}
export default RegisterAdmin
