import React, { Component } from 'react'
import FarmsPerSite from '../FarmsPerSite'
import { DateRangePicker } from '../DateRangePicker'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import qs from 'qs';

import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import Spinner from '../Spinner'
import Filters from '../Filters';


class GeneratorsGrid extends Component {
    /**
     * Fetches the data and displays a grid with generator cards per
     * company or per site according to the user role
     */

    state = {
        showCalendar: false,
        dateRange: [],
        data: {},
        spinner: true,
        error: false,
        companyData: {}
    }

    showCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }

    onChangeDate = (date) => {
        if (date) {
            this.setState({
                dateRange: date
            }, () => this.fetchOverAllData())

        } else {
            this.showCalendar()
        }
    }
    componentDidMount() {
        smoothToTop();
        document.title = "BMS App - Generators"

        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()
        this.setState({
            dateRange: [yesterday, today]
        }, () => this.fetchOverAllData())
    }

    fetchOverAllData() {
        //set the correct day
        const { dateRange } = this.state
        let params = {}
        params["from"] = dateRange[0].getFullYear() + "-" + (dateRange[0].getMonth() + 1) + "-" + dateRange[0].getDate()
        params["to"] = dateRange[1].getFullYear() + "-" + (dateRange[1].getMonth() + 1) + "-" + dateRange[1].getDate()
        console.log("overall data for date range: ", params['from'], "--", params['to'])
        this.setState({
            spinner: true
        })

        //set the header
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        };
        const id = this.props.match.params.id;
        const specified = this.props.specified;
        let route;
        if (id) {
            if (specified === "companies")
                route = "fetch_companys_data/" + id
            else if (specified === "sites")
                route = "fetch_site_data/" + id
        }
        else
            route = 'fetch_overall_data'

        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                this.setState({
                    data: response.data,
                    spinner: false,
                    error: false,
                    showCalendar: false
                }, () => { this.initFirstCompany() })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false,
                    error: true
                })
            });
    }
    initFirstCompany() {
        let stateData = this.state.data;

        const specified = this.props.specified;
        let companyData = [];
        if (specified == "companies") {
            companyData.push({ ...stateData });
        } else if (specified == "sites") {
            // @todo: has to be check later 
        } else {
            companyData.push({ ...stateData[0] });
        }
        console.log('dddd')
        console.log(stateData.length);
        if (companyData.length > 0) {
            if (companyData[0].sites.length > 0) {
                let companySites = companyData[0].sites;
                companyData[0].sites = [];
                companyData[0].sites.push({ ...companySites[0] });
                if (companyData[0].sites[0].farms.length > 0) {
                    companyData[0].sites[0].farms = [];
                    companyData[0].sites[0].farms.push({ ...companySites[0].farms[0] });
                }
            }
        }
        console.log('company initial data');
        console.log(companyData);
        this.setState({
            companyData: companyData
        })

    }
    renderGenerators = (selectedCompanies, selectedSites, selectedFarms) => {
        console.log("Render overview here ");
        console.log('selected companies');
        console.log(selectedCompanies);
        console.log('selected site');
        console.log(selectedSites);
        console.log('selected farms ');
        console.log(selectedFarms);

        let dataFromState = [];
        if (this.props.specified === "companies") {
            dataFromState.push({ ...this.state.data });
        } else {
            dataFromState = this.state.data;
        }

        console.log('state data');
        console.log(dataFromState);

        let companyData = [];
        selectedCompanies.map((selectedCompany, selectedCompanyIndex) => {
            dataFromState.map((company, companyIndex) => {
                if (selectedCompany.id == company.company_id) {
                    // companyData[selectedCompanyIndex] = { ...company };
                    companyData.push({ ...company })
                    companyData[selectedCompanyIndex].sites = [];
                    selectedSites.map((selectedSite, selectedSiteIndex) => {
                        //companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms = [];
                        if (company.sites.length > 0) {
                            company.sites.map((site, siteIndex) => {
                                if (selectedSite.id == site.id && selectedSite.company_id == company.company_id) {
                                    //companyData[selectedCompanyIndex].sites[selectedSiteIndex] = { ...site };
                                    companyData[selectedCompanyIndex].sites.push({ ...site });
                                    companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms = [];
                                    selectedFarms.map((selectedFarm, selectedFarmIndex) => {
                                        if (site.farms.length > 0) {
                                            site.farms.map((farm, farmIndex) => {
                                                if (selectedFarm.id == farm.id && selectedSite.company_id == company.company_id && selectedFarm.site_id == site.id) {
                                                    // companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms[selectedFarmIndex] = { ...farm };
                                                    companyData[selectedCompanyIndex].sites[selectedSiteIndex].farms.push({ ...farm });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        });
        console.log(companyData)
        this.setState({
            companyData: companyData
        });
    }
    render() {
        const { companyData, spinner, error, dateRange, showCalendar, data } = this.state
        const { specified } = this.props
        let extraStyle = {}
        if (spinner)
            return (
                <Spinner />
            )

        let content = <h2 className="no-data">No generators found</h2>
        let h3Title = <h3 style={titleStyle}> <span style={spanStyle}>Company: </span> {data.company_name} </h3>
        if (error)
            return (
                <div>
                    <h1 className="my-title"> Generators </h1>
                    <hr />
                    <div>
                        {content}
                    </div>
                </div>
            );

        /* if (specified === "companies") {
            content =
                <div>
                    <Filters onFarmSelect={this.renderGenerators} />
                    {companyData[0].sites.length > 0 ?
                        companyData[0].sites.map(site =>
                            <FarmsPerSite site={site} item={"generators"} id={site.id} key={site.id} company_name={data.company_name} changeCurrentPlaceholder={this.props.changeCurrentPlaceholder} />
                        )
                        : <h2 className="no-data">No sites found</h2>
                    }
                </div >
        }
        else  **/if (specified === "sites") {
            content =
                <div>
                    <FarmsPerSite site={{ "name": data.name, "id": data.id, "farms": data.farms }} item={"generators"} company_name={data.company_name} changeCurrentPlaceholder={this.props.changeCurrentPlaceholder} />
                </div >
        }
        else {
            if (companyData.length) {
                if (this.props.specified !== "companies") {
                    h3Title = <br />
                    extraStyle = {
                        top: "0px"
                    }
                }
                content =
                    <div>
                        {companyData.map((data, index) =>
                            <div key={index}>
                                {this.props.specified !== "companies" ?
                                    <h3 style={titleStyle}> <span style={spanStyle}>Company: </span> {data.company_name} </h3>
                                    : ""
                                }
                                {data.sites.length > 0 ?
                                    data.sites.map(site =>
                                        <FarmsPerSite site={site} item={"generators"} id={site.id} key={site.id} company_name={data.company_name} changeCurrentPlaceholder={this.props.changeCurrentPlaceholder} />
                                    )
                                    : <h2 className="no-data">No sites found</h2>
                                }

                            </div>
                        )}
                    </div >
            }
        }

        return (
            <div className="generators" >
                <div>
                    <h1 className="my-title"> Wind Turbine
                    <span className="date-picker-opener-with-title" >
                            {!showCalendar &&
                                <button onClick={this.showCalendar} style={{ paddingLeft: "0px" }}>
                                    <div className="date-picker-opener">
                                        <span className="choose-date pull-left">Choose Date</span>
                                        <span className="pull-right"><FontAwesomeIcon icon={faPlay} size={"sm"} /></span>

                                    </div>
                                </button>
                            }
                            {showCalendar &&
                                <div>
                                    <DateRangePicker onChangeDate={this.onChangeDate} date={dateRange} />
                                </div>
                            }
                        </span>
                    </h1>
                </div>
                <div className="body" style={extraStyle}>
                    <hr />
                    <Filters onFarmSelect={this.renderGenerators} />
                    {h3Title}


                    <br />
                    {content}
                </div>
            </div>
        );
    }
}

const titleStyle = {
    fontWeight: "200",
    fontSize: "2rem",
    color: "#000",
    padding: "20px",
    backgroundColor: "#31628A",
    width: "auto",
    maxWidth: "400px",
    height: "auto",
    color: "#ffffff",
    marginBottom: "30px"
}
const noTop = {
    top: "0px"
}

const spanStyle = { "color": "#ffffff" }



export default GeneratorsGrid
