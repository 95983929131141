/**
 * Deprecated!!!
 */
export class Company{

    constructor(data) {
        this.id = parseInt(data.id);
        this.name = data.name;
        this.description = data.description;
        this.logo_path = data.logo_path;
        this.country = data.country;
        this.city = data.city;
        this.address = data.address;
        this.zip_code = data.zip_code;
        this.phone =data.phone;
        this.token = data.token;
        this.admins = [];
        this.users = [];

        if(data.admins)
            data.admins.map((admin) => {
                this.admins.push(new User(admin))
            })
        if(data.users)
            data.users.map((user) => {
                this.users.push(new User(user))
            })

        // console.log("company created: "+ this.name)
    }
}

export class User{

    constructor(data) {
        this.id = parseInt(data.id);
        this.username = data.username;
        this.email = data.email;
        this.email_verified_at = data.email_verified_at;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.speciality = data.speciality;
        this.role_id = data.role_id;

        // console.log("user created: "+ this.username)
    }
}