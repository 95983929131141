import React, { Component, useState } from 'react';
import {Slider } from '@material-ui/core';

const CriteriaSettingsSlider = (props) => {
  const [sliderValue, setSliderValue] = useState(props.value ?? 0);
  const onChangeSlider = (event, value) => {
    setSliderValue(value);
    props.onChange(props.generator_id, value);
  }
  return (
    <div style={sliderComponentStyle} className="d-inline-block col-sm-7">
      <div style={sliderIconStyle}  className="col-sm-2 text-center align-middle d-inline-block">
        {props.icon}
      </div>
      <div style={sliderContainerStyle}  className="col-sm-8  d-inline-block">
        <Slider onChange={(event, value) => { onChangeSlider(event,  value)}} valueLabelDisplay="off" value={sliderValue}  defaultValue={props.value ?? 0} min={props.minimum ?? 0} max={props.maximum ?? 100} aria-label="Default"  style={sliderStyle}/>  
      </div>
      <div style={sliderValueStyle} className="col-sm-2 text-center d-inline-block">
        {sliderValue} {props.measure_label ?? ""}
      </div>
      <div style={sliderTitleStyle} className="text-center row d-block">
          {props.title}
      </div>
    </div>
  );
}

const sliderComponentStyle = {
  clear:"both",
  border:"1px solid #ccc",
  padding:"20px",
  overflow:"hidden",
  borderRadius: "10px",
  marginBottom: "20px"
};
const sliderTitleStyle = {
  fontWeight:"bold",
  fontSize: "16px",
  color:"#ffffff"
};
const sliderValueStyle = {
  // color:"#ffffff"
  color:"#ffffff",
  fontWeight:"bold"
};
const sliderIconStyle = {
  color:"#ffffff"
};
const sliderStyle = {
  color: "#ffffff"
};
const sliderContainerStyle = {
  color:"#dedede",
  fontSize:"12px"
};


export default CriteriaSettingsSlider;