import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrow, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
// import IPut from 'iput';

export class FormSiteDetails extends Component {
    /**
     * Form to fullfil the site details and store it in the database.
     * 
     * It also contains the forms to create the farms and annotations of the
     * sites. 
     */

    state = {
        farmsId: 0,
        birdsCategoryId: 0
    }

    addFarm = () => {
        let currentFarmId = this.state.farmsId
        let farm = {
            name: '',
            latitude: '',
            longitude: '',
            id: currentFarmId
        }
        let newfarms = this.props.values.farms
        newfarms.push(farm)
        currentFarmId += 1
        this.setState({farmsId: currentFarmId})
        this.props.syncFarms(newfarms)
    }
    removeFarm = (fid) => {
        let newfarms = this.props.values.farms
        newfarms.splice(fid, 1);
        for(let i = fid; i<newfarms.length; i++)
            newfarms[i].id -= 1
        let currentFarmId = this.state.farmsId -1
        this.setState({farmsId: currentFarmId})
        this.props.syncFarms(newfarms)
    }
    handleChangeOnFarm = (fid, input) => e => {
        let newfarms = this.props.values.farms
        newfarms[fid][input] = e.target.value
        this.props.syncFarms(newfarms)

    }

    validateFarm = (farm) => {
        return farm.name.length > 0
    }

    addBirdsCategory = () => {
        let currentBirdsCategoryId = this.state.birdsCategoryId
        let bCategory = {
            id: currentBirdsCategoryId,
            category: '',
        }
        let newbCategories = this.props.values.bCategories
        newbCategories.push(bCategory)
        currentBirdsCategoryId += 1
        this.setState({birdsCategoryId: currentBirdsCategoryId})
        this.props.syncbCategories(newbCategories)
    }
    removeBirdsCategory = (cid) => {
        let newbCategories = this.props.values.bCategories
        console.log("Removing cid: ", cid)
        newbCategories.splice(cid, 1);
        for(let i = cid; i<newbCategories.length; i++)
            newbCategories[i].id -= 1
        let currentBirdsCategoryId = this.state.birdsCategoryId -1
        this.setState({birdsCategoryId: currentBirdsCategoryId})
        this.props.syncbCategories(newbCategories)
    }
    handleChangeOnBirdCategory = (cid, input) => e => {
        let newbCategories = this.props.values.bCategories
        newbCategories[cid][input] = e.target.value
        this.props.syncbCategories(newbCategories)
    }
    validateBirdCategory = (bCategory) => {
        return bCategory.category.length > 0
    }

    validate() {
        const { values } = this.props
        if (
            values.siteName.length > 0
        ){
            for(let i=0; i<this.props.values.farms.length; i++){
                if (!this.validateFarm(this.props.values.farms[i]))
                    return false
            }
            for(let i=0; i<this.props.values.bCategories.length; i++){
                if (!this.validateBirdCategory(this.props.values.bCategories[i]))
                    return false
            }
            return true
        }
        else return false
    }

    previous = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    continue = e => {
        e.preventDefault();
        this.validate() ? this.props.register() : this.props.setAlert('alert alert-danger text-center', 'All the fields with (*) should be filled')
    }


    render() {
        const { values, handleChange } = this.props
        return (
            <div className="form-horizontal">
                <div className="form-group">
                    <label htmlFor="companyName" className="control-label">Site Name (*)</label>
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            ref="siteName"
                            name="siteName"
                            value={values.siteName}
                            onChange={handleChange('siteName')}
                            required autoFocus
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="latitude" className="control-label">Latitude</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="latitude"
                                    name="latitude"
                                    value={values.latitude}
                                    onChange={handleChange('latitude')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="longitude" className="control-label">Longitude</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="longitude"
                                    name="longitude"
                                    value={values.longitude}
                                    onChange={handleChange('longitude')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    

                </div>
                {/* IP Address DEPRECATED */}
                {/* <div className="row d-flex">
                    <div className="mx-auto text-center" >
                        <label htmlFor="siteIp" className="control-label"> Ip Address</label>
                        <div style={{ width: "120%" }}>
                            <IPut
                                className="form-control"
                                onChange={this.props.updateip}
                                required autoFocus
                            />
                        </div>
                    </div>
                </div> */}
                
                <br/>
                <div>
                    {values.farms.map( (farm) => 
                        <SimpleFarmForm 
                            key={farm.id} 
                            farm={farm} 
                            removeFarm={this.removeFarm}
                            handleChangeOnFarm={this.handleChangeOnFarm}
                            /> 
                    )}
                </div>
                <div>
                    {values.bCategories.map( (bCategory) => 
                        <SimpleBirdCategoryForm 
                            key={bCategory.id} 
                            bCategory={bCategory} 
                            removeBirdsCategory={this.removeBirdsCategory}
                            handleChangeOnBirdCategory={this.handleChangeOnBirdCategory}
                            /> 
                    )}
                    <br/>
                </div>

                {/*  */}
                <div className="float-right">
                    <button className="btn btn-primary" onClick={this.addFarm}>
                            <span>Add Farm</span>
                    </button>
                    <button className="btn btn-success" onClick={this.addBirdsCategory} style={{marginLeft: "10px"}}>
                            <span>Add Bird Category</span>
                    </button>
                </div>

                <div style={{"marginBottom": "100px"}} />

                <div className="row d-flex">
                    <button className="btn btn-primary mx-auto register-button" onClick={this.continue} >
                        <span>Register All</span>
                    </button>
                    {this.props.fromCompany &&
                        <button className="btn btn-secondary float-right" onClick={this.props.skipStep} >
                            <span>Skip</span>
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </button>}

                </div>
                <br />

                <div className="text-center">
                    <img
                        src={process.env.PUBLIC_URL + '/default_generators.png'}
                        alt="default generators logo"
                        style={this.props.imgStyle}
                    />
                </div>

            </div>
        )
    }
}

export class SimpleFarmForm extends Component {
    handleChange = input => e => {
        this.setState({ [input]: e.target.value })
    }

    render() {
        return (
            <div style = { formStyle } className="form-horizontal">
                <button className="close-button float-right" style={{"borderRadius": "50px"}} onClick={() => this.props.removeFarm(this.props.farm.id)} >
                    <span>&times;</span>
                </button>
                <h4 className="text-center" style={secTitleStyle}> Add farm </h4>
                <p className="text-center"><small>You can ingore this part and add farms later.</small></p>
                
                <div className="form-group">
                    <label htmlFor="companyName" className="control-label">Farm Name (*)</label>
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            ref="name"
                            name="name"
                            value={this.props.farm.name}
                            onChange={this.props.handleChangeOnFarm(this.props.farm.id, 'name')}
                            required autoFocus
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="latitude" className="control-label">Latitude</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="latitude"
                                    name="latitude"
                                    value={this.props.farm.latitude}
                                    onChange={this.props.handleChangeOnFarm(this.props.farm.id, 'latitude')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="longitude" className="control-label">Longitude</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="longitude"
                                    name="longitude"
                                    value={this.props.farm.longitude}
                                    onChange={this.props.handleChangeOnFarm(this.props.farm.id, 'longitude')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <img
                        src={process.env.PUBLIC_URL + '/default_generators_icon.png'}
                        alt="default generators logo"
                        style={this.props.imgStyle}
                    />

            </div>
        )
    }
}


export class SimpleBirdCategoryForm extends Component {
    handleChange = input => e => {
        this.setState({ [input]: e.target.value })
    }

    render() {
        return (
            <div style = { birdFormStyle } className="form-horizontal">
                <button className="close-button float-right" style={{"borderRadius": "50px"}} onClick={() => this.props.removeBirdsCategory(this.props.bCategory.id)} >
                    <span>&times;</span>
                </button>
                <h4 className="text-center" style={birdSecTitleStyle}> Add Bird Category </h4>
                <p className="text-center"><small>A bird category that may exist in this site.</small></p>
                
                <div className="form-group row">
                    <label htmlFor="birdCategory" className="col-sm-2 col-form-label"> Category (*): </label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            ref="category"
                            name="category"
                            value={this.props.bCategory.category}
                            onChange={this.props.handleChangeOnBirdCategory(this.props.bCategory.id, 'category')}
                            required autoFocus
                        />
                    </div>
                </div>
                <p className="text-center"><small>You can also add the categories in the annotation process</small></p>
                <FontAwesomeIcon icon={faCrow} size={'2x'} style={{color: "#3a973a"}} />

            </div>
        )
    }
}

const formStyle = {
    border: "solid 2px #aaa",
    padding : "2%",
    margin: "2% 0%",
    borderRadius: "10px"
}


const secTitleStyle = {
    color: "#6a6a6a",
}

const birdSecTitleStyle = {
    color: "#6aa76a",
}

const birdFormStyle ={
    border: "solid 2px #6aa76a",
    padding : "2%",
    margin: "2% 0%",
    borderRadius: "10px"
}

export default FormSiteDetails
