import React, {Component} from 'react'
import Modal from 'react-modal'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faPlus, faCrow, faUser, faUserShield, faCog} from "@fortawesome/free-solid-svg-icons";
import auth from './helpers/auth'
import {Link} from 'react-router-dom'
import axios from 'axios'
import UserSettingsModal from "./UserSettingsModal";


export class UsersList extends Component {
    /**
     * Renders a list that displays the users grouped by their roles
     */
    render() {
        const {company} = this.props;
        return (
            <div>
                <div className="my-subtitle">{company.name}</div>
                <br/>
                <div className="row">
                    <div className="my-inner-subtitle"><FontAwesomeIcon icon={faUserShield} size={"xs"}
                                                                        style={{margin: "0 10px"}}/>Administration
                    </div>
                    {auth.role_id <= 2 &&
                    <div className="col d-flex">
                        <Link to={{
                            pathname: `/companies/${company.id}/registeradmin`,
                            state: {company}
                        }}
                              className="btn mb-1 mt-auto" style={addStyle}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </Link>
                    </div>}
                </div>
                <br/>
                <table className="table table-hover ">
                    <tbody style={wordBreak}>
                    <tr style={normalBreak}>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                    {company.users.filter(user => user.role_id === 2).map(user => <UserRow user={user} key={user.id}
                                                                                           setAlert={this.props.setAlert}/>)}
                    </tbody>
                </table>
                <br/>
                <div className="row">
                    <div className="my-inner-subtitle"><FontAwesomeIcon icon={faUser} size={"xs"}
                                                                        style={{margin: "0 10px"}}/>Users
                    </div>
                    {auth.role_id <= 2 &&
                    <div className="col d-flex">
                        <Link to={"/registeruser/" + company.id} className="btn mb-1 mt-auto" style={addStyle}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </Link>
                    </div>}
                </div>
                <br/>
                <table className="table table-hover ">
                    <tbody style={wordBreak}>
                    <tr style={normalBreak}>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                    {company.users.filter(user => user.role_id === 3).map(user => <UserRow user={user} key={user.id}
                                                                                           setAlert={this.props.setAlert}/>)}
                    </tbody>
                </table>
                <br/>
                <div className="row">
                    <div className="my-inner-subtitle"><FontAwesomeIcon icon={faCrow} size={"xs"}
                                                                        style={{margin: "0 10px"}}/>Ornithologists
                    </div>
                    {auth.role_id <= 2 &&
                    <div className="col d-flex">
                        <Link to={"/registerornithologist/" + company.id} className="btn mb-1 mt-auto"
                              style={addBirdStyle}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </Link>
                    </div>}
                </div>
                <br/>
                <table className="table table-hover ">
                    <tbody style={wordBreak}>
                    <tr style={normalBreak}>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                    {company.users.filter(user => user.role_id === 4).map(user => <UserRow user={user} key={user.id}
                                                                                           setAlert={this.props.setAlert}/>)}
                    </tbody>
                </table>
                <br/>
                <hr className="my-hr"/>
            </div>
        )
    }
}


export class UserRow extends Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
            showUserSettingsModal: false
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleYes = this.handleYes.bind(this);

        this.handleOpenUserSettingsModal = this.handleOpenUserSettingsModal.bind(this);
        this.handleCloseUserSettingsModal = this.handleCloseUserSettingsModal.bind(this);
    }

    /**
     * opens the modal to warn the delete
     */
    handleOpenModal() {
        this.setState({showModal: true});
    }

    /**
     * closes the modal to warn the delete
     */
    handleCloseModal() {
        this.setState({showModal: false});
    }

    /**
     * opens the modal for user settings
     */
    handleOpenUserSettingsModal() {
        console.log("opening UserSettings modal")
        this.setState({showUserSettingsModal: true});
    }

    /**
     * closes the modal for user settings
     */
    handleCloseUserSettingsModal() {
        console.log("closing UserSettings modal")
        this.setState({showUserSettingsModal: false});
    }

    /**
     * handles the yes answer on the delete modal. It makes a delete request
     * to the backend
     */
    handleYes() {
        //delete
        const config = {
            headers: {Authorization: `Bearer ${auth.token}`}
        };
        axios.delete(process.env.REACT_APP_API_URL + 'users/' + this.props.user.id, config)
            .then(response => {
                this.setState({showModal: false});
                this.props.setAlert("success", "User " + this.props.user.username + " deleted", true);
            })
            .catch(error => {
                this.setState({showModal: false});
                this.props.setAlert("danger", "Action not accepted.", false);
            })

    }

    /**
     * Each row contains a user
     */
    render() {
        Modal.setAppElement("#root")
        return (
            <tr style={normalBreak}>
                <td className="col-3">{this.props.user.username}</td>
                <td className="col-3">{this.props.user.first_name + " " + this.props.user.last_name}</td>
                <td className="col-3">{this.props.user.email}</td>
                <td className="col-2">{auth.roles_titles[this.props.user.role_id]}</td>
                {auth.role_id <= 2 && <td className="col-1">
                    <button style={iconStyle} onClick={this.handleOpenUserSettingsModal}>
                        <FontAwesomeIcon icon={faCog} size="lg"/>
                    </button>
                    <Modal
                        isOpen={this.state.showUserSettingsModal}
                        onRequestClose={this.handleCloseUserSettingsModal}
                        style={modalSettingsStyle}
                    >
                        <UserSettingsModal user={this.props.user} closeMe={this.handleCloseUserSettingsModal}/>
                    </Modal>
                    {this.props.user.role_id > 2 && <button style={trashStyle} onClick={this.handleOpenModal}>
                        <FontAwesomeIcon icon={faTrashAlt} size="lg"/>
                    </button>}
                    <Modal
                        isOpen={this.state.showModal}
                        onRequestClose={this.handleCloseModal}
                        style={modalStyle}
                    >
                        <div className="card">
                            <div className="card-body" style={modalCard}>
                                <h5 className="card-title text-center">Are you sure?</h5>
                                Are you sure you want to delete <strong>{this.props.user.username}</strong>?
                                <div className="text-center" style={modalButtons}>
                                    <a href="#" onClick={this.handleYes} className="btn btn-primary">Yes</a>
                                    <span style={spanStyle}></span>
                                    <a href="#" onClick={this.handleCloseModal}
                                       className="btn btn-danger bg-danger border-danger">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </Modal>

                </td>}

            </tr>
        )
    }
}

const iconStyle = {
    color: "var(--main-primary)",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
}

const trashStyle = {
    color: "var(--main-danger)",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
}

const normalBreak = {
    wordBreak: "normal"
}

const wordBreak = {
    wordBreak: "break-word"
}

const modalStyle = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderStyle: "none",
        padding: "0 0"
    },
    overlay: {zIndex: 10000},
}

const modalSettingsStyle = {
    content: {
        padding: "0 0",
    },
    overlay: { zIndex: 10000 },
}

const modalCard = {
    // padding: "25px 90px",
    // borderStyle: "none"
    border: "1px solid #dde7f1"
}

const modalButtons = {
    padding: "4% 0 2%"
}

const spanStyle = {
    margin: "7px"
}

const addStyle = {
    padding: "0px 5px",
    marginRight: "6px",
    color: "#fcfcfc",
    backgroundColor: "#97bdde"
}
// #b4cee4

const addBirdStyle = {
    padding: "0px 5px",
    marginRight: "6px",
    color: "#fff",
    backgroundColor: "#7bc99d"
}

export default UsersList
