import React, { Component } from "react";
import axios from 'axios'
import qs from 'qs';

import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import ItemsPerFarm from '../ItemsPerFarm'
import { DateRangePicker } from '../DateRangePicker'

import Spinner from '../Spinner'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlay } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom'


export class SiteData extends Component {
    /**
     * Displays informations for a specific site.
     */
    state = {
        showCalendar: false,
        dateRange: [],
        site: {},
        spinner: true,
        error: false
    }

    showCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }
    onChangeDate = (date) => {
        if (date) {
            this.setState({
                dateRange: date
            }, () => this.fetchOverAllData())
        } else {
            this.showCalendar()
        }
    }

    componentDidMount() {
        smoothToTop();
        document.title = "BMS App - Site"
        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()
        this.setState({
            dateRange: [yesterday, today]
        }, () => this.fetchOverAllData())
    }

    fetchOverAllData() {
        //set the correct day
        const { dateRange } = this.state
        let params = {}
        params["from"] = dateRange[0].getFullYear() + "-" + (dateRange[0].getMonth() + 1) + "-" + dateRange[0].getDate()
        params["to"] = dateRange[1].getFullYear() + "-" + (dateRange[1].getMonth() + 1) + "-" + dateRange[1].getDate()
        console.log("overall data for date range: ", params['from'], "--", params['to'])
        this.setState({
            spinner: true
        })

        //set the header
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        };
        const sid = this.props.match.params.id
        axios.get(process.env.REACT_APP_API_URL + 'sites/' + sid, config)
            .then(response => {
                this.setState({
                    site: response.data,
                    spinner: false,
                    showCalendar: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    error: true,
                    spinner: false

                })
            });
    }

    render() {
        const { site, spinner, error, showCalendar, dateRange } = this.state;
        if (spinner)
            return (
                <Spinner />
            )
        if (error)
            return (
                <h2 className="no-data">No data found</h2>
            )

        let canAdd = '';
        if (auth.role_id <= 1)
            canAdd =
                <div className="col text-right" style={addStyle}>
                    <div className="container" style={{ marginRight: "0" }}>
                        <Link to={"/sites/" + this.props.match.params.id + "/registerfarm"} className="btn btn-primary ">
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="d-none d-lg-inline"> Add Farm</span>
                        </Link>
                    </div>
                </div>

        let results =
            <div>
                <div className="row">
                    <div>
                        <h2 className="col my-title"> {site.name}
                            <span className="date-picker-opener-with-title" >
                                {!showCalendar &&
                                    <button onClick={this.showCalendar} style={{ paddingLeft: "0px" }}>
                                        <div className="date-picker-opener">
                                            <span className="choose-date pull-left">Choose Date</span>
                                            <span className="pull-right"><FontAwesomeIcon icon={faPlay} size={"sm"} /></span>

                                        </div>
                                    </button>
                                }
                                {showCalendar &&
                                    <div>
                                        <DateRangePicker onChangeDate={this.onChangeDate} date={dateRange} />
                                    </div>
                                }
                            </span>
                        </h2>
                    </div>
                    {canAdd}
                </div>
                <hr />
                {site.farms.map(farm =>
                    <ItemsPerFarm farm={farm} item={'summaries'} key={farm.id} />
                )}
            </div>


        return (
            <div className="container-inner-wrapper">
                {results}
            </div>
        );
    }
}

const addStyle = {
    paddingTop: "1.6%",
}

export default SiteData
