import React, { Component } from "react";
import { DateRangePicker } from './DateRangePicker'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import qs from 'qs';
import auth from './helpers/auth'

import SiteCard from './SiteCard'


class EventSummary extends Component {

    state = {
        date: [new Date(), new Date()],
        statistics: {
            detections: 0,
            deterrences: 0,
            shutdowns: 0
        },
        selectedFarms: [],
        selectedGenerators: [],
        selectedCameras: [],
        statisticsSpinner: false,
        showCalendar: false
    };


    onChangeDate = date => this.setState({ date }, () => this.fetchStatistics())

    showCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }

    componentDidMount() {
        const { content, selectedFarms, selectedGenerators, selectedCameras } = this.props
        let detections = 0, deterrences = 0, shutdowns = 0

        for (let i = 0; i < content.length; ++i) {
            detections += content[i].detections
            deterrences += content[i].deterrences
            shutdowns += content[i].shutdowns
        }
        this.setState({
            statistics: {
                detections: detections,
                deterrences: deterrences,
                shutdowns: shutdowns
            },
            selectedFarms: selectedFarms,
            selectedGenerators: selectedGenerators,
            selectedCameras: selectedCameras
        })
    }

    fetchStatistics = () => {
        const { date } = this.state
        const selectedTypes = ['detection', 'deterrence', 'shutdown']
        let params = {}

        const { selectedGenerators, selectedFarms, selectedCameras } = this.state
        if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        } else {
            console.log("Unexpected input in Events Summary request")
            return
        }
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        console.log(fromDate, "----", toDate)
        params["from"] = fromDate
        params["to"] = toDate
        params["eventtypes"] = selectedTypes
        const requestRoute = process.env.REACT_APP_API_URL + "events/statistics"
        console.log("requesting to :: " + requestRoute)
        this.setState({
            statisticsSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            const statistics = {
                detections: response.data.detections,
                deterrences: response.data.deterrences,
                shutdowns: response.data.shutdowns
            }
            this.setState({
                statistics: statistics,
                statisticsSpinner: false
            })
        })
            .catch(error => {
                console.log("Got error on statistics request")
                console.log(error.response)
                this.setState({
                    statisticsSpinner: false
                })
            })
    }


    render() {

        let onChangeFunction = this.props.onChangeDate ? this.props.onChangeDate : this.onChangeDate
        let date = this.props.date ? this.props.date : this.state.date
        let statistics = this.props.statistics ? this.props.statistics : this.state.statistics
        let statisticsSpinner = this.props.statisticsSpinner ? this.props.statisticsSpinner : this.state.statisticsSpinner
        const { showCalendar } = this.state
        return (
            <div>
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="flex-col">
                            <div className="row">
                                <h2 className="align-left" style={TitleStyle}>Event Summary</h2>
                                {!showCalendar &&
                                    <button onClick={this.showCalendar} style={{ paddingLeft: "20px" }}>
                                        <div className="date-picker-opener">
                                            <span className="choose-date pull-left">Choose Date</span>
                                            <span className="pull-right"><FontAwesomeIcon icon={faPlay} size={"sm"} /></span>

                                        </div>
                                    </button>
                                }
                                {showCalendar &&
                                    <div style={{ paddingLeft: "20px" }}>
                                        <DateRangePicker onChangeDate={(date) => { (date) ? onChangeFunction(date) : this.showCalendar() }} date={date} />
                                    </div>
                                }


                            </div>

                        </div>
                    </div>
                    <br />
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <SiteCard class={"success detections-site-card"} header={"Detections"} number={statistics.detections} statisticsSpinner={statisticsSpinner} />
                    </div>
                    <div className="col-xl-4 col-lg-4">
                        <SiteCard class={"warning deterrences-site-card"} header={"Deterrences"} number={statistics.deterrences} statisticsSpinner={statisticsSpinner} />
                    </div>
                    <div className="col-xl-4 col-lg-4">
                        <SiteCard class={"danger shutdowns-site-card"} header={"Shut Down Signals"} number={statistics.shutdowns} statisticsSpinner={statisticsSpinner} />
                    </div>
                </div>
            </div >
        );
    }
}



const TitleStyle = {
    marginTop: "1rem",
    marginBottom: "1rem",
    fontWeight: "400",
    fontSize: "1.4rem"
}

const buttonStyle = {
    marginLeft: "5px",
    borderRadius: "50%",
}

export default EventSummary;
