import React, { Component } from 'react'
import Modal from 'react-modal'
import { Multiselect } from 'multiselect-react-dropdown';
import { CCTVEventsTable } from '../CCTVEventsTable'
import { DateRangePicker } from '../DateRangePicker'
import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import qs from 'qs';

import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import { FormControl } from '@material-ui/core'; //tried this because there was an import error, got fixed and reversed it
import FormLabel from '@material-ui/core/FormLabel';

import Reports from '../pages/Reports'
import LittleSpinner from '../LittleSpinner';


class CCTVEvents extends Component {
    /**
     * Renders the page that searches and shows the events.
     */

    // intialitation of the Refs of the multiselect inputs.
    multiselectRefCompanies = React.createRef();
    multiselectRefSites = React.createRef();
    multiselectRefFarms = React.createRef();
    multiselectRefGenerators = React.createRef();

    state = {
        alert: false,
        date: [new Date(), new Date()],
        showDetails: false,

        sitesDisabled: true,
        farmsDisabled: true,
        generatorsDisabled: true,
        optionData: [],
        selectedCompanies: [],
        selectedSites: [],
        selectedFarms: [],
        selectedGenerators: [],
        tableSpinner: false,
        showModal: false,
        events: [],
        totalEvents: 0,
        pagingControl: {
            pageSize: 20,
            currentPage: 0,
            lastPage: 0
        }
    }

    /**
     * Opens the modal that contains the image or the clip of the event
     */
    handleOpenModal() {
        this.setState({ showModal: true });
    }
    /**
     * Closes the modal that contains the image or the clip of the event
     */
    handleCloseModal() {
        this.setState({ showModal: false });
    }
    /**
     * Removes all the selected items of the companis' multiselect
     */
    resetCompaniesValues() {
        this.multiselectRefCompanies.current.resetSelectedValues();
        this.resetSitesValues()
        let newArray = this.state.optionData
        newArray.map(comp => comp['children'] = [])
        this.setState({
            // sitesDisabled: auth.role_id > 0,
            farmsDisabled: true,
            generatorsData: true,
            selectedCompanies: [],
            optionData: newArray
        })
    }
    /**
     * Removes all the selected items of the sites' multiselect
     */
    resetSitesValues() {
        this.multiselectRefSites.current.resetSelectedValues();
        this.resetFarmsValues()
        this.resetGeneratorsValues()
        let newArray = this.state.optionData
        newArray.map(comp => comp['children'].map(site => site['children'] = []))
        this.setState({
            farmsDisabled: true,
            selectedSites: [],
            optionData: newArray
        })
    }
    /**
     * Removes all the selected items of the farms' multiselect
     */
    resetFarmsValues() {
        this.multiselectRefFarms.current.resetSelectedValues();
        this.resetGeneratorsValues()
        let newArray = this.state.optionData
        newArray.map(comp => comp['children'].map(site => site['children'].map(farm => farm['children'] = [])))
        this.setState({
            generatorsDisabled: true,
            selectedFarms: [],
            optionData: newArray
        })
    }
    /**
     * Removes all the selected items of the generators' multiselect
     */
    resetGeneratorsValues() {
        this.multiselectRefGenerators.current.resetSelectedValues();
        this.setState({
            selectedGenerators: []
        })
    }
    /**
     * Removes all the selected items of the all multiselects
     */
    resetAll() {
        if (auth.role_id <= 1)
            this.resetCompaniesValues()
        this.resetSitesValues()
        this.resetFarmsValues()
        this.resetGeneratorsValues()
        this.setState({
            events: [],
            totalEvents: 0,
            pagingControl: {
                pageSize: 20,
                currentPage: 0,
                lastPage: 0
            },
            showDetails: false
        })
    }
    /**
     * Displayes an alert on the top of the view
     * @param {string} type the type of the alert
     * @param {string} msg The message of the alert
     */
    setAlert = (type, msg, fetch = false) => {
        this.setState({
            alert: true,
            alertClass: "alert alert-" + type + " text-center",
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => {
            this.setState({
                alert: false,
                alertClass: '',
                alertMsg: '',
            });
            if (fetch) {
                this.search()
            }

        }, 3000)
    }
    /**
     * Makes the request with the selected values, and then downloads the created exel
     */
    downloadReport = () => {
        const { date, selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
        let params = {}


        if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the reaquest fields.")
            return
        }
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        const requestRoute = process.env.REACT_APP_API_URL + "cctv_events/report"
        console.log(fromDate, "----", toDate)
        params["from"] = fromDate
        params["to"] = toDate

        console.log("requesting to :: " + requestRoute)
        this.handleOpenModal()
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            responseType: 'blob',
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            let nowDate = new Date();
            let date = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
            let filename = 'BMS-CCTV-Report-' + date + '.xlsx'
            link.setAttribute('download', filename); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.handleCloseModal()
        })
            .catch(error => {
                console.log("Got error on report request")
                console.log(error)
                this.handleCloseModal()
            })
        // this.handleCloseModal()
    }

    /**
     * Synchronises the new date with the state
     * @param {Date} date 
     */
    onChangeDate = (date) => {
        console.log("date-change: ", date)
        this.setState({ date })
    }
    /**
     * Requests the page with the pageId from the backend with the given search inputs
     * @param {int} pageId 
     */
    changePage = (pageId) => {
        const { date, selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
        let params = {}

        if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the reaquest fields.")
            return
        }
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        params["from"] = fromDate
        params["to"] = toDate
        params["page"] = pageId

        const requestRoute = process.env.REACT_APP_API_URL + "cctv_events/search"
        this.setState({
            tableSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            const myPagingControl = {
                currentPage: response.data.current_page,
                lastPage: response.data.last_page,
            }
            let events = response.data.data
            // events.map(event => event['generator'] = this.state.selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
            this.setState({
                tableSpinner: false,
                totalEvents: response.data.total,
                events: events,
                pagingControl: myPagingControl
            })
        })
            .catch(error => {
                // this.setAlert("danger", "Errorfull request")
                console.log("Got error on search request")
                console.log(error.response)
                this.setState({
                    tableSpinner: false,
                })
            })
    }
    /**
     * Parses the given inputs, creates the request structure, and makes the request on the backend. On
     * the response, it fills the eventtable
     */
    search = () => {
        const { date, selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
        let params = {}


        if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the reaquest fields.")
            return
        }
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        console.log(fromDate, "----", toDate)
        params["from"] = fromDate
        params["to"] = toDate
        params["page"] = 1

        const requestRoute = process.env.REACT_APP_API_URL + "cctv_events/search"

        this.setState({
            tableSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            // this.setAlert("success", "Successful request")
            const myPagingControl = {
                currentPage: response.data.current_page,
                lastPage: response.data.last_page,
            }
            let events = response.data.data
            events.map(event => event['generator'] = this.state.selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
            this.setState({
                tableSpinner: false,
                totalEvents: response.data.total,
                events: events,
                pagingControl: myPagingControl,
                showDetails: false,
            })

            this.fetchAllData()
            // console.log("Response: ", response.data)
            // console.log("Events: ", response.data.data)

        })
            .catch(error => {
                // this.setAlert("danger", "Errorfull request")
                console.log("Got error on search request")
                console.log(error.response)
                this.setState({
                    tableSpinner: false,
                })
            })
    }

    /**
     * Calculates the active and the inactive generators from those that are set in the multiselect
     * input
     */
    getActiveCount() {
        let res = {}
        res["activeCount"] = this.state.selectedGenerators.filter(gen => gen.isActive === 1).length
        res["inactiveCount"] = this.state.selectedGenerators.filter(gen => gen.isActive === 0).length
        return res
    }

    // --------------------------------------------------------------------------------------------------------- //

    componentDidMount() {
        // smoothToTop() //Commented for "show more details" rerendering
        this.fetchInitialData()
        document.title = "BMS App - Events"

        //set the first value from the defaults
    }
    /**
     * Fetches the inital data. If the user is admin, the companies are fetched
     * otherwise the sites.
     */
    fetchInitialData() {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        // let route = auth.role_id > 1 ? "sites" : "companies-lite"
        let route = "companies-lite"
        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                let items = [];
                response.data.map((item) => {
                    item['children'] = [];
                    items.push(item)
                })
                console.log("Initial data: ", items)
                this.setState({
                    optionData: items
                })
                if (auth.role_id > 1) {
                    //fetch also the sites
                    let newSelectedCompanies = []
                    items.map(comp => {
                        this.fetchData('company', comp)
                        newSelectedCompanies.push(comp)
                    })
                    this.setState({
                        selectedCompanies: newSelectedCompanies
                    })
                }
            })
            .catch(error => {
                console.log("Error on fetching initial data")
                console.log(error)
            });
    }
    /**
     * When the user selects an item from a category, then in the next category, the children of the item should be
     * fetched in order to be displayed as a optional values. This is what is happening in this page.
     * @param {String} category The category of the item (companies, sites, farms)
     * @param {Object} item The item
     */
    fetchData = (category, item) => {
        /*  
            ROUTES 
            /companies/{cid}/sites
            /sites/{sid}/farms
            /farms/{fid}/generators
        */
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        let route;
        if (category === "company") {
            route = "companies/" + item.id + "/sites"
        }
        else if (category === "site") {
            route = "sites/" + item.id + "/farms"
        }
        else if (category === "farm") {
            route = "farms/" + item.id + "/generators"
        }
        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                if (response.data.length === 0) {
                    const childname = category === "company" ? "sites" : category === "site" ? "farms" : category === "farm" ? "generators" : "'?'"
                    this.setAlert("warning", "No " + childname + " are found for the " + category + ": " + item.name)
                    return
                }
                response.data.map((incomingItem) => {
                    incomingItem['view_name'] = item['name'] + ": " + incomingItem['name'] // <p><span style={spanStyle}>{item['name'] +":"}</span> {incomingItem['name']}</p>
                    incomingItem['children'] = []
                    item['children'].push(incomingItem)
                })
                let newArray;
                if (category === "company") {
                    //we got sites
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'] = item['children']//newArray[elementsIndex] = {...newArray[elementsIndex], children: item['children']}
                }
                else if (category === "site") {
                    //we got farms
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = item['children']  //{...newArray[elementsIndex]['children'][elementsSitesIndex], children: item['children']} 
                }
                else if (category === "farm") {
                    //we got generators
                    const elementsIndex = this.state.optionData.findIndex(element => element.id === item.company_id)
                    const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === item.site_id)
                    const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === item.id)
                    newArray = [...this.state.optionData]
                    newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = item['children'] //{...newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex], children: item['children']} 
                }
                this.setState({
                    optionData: newArray,
                }, () => {
                    console.log("New state: ", this.state)
                    this.checkDisables()
                })
            })
            .catch(error => {
                console.log("Error on fetching data")
                console.log(error)
            });
    }
    /**
     * When the user searches without to fullfil all the multiselectors, then the datatree of the 
     * selected item should be fetched and added. This is what this fucntion does. The datatree starts 
     * from the latest item category that is filled.
     */
    fetchAllData = () => {
        const { selectedGenerators, selectedFarms, selectedSites, selectedCompanies } = this.state
        let params = {}
        if (selectedGenerators.length > 0) {
            return
        }
        else if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm_ids"] = farmsIds
        }
        else if (selectedSites.length > 0) {
            const siteIds = selectedSites.map(site => site.id)
            params["site_ids"] = siteIds
        }
        else if (selectedCompanies.length > 0) {
            const compIds = selectedCompanies.map(comp => comp.id)
            params["comp_ids"] = compIds
        } else {
            this.setAlert("warning", "Please, fill the reaquest fields.")
            return
        }
        const requestRoute = process.env.REACT_APP_API_URL + "datatree"
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            let results = response.data
            if (selectedFarms.length > 0) {
                let upldatedSelectedFarms = selectedFarms
                let newSelectedGens = selectedGenerators
                upldatedSelectedFarms.forEach(farm => farm['children'] = results.filter(f => farm.id === f.id)[0]['generators'])
                results.map(farm => farm['generators'].map(gen => newSelectedGens.push(gen)))
                this.setState({
                    newSelectedFarms: upldatedSelectedFarms,
                    selectedGenerators: newSelectedGens
                })
            }
            else if (selectedSites.length > 0) {
                let updatedSites = selectedSites
                let newSelectedGens = selectedGenerators
                let newSelectedFarms = selectedFarms
                updatedSites.forEach(site => site['children'] = results.filter(s => site.id === s.id)[0]['farms'])
                results.map(site => site["farms"].map(farm => {
                    newSelectedFarms.push(farm)
                    farm['children'] = farm['generators']
                    farm['children'].map(gen => {
                        newSelectedGens.push(gen)
                    })
                }))
                this.setState({
                    selectedSites: updatedSites,
                    selectedGenerators: newSelectedGens,
                    selectedFarms: newSelectedFarms
                })
            }
            else if (selectedCompanies.length > 0) {
                let updatedCompanies = selectedCompanies
                let newSelectedGens = selectedGenerators
                let newSelectedFarms = selectedFarms
                let newSelectedSites = selectedSites
                updatedCompanies.forEach(comp => comp['children'] = results.filter(c => comp.id === c.id)[0]['sites'])
                results.map(comp => {
                    comp['children'] = comp['sites']
                    comp['children'].map(site => {
                        newSelectedSites.push(site)
                        site['children'] = site['farms']
                        site['children'].map(farm => {
                            newSelectedFarms.push(farm)
                            farm['children'] = farm['generators']
                            farm['children'].map(gen => {
                                newSelectedGens.push(gen)
                            })
                        })
                    })
                })
                this.setState({
                    selectedCompaines: updatedCompanies,
                    selectedGenerators: newSelectedGens,
                    selectedFarms: newSelectedFarms,
                    selectedSites: newSelectedSites
                })
            }
        })
            .catch(error => {
                // this.setAlert("danger", "Errorfull request")
                console.log("Got error on loading data tree request")
                console.log(error.response)
            })
    }

    /**
     * Checks which of the multiselects should be disabled or not. 
     */
    checkDisables = () => {
        // console.log("CHECKING DISABLES")
        // const { optionData } = this.state
        // let mySitesDisabled = true
        // let myFarmsDisabled = true
        // let myGeneratorsDisabled = true

        // if (optionData.length > 0) {
        //     const sitesData = []            
        //     optionData.map( company => company['children'].map(site => sitesData.push(site)))
        //     const farmsData = []
        //     sitesData.map( site => site['children'].map(farm => farmsData.push(farm)))
        //     const generatorsData = []
        //     farmsData.map( farm => farm['children'].map(gen => generatorsData.push(gen)))
        //     if(sitesData.length > 0){
        //         // console.log("found sites")
        //         mySitesDisabled = false
        //         if( farmsData.length >0 ){
        //             // console.log("found farm")
        //             myFarmsDisabled = false
        //             if( generatorsData.length > 0 ){
        //                 // console.log("found generators")
        //                 myGeneratorsDisabled = false                        
        //             }
        //         }
        //     }
        //     this.setState({
        //         sitesDisabled: mySitesDisabled,
        //         farmsDisabled: myFarmsDisabled,
        //         generatorsDisabled: myGeneratorsDisabled
        //     })
        // }
        // else
        //     console.warn("company option DATA are empty")
        let { selectedCompanies, selectedSites, selectedFarms } = this.state
        let disables = {
            sitesDisabled: !selectedCompanies.length > 0,
            farmsDisabled: !selectedSites.length > 0,
            generatorsDisabled: !selectedFarms.length > 0
        }
        return disables
    }
    /**
     * Updates and syncs the selected item in the states onSelect event
     * @param {String} category 
     * @param {Array} selectedList 
     * @param {Object} selectedItem 
     */
    onSelect = (category, selectedList, selectedItem) => {
        if (category === 'generator') {
            let selected = this.state.selectedGenerators;
            selected.push(selectedItem)
            this.setState({
                selectedGenerators: selected,
            }/*, () => this.checkDisables()*/)
            return
        }
        this.fetchData(category, selectedItem)
        // until now the below is useless
        if (category === "company") {
            let selected = this.state.selectedCompanies;
            selected.push(selectedItem)
            this.setState({
                selectedCompanies: selected
            })
        }
        else if (category === "site") {
            let selected = this.state.selectedSites;
            selected.push(selectedItem)
            this.setState({
                selectedSites: selected
            })
        }
        else if (category === "farm") {
            let selected = this.state.selectedFarms;
            selected.push(selectedItem)
            this.setState({
                selectedFarms: selected
            })
        }
    }
    /**
     * Updates and syncs the selected item in the states onRemove event
     * @param {String} category 
     * @param {Array} selectedList 
     * @param {Object} selectedItem 
     */
    onRemove = (category, selectedList, removedItem) => {
        if (category === "company") {
            const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.id)
            let newArray = [...this.state.optionData]
            newArray[elementsIndex]['children'] = []
            let newSelectedCompanies = this.state.selectedCompanies
            newSelectedCompanies = newSelectedCompanies.filter(comp => comp.id !== removedItem.id)
            this.setState({
                optionData: newArray,
                selectedCompanies: newSelectedCompanies
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
            this.resetSitesValues()
        }
        else if (category === "site") {
            const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
            const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.id)
            let newArray = [...this.state.optionData]
            newArray = [...this.state.optionData]
            newArray[elementsIndex]['children'][elementsSitesIndex]['children'] = []
            let newSelectedSites = this.state.selectedSites
            newSelectedSites = newSelectedSites.filter(site => site.id !== removedItem.id)
            this.setState({
                optionData: newArray,
                selectedSites: newSelectedSites
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
            this.resetFarmsValues()
        }
        else if (category === "farm") {
            const elementsIndex = this.state.optionData.findIndex(element => element.id === removedItem.company_id)
            const elementsSitesIndex = this.state.optionData[elementsIndex]['children'].findIndex(element => element.id === removedItem.site_id)
            const elementsFarmsIndex = this.state.optionData[elementsIndex]['children'][elementsSitesIndex]['children'].findIndex(element => element.id === removedItem.id)
            let newArray = [...this.state.optionData]
            newArray = [...this.state.optionData]
            newArray[elementsIndex]['children'][elementsSitesIndex]['children'][elementsFarmsIndex]['children'] = []
            let newSelectedFarms = this.state.selectedFarms
            newSelectedFarms = newSelectedFarms.filter(farm => farm.id !== removedItem.id)
            this.setState({
                optionData: newArray,
                selectedFarms: newSelectedFarms
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
            this.resetGeneratorsValues()
        }
        else if (category === "generator") {
            let newSelectedGenerators = this.state.selectedGenerators
            newSelectedGenerators = newSelectedGenerators.filter(gen => gen.id !== removedItem.id)
            this.setState({
                selectedGenerators: newSelectedGenerators
            }, () => {
                console.log("New state: ", this.state)
                // this.checkDisables()
            })
        }
    }
    /**
     * Handles the changes in the checkboxes
     * @param {String} checkboxType 
     */
    toggleTypeCheckbox = (checkboxType) => {
        let newTypes = this.state.eventTypes
        newTypes[checkboxType] = !newTypes[checkboxType]
        this.setState({ eventTypes: newTypes })
    }
    /**
     * Renders the events page
     */
    render() {
        Modal.setAppElement("#root")

        const { optionData, selectedCompanies, selectedSites, selectedFarms, tableSpinner, events, showModal,
            selectedGenerators, date, pagingControl, totalEvents, showDetails } = this.state
        const { sitesDisabled, farmsDisabled, generatorsDisabled } = this.checkDisables()
        const { activeCount, inactiveCount } = this.getActiveCount()
        let searchDisabled = true
        if (auth.role_id <= 1)
            searchDisabled = selectedCompanies.length <= 0
        else
            searchDisabled = selectedSites.length <= 0

        const sitesData = []
        optionData.map(company => company['children'].map(site => sitesData.push(site)))
        const farmsData = []
        sitesData.map(site => site['children'].map(farm => farmsData.push(farm)))
        const generatorsData = []
        farmsData.map(farm => farm['children'].map(gen => generatorsData.push(gen)))

        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} style={{ margin: "0 20%" }} role="alert">{this.state.alertMsg}</div>

        return (
            <div className="container-inner-wrapper">
                <h2 className="my-title"> CCTV  Events </h2>
                <div>{alert}</div>
                <hr />
                <div>
                    <div className="row">
                        {auth.role_id <= 1 && <div className={"col mb-3"}>
                            <Multiselect
                                options={optionData}
                                onSelect={this.onSelect.bind(this, "company")}
                                onRemove={this.onRemove.bind(this, "company")}
                                displayValue="name"
                                placeholder="Company"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefCompanies}
                            />
                        </div>}
                        <div className={sitesDisabled ? "col disabled" : "col mb-3"}>
                            <Multiselect
                                options={sitesData}
                                selectedValues={selectedSites.map(site => site)}
                                onSelect={this.onSelect.bind(this, "site")}
                                onRemove={this.onRemove.bind(this, "site")}
                                displayValue={auth.role_id > 1 ? "name" : "view_name"}
                                placeholder="Site"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefSites}
                            />
                            {sitesDisabled && <p style={warningStyle}> Unavailable: please select a Company </p >}
                            {selectedSites.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetSitesValues()}>Clear</button>}

                        </div>
                        <div className={farmsDisabled ? "col disabled" : "col mb-3"}>
                            <Multiselect
                                options={farmsData}
                                selectedValues={selectedFarms.map(farm => farm)}
                                onSelect={this.onSelect.bind(this, "farm")}
                                onRemove={this.onRemove.bind(this, "farm")}
                                displayValue="view_name"
                                placeholder="Farm"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefFarms}
                            />
                            {farmsDisabled && <p style={warningStyle}> Unavailable: please select a Site </p >}
                            {selectedFarms.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetFarmsValues()}>Clear</button>}

                        </div>
                        <div className={generatorsDisabled ? "col disabled" : "col mb-3"}>
                            <Multiselect
                                options={generatorsData}
                                selectedValues={selectedGenerators.map(gen => gen)}
                                onSelect={this.onSelect.bind(this, "generator")}
                                onRemove={this.onRemove.bind(this, "generator")}
                                displayValue="view_name"
                                placeholder="Generator"
                                closeIcon="cancel"
                                avoidHighlightFirstOption={true}
                                ref={this.multiselectRefGenerators}
                            />
                            {generatorsDisabled && <p style={warningStyle}> Unavailable: please select a Farm </p >}
                            {selectedGenerators.length > 0 && <button type="button" className="btn btn-link float-right sm" onClick={() => this.resetGeneratorsValues()}>Clear</button>}
                        </div>

                    </div>
                    <div className="d-flex row ">

                        <div className="col">
                            <FormLabel component="legend"><span style={{ color: "#ffffff" }}>Calendar:</span></FormLabel>

                            <DateRangePicker
                                date={date}
                                onChangeDate={this.onChangeDate}
                            />
                        </div>

                        <div className="align-text-bottom col">
                            <span style={{ margin: "20px" }} />
                            <button className={searchDisabled ? "btn btn-primary disabled" : "btn btn-primary"} style={buttonStyle} onClick={() => this.search()}>Search</button>

                            <button className="btn btn-danger" style={buttonStyle} onClick={() => this.resetAll()} >Reset</button>
                        </div>
                    </div>

                </div>

                {totalEvents > 0 && <div style={{ marginTop: '50px' }}>
                    <div className="row">
                        <div className="col-lg">
                            <strong style={{ color: "#000" }}>Total: </strong>{totalEvents}
                        </div>
                        <div className="col-lg">
                            <strong style={{ color: "#444" }}>Items per Page : </strong>{events.length}
                        </div>
                    </div>

                    <CCTVEventsTable
                        events={events}
                        tableSpinner={tableSpinner}
                        pagingControl={pagingControl}
                        changePage={this.changePage}
                        setAlert={this.setAlert}
                    />
                    <div className="float-right">

                        {!showDetails &&
                            <div>

                                <button className="btn btn-primary " style={buttonStyle} onClick={this.downloadReport} >
                                    <span className="d-none d-lg-inline">Download Excel</span>
                                    <FontAwesomeIcon icon={faFileExcel} style={{ marginLeft: "5px" }} />
                                </button>
                            </div>
                        }
                    </div>


                    {showDetails &&
                        <Reports
                            companies={selectedCompanies}
                            sites={selectedSites}
                            farms={selectedFarms}
                            generators={selectedGenerators}
                            date={date}
                            downloadReport={this.downloadReport}

                        />
                    }
                </div>}
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    style={modalStyle}
                >
                    <div className="card">
                        <div className="card-body" style={modalCard}>
                            <h5 className="card-title text-center">Exporting to Excel</h5>

                            <div className="text-center" >
                                <br />
                                <LittleSpinner />
                            </div>
                        </div>
                    </div>

                </Modal>
            </div >
        )
    }
}

const buttonStyle = { margin: "10px 10px" }

const buttonLink = { color: "white" }

const warningStyle = { color: "#7a7979" }

const spanStyle = { color: "#555" }

const modalStyle = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderStyle: "none",
        padding: "0 0"
    },
    overlay: { zIndex: 10000 },
}

const modalCard = {
    padding: "25px 90px",
    borderStyle: "none",
    border: "1px solid #dde7f1"
}

export default CCTVEvents