import React, { Component } from 'react'
import axios from 'axios'
import auth from './helpers/auth'
import Spinner from './Spinner'
// import PinchZoomPan from "react-responsive-pinch-zoom-pan";


export class CCTVEventImageModal extends Component {

    canvasRef = React.createRef();

    state = {
        spinner: true,
        canvas: {
            canvasWidth: 1000,
            canvasHeight: 1000
        },
        viewWidth: 0.8,
        imageLoaded: false
    }

    /**
     * On mount of the component a get request is done in order to get the image url.
     * After that displays it properly
     */
    componentDidMount() {
        const cWidth = parseInt(window.innerWidth * this.state.viewWidth)
        const cHeight = parseInt(cWidth / 1.9)
        this.setState({
            canvas: {
                canvasWidth: cWidth,
                canvasHeight: cHeight
            }
        })

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        let eid = this.props.eventid
        const url = process.env.REACT_APP_API_URL + 'cctv_events/' + eid + '/image';
        axios.get(url, config)
            .then(response => {
                const event = response.data.event
                const imageUrl = response.data.image_url
                this.initImage(imageUrl);
                console.log(event.generator.name);
                this.setState({
                    eventid: eid,
                    event: event,
                    imageUrl: imageUrl,
                    error: false,
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                this.setState({
                    eventid: eid,
                    error: true,
                    errorLog: error.response.data.error,
                    spinner: false
                })
            });
    }
    /**
     * loads the image and then draws it on the canvas
     * @param {string} iurl image url on the S3
     */
    initImage = (iurl) => {
        let image = new Image();
        image.src = iurl
        this.setState({
            image: image,
            imageLoaded: false
        })
        image.onload = () => {
            this.setState({
                imageLoaded: true
            })
            console.log("Image loaded")
            this.drawImage()
        }
        console.log("Image initialized.. waiting to load")
    }
    /**
     * draws the image properly on the canva
     */
    drawImage = () => {
        const { canvasWidth, canvasHeight } = this.state.canvas
        const { image } = this.state
        const ctx = this.canvasRef.current.getContext('2d')

        console.log("Drawing image")
        console.log("Canvas shape: ", canvasWidth, canvasHeight)
        console.log("Image shape:", image.width, image.height)
        // const projectiveScale = image.width/canvasWidth;
        const projectiveScale = image.height / canvasHeight;
        console.log("projectiveScale: ", projectiveScale)
        this.setState({
            projectiveScale: projectiveScale
        })

        const newWidth = parseInt(image.width / projectiveScale)
        const newHeight = parseInt(image.height / projectiveScale)
        this.setState({
            canvas: {
                canvasWidth: newWidth,
                canvasHeight: newHeight
            }
        })
        console.log("New Image shape:", newWidth, newHeight)
        ctx.drawImage(image, 0, 0, newWidth, newHeight);

    }


    /**
     * opens the image in a new tab
     */
    onNewTab = () => {
        const { imageUrl } = this.state
        let win = window.open(imageUrl, '_blank');
        win.focus();
    }

    /**
     * Renders the content of the modal. It contains some details and the main image.
     */
    render() {
        const { spinner, eventid, error, imageLoaded } = this.state
        const evt = this.state.event
        const { canvasWidth, canvasHeight } = this.state.canvas
        if (spinner)
            return <Spinner />
        let modalBody
        if (!error)
            modalBody =
                <div style={modalBodyStyle}>
                    <div className="table-responsive">
                        <table className="table  events-table" style={{ width: String(this.state.viewWidth * 100) + "%", textAlign: "center", margin: "0 auto" }}>
                            <tbody>
                                <tr style={bgColor}>
                                    <th className="d-none d-md-table-cell">Generator</th>
                                    <th className="d-none d-md-table-cell">Start Time</th>
                                    <th className="d-none d-md-table-cell">End Time</th>
                                    <th className="d-none d-md-table-cell">Duration</th>
                                    <th className="d-none d-md-table-cell">Length</th>
                                </tr>
                                <tr>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Generator: </span>{evt.generator.name}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Start Time: </span>{evt.StartTime}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">End Time: </span>{evt.EndTime}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Duration: </span>{evt.Duration}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Length: </span>{evt.Length}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* <PinchZoomPan> */}
                    <canvas ref={this.canvasRef} width={canvasWidth} height={canvasHeight} style={canvasStyle}> </canvas>
                    {/* </PinchZoomPan> */}

                    {!imageLoaded &&
                        <div className="spinner-border  my-spinner" role="status" style={canvasLoadingSpinner}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    }

                    <p> To open image in a new Tab click <button className="btn btn-info" onClick={() => this.onNewTab()}>here</button></p>
                </div>
        else
            modalBody =
                <div style={modalBodyStyle}>
                    <p>{this.state.errorLog}</p>
                </div>
        return (
            <div className="text-center modalWindow" style={{ marginBlock: "50px" }}>
                <h1 className="my-title-centered"> CCTV Event #{eventid ? eventid : " - "} </h1>
                <hr style={{ margin: "1% 0" }} />
                {modalBody}
                <button className="btn btn-danger" onClick={() => this.props.onRequestClose()}>Close </button>
            </div>
        )
    }
}

const canvasLoadingSpinner = {
    position: "absolute",
    left: "0",
    right: "0",
    margin: "15% auto",
    zIndex: 10000
}

const bgColor = {
    backgroundColor: "#f4f4f6"
}

const canvasStyle = {
    border: "solid 2px #777",
    borderRadius: "5px",
    backgroundColor: "#f4f4f6"
}

const modalBodyStyle = {
    marginTop: "50px"
}


export default CCTVEventImageModal
