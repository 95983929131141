import React, { Component } from 'react'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';


export class DateRangePicker extends Component {
    /**
     * a simple component for date range picking
     */
    render() {
        const { date } = this.props
        return (
            <div style={dateBlockStyle} className="date-picker">
                {/* <p> Select Dates: </p> */}
                <DateTimeRangePicker
                    onChange={this.props.onChangeDate}
                    value={date}
                    disableClock={true}
                    format={"dd-MM-y"}
                    minDate={new Date(2020, 2, 10)}
                    maxDate={new Date()}

                />
                <br />
                <button
                    className="btn btn-link"
                    style={buttonStyle}
                    onClick={() => {
                        let startDate = new Date(2020, 2, 10)
                        this.props.onChangeDate([startDate, new Date()])
                    }}
                >
                    All
                </button>
                <button
                    className="btn btn-link"
                    style={buttonStyle}
                    onClick={() => {
                        let lastMonth = new Date()
                        lastMonth.setMonth(lastMonth.getMonth() - 1)
                        this.props.onChangeDate([lastMonth, new Date()])
                    }}
                >
                    Last Month
                </button>
                <button
                    className="btn btn-link"
                    style={buttonStyle}
                    onClick={() => {
                        let lastweek = new Date()
                        lastweek.setDate(lastweek.getDate() - 7)
                        this.props.onChangeDate([lastweek, new Date()])
                    }}
                >
                    Last Week
                </button>
                <button
                    className="btn btn-link"
                    style={buttonStyle}
                    onClick={() => {
                        let yesterday = new Date()
                        yesterday.setDate(yesterday.getDate() - 1)
                        this.props.onChangeDate([yesterday, new Date()])
                    }}
                >
                    Yesterday
                </button>
            </div>
        )
    }
}

const dateBlockStyle = {
    marginTop: '10px'
}

const buttonStyle = {
    padding: "0 3px",
    color: "#0000ee"
}

export default DateRangePicker
