import React, { Component } from 'react'

export class FormFarmDetails extends Component {
    /**
     * Form to set the details of a farm
     */

    validate() {
        const { values } = this.props

        if (
            values.farmName.length > 0
        )
           
                return true
        else return false
    }

    previous = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    continue = e => {
        e.preventDefault();
        this.validate() ? this.props.register() : this.props.setAlert('alert alert-danger text-center', 'All the fields with (*) should be filled')
    }


    render() {
        const { values, handleChange } = this.props

        return (
            <div className="form-horizontal">

                <div className="form-group">
                    <label htmlFor="companyName" className="control-label">Farm Name (*)</label>
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            ref="farmName"
                            name="farmName"
                            value={values.farmName}
                            onChange={handleChange('farmName')}
                            required autoFocus
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="latitude" className="control-label">Latitude</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="latitude"
                                    name="latitude"
                                    value={values.latitude}
                                    onChange={handleChange('latitude')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="longitude" className="control-label">Longitude</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="longitude"
                                    name="longitude"
                                    value={values.longitude}
                                    onChange={handleChange('longitude')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                </div>

            
                <br />

                <div className="row d-flex">
                    <button className="btn btn-primary mx-auto register-button" onClick={this.continue}>
                        <span>Register</span>
                    </button>
                </div>
                <br/>
                <br />


                <div className="text-center">
                    <img
                        src={process.env.PUBLIC_URL + '/default_generators.png'}
                        alt="default generators logo"
                        style={this.props.imgStyle}
                    />
                </div>

            </div>
        )
    }
}

export default FormFarmDetails
