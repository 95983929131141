import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faDatabase, faFileCode, faFan, faSave, faVideo, faBell, faUserFriends, faDesktop, faSitemap, faCloudUploadAlt, faCrow, faCogs, faChartBar, faMapMarkerAlt, faCheck, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import auth from '../helpers/auth'


export class Sidebar extends Component {

    getCorrectRoute = (item) => {
        const { dropDownContent, currentLocation } = this.props
        if (item !== "All") {
            const corePath = "/" + dropDownContent + "/" + item.id
            if (currentLocation === "overview")
                return corePath
            else if (currentLocation === "users") {
                if (dropDownContent === "companies")
                    return corePath + "/" + currentLocation
                else
                    return "/users"
            }
            else if (currentLocation === "events")
                return "/events"
            else if (currentLocation === "stats")
                return "/stats"
            else
                return corePath + "/" + currentLocation
        }
        else
            return "/" + currentLocation
    }

    componentDidMount() {
        if (this.props.dropDownSync) {
            this.props.getDropDownContents()
        }
    }

    render() {
        const { dropDownData, dropDownSync, dropDownContent,
            currentPlaceHolder, dropDownSelectedValue } = this.props;
        // if (dropDownSync){
        //     this.props.getDropDownContents()
        //     this.props.setDropDownSync(false)
        // }
        // const phpmyadmin = process.env.REACT_APP_PHPMYADMIN
        // console.log("phpmyadmin: " + phpmyadmin)

        return (
            <div>
                {/* Sidebar  */}
                <div className="border border-dark" id="sidebar-wrapper" style={wrapperStyle}>
                    <div className="list-group list-group-flush sticky-top" style={topStyle}>
                        <nav>
                            <div className="btn-group list-group-item">
                                <button
                                    id="dropdownButton"
                                    type="button"
                                    className="btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    data-display="static"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={selectStyle}
                                >
                                    {currentPlaceHolder}
                                </button>
                                <ul className="dropdown-menu" style={dropdownListStyle}>
                                    <li>
                                        <NavLink
                                            className="btn my-navlink"
                                            to={() => this.getCorrectRoute('All')}
                                            onClick={() => this.props.selectOnDropDown('All')}
                                        >
                                            All
                                        </NavLink>
                                    </li>
                                    {dropDownData.map((object, index) =>
                                        <li key={index}>
                                            <NavLink
                                                className="btn my-navlink"
                                                to={() => this.getCorrectRoute(object)}
                                                onClick={() => this.props.selectOnDropDown(object)}
                                            >
                                                {object.name}
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>

                            </div>
                            {auth.role_id <= 1 && <NavLink
                                to={currentPlaceHolder !== "All" ?
                                    "/" + dropDownContent + "/" + dropDownSelectedValue.id + "/dashboard"
                                    : "/dashboard"}
                                onClick={() => this.props.changeCurrentLocation("dashboard")}
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faDesktop} />
                                <span style={padding} />Administration
                            </NavLink>}
                            <NavLink
                                to={currentPlaceHolder !== "All" ?
                                    "/" + dropDownContent + "/" + dropDownSelectedValue.id
                                    : "/overview"}
                                onClick={() => this.props.changeCurrentLocation("overview")}
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faSitemap} />
                                <span style={padding} />Wind Park Overview
                            </NavLink>
                            <NavLink
                                exact to={currentPlaceHolder !== "All" ?
                                    "/" + dropDownContent + "/" + dropDownSelectedValue.id + "/generators"
                                    : "/generators"}
                                onClick={() => this.props.changeCurrentLocation("generators")}
                                className="list-group-item list-group-item-action "
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faFan} />
                                <span style={padding} />Wind Turbine
                            </NavLink>
                            <NavLink
                                exact to="/stats"
                                onClick={() => this.props.changeCurrentLocation("stats")}
                                className="list-group-item list-group-item-action "
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faChartBar} />
                                <span style={padding} /> Statistics
                            </NavLink>
                            <NavLink
                                exact to="/events"
                                onClick={() => this.props.changeCurrentLocation("events")}
                                className="list-group-item list-group-item-action "
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faBell} />
                                <span style={padding} />  Bird / Bat Events
                            </NavLink>
                            <NavLink
                                exact to="/cctv_events"
                                onClick={() => this.props.changeCurrentLocation("cctv_events")}
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faVideo} />
                                <span style={padding} /> CCTV Events
                            </NavLink>
                            <NavLink
                                exact to="/gps"
                                onClick={() => this.props.changeCurrentLocation("gps")}
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                <span style={padding} /> GPS / GIS
                            </NavLink>
                            <NavLink
                                exact to="/health-status"
                                onClick={() => this.props.changeCurrentLocation("health-status")}
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                                <span style={padding} /> Health Status
                            </NavLink>
                            <NavLink
                                exact to="/algorithm-settings"
                                onClick={() => this.props.changeCurrentLocation("algorithm-settings")}
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faSlidersH} />
                                <span style={padding} /> Algorithm Settings
                            </NavLink>

                            <NavLink
                                exact to={dropDownContent === "companies" ?
                                    currentPlaceHolder !== "All" ? "/companies/" + dropDownSelectedValue.id + "/users"
                                        : "/users" : "/users"}
                                onClick={() => this.props.changeCurrentLocation("users")}
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faUserFriends} />
                                <span style={padding} />Users
                            </NavLink>

                            {/* Ornithologists part */}
                            {(auth.role_id === 0 || auth.role_id === 4) && <NavLink
                                to="/upload-annotations"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faCrow} />
                                <span style={padding} />Annotations
                            </NavLink>}


                            {/* DEVELOPERS PART */}
                            {/* { auth.role_id === 0 && <hr className="my-hr" style={{marginTop:"15%", marginBottom:"15%"}}/>} */}
                            {auth.role_id === 0 && <div style={{ marginTop: "35%" }} />}

                            {auth.role_id === 0 && <NavLink
                                to="/upload-files"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faCloudUploadAlt} />
                                <span style={padding} />Uploads View
                            </NavLink>}

                            {auth.role_id === 0 && <NavLink
                                to="/config-control"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faCogs} />
                                <span style={padding} />Config Control
                            </NavLink>
                            }
                            {auth.role_id === 0 && <NavLink
                                to="/generator-config-control"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faCogs} />
                                <span style={padding} />Generator Config Control
                            </NavLink>
                            }
                            {/* { auth.role_id === 0 && <hr className="my-hr" style={{marginTop:"15%", marginBottom:"15%"}}/>} */}
                            {auth.role_id === 0 && <div style={{ marginBottom: "35%" }} />}

                            {/* Third party software links */}
                            {auth.role_id === 0 && <a
                                href={process.env.REACT_APP_PHPMYADMIN}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faDatabase} />
                                <span style={padding} />PhpMyAdmin
                            </a>}
                            {auth.role_id === 0 && <a
                                href={"https://s3.console.aws.amazon.com/s3/buckets/bms-webapp-bucket/?region=eu-west-3&tab=overview"}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faSave} />
                                <span style={padding} />S3 Storage
                            </a>}
                            {auth.role_id === 0 && <a
                                href={"https://bitbucket.org/up2metricPC/bms-webdeployment/src/master/"}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faCode} />
                                <span style={padding} />BitBucket
                            </a>}
                            {auth.role_id === 0 && <NavLink
                                to="/testpage"
                                className="list-group-item list-group-item-action"
                                style={navLinkStyle}
                            >
                                <FontAwesomeIcon icon={faFileCode} />
                                <span style={padding} />Test Page
                            </NavLink>}
                        </nav>
                    </div>
                </div>
            </div >
        )
    }
}

const topStyle = {
    top: "63px"
}

const wrapperStyle = {
    height: "100%",
}

const navLinkStyle = {
    zIndex: "2",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    borderTop: "1px solid rgba(0,0,0,.125)",
    color: "#495057"
}

const dropdownListStyle = {
    padding: "0",
    backgroundColor: " #f8f9fa",
    color: "#212529",
    width: "70%",
    overflow: "auto",
}

const selectStyle = {
    padding: "5px",
}

const padding = {
    padding: "10px"
}

export default Sidebar
