import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import auth from './auth'

/**
 * This function checks if the user that requested this route is authenticated.
 * If he is not, the he is redirected to the Login page.
 * 
 * The routes under this component cannot be reached if no authentication have been taken part
 * @param {Array} param0 
 */
export const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render ={
            (props) => {
                if(auth.isAuthenticated()){
                    return <Component {...props}  />
                }
                else {
                    return<Redirect to={
                        {
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }
                    }/>
                }
            }
        }/>
    )
}