import React, { Component } from 'react'
import axios from 'axios';
import qs from 'qs';
import auth from './helpers/auth'

import GeneratorCard from './GeneratorCard'
import CameraCard from './CameraCard'
import ReportSet from './charts/ReportSet'
import GeneratorSummary from "./GeneratorSummary";
import EventSummary from "./EventSummary";

const components = {
    generators: GeneratorCard,
    cameras: CameraCard,
    reports: ReportSet
}

class ItemsPerFarm extends Component {
    state = {
        statistics: {
            detections: -1,
            deterrences: -1,
            shutdowns: -1
        },
        activeCount: -1,
        inactiveCount: -1,
        spinner: true,

    }

    componentDidMount() {
        if (this.props.item === 'reports') {
            this.fetchStatistics()
        }
    }

    fetchStatistics() {
        const { farm, date, eventTypes } = this.props
        let params = {}
        const generators = farm['generators']
        const genIds = generators.map(gen => gen.id)
        params["gen"] = genIds

        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        const requestRoute = process.env.REACT_APP_API_URL + "events/statistics"

        let selectedTypes = []
        if (eventTypes['detection'])
            selectedTypes.push('detection')
        if (eventTypes['deterrence'])
            selectedTypes.push('deterrence')
        if (eventTypes['shutdown'])
            selectedTypes.push('shutdown')


        params["from"] = fromDate
        params["to"] = toDate
        params["eventtypes"] = selectedTypes

        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            const statistics = {
                detections: response.data.detections,
                deterrences: response.data.deterrences,
                shutdowns: response.data.shutdowns
            }
            this.setState({
                statistics: statistics,
            }, () => console.log(this.state))
        })
            .catch(error => {
                console.log("Got error on statistics (ItemsPerFarm) request")
                console.log(error.response)
            })
        this.setState({
            activeCount: this.getActiveCount(),
            inactiveCount: this.getInactiveCount(),
            spinner: false
        })
    }

    getActiveCount() {
        return this.props.farm['generators'].filter(gen => gen.isActive === 1).length
    }

    getInactiveCount() {
        return this.props.farm['generators'].filter(gen => gen.isActive === 0).length
    }

    render() {
        const { farm, item } = this.props
        const Item = components[item]
        let results = <h2 className="no-data">No generators found</h2>

        if (item === 'generators') {
            if (farm.generators.length) {
                results =
                    <div className="row">
                        {farm.generators.map((generator, index) => (
                            <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
                                <Item generator={generator} key={generator.id} />
                            </div>
                        ))}
                    </div>
            }
        }

        else if (item === 'summaries') {
            results =
                <div >
                    <GeneratorSummary generators={farm.generators} />
                    <EventSummary content={farm.generators} selectedFarms={[farm]} selectedGenerators={farm.generators} selectedCameras={[]} />
                    <hr className="my-hr" />
                </div >
        }

        else if (item === 'cameras') {
            if (farm.generators.length) {
                results =
                    <div className="row  ">
                        {farm.generators.map(generator =>
                            generator.cameras.map((camera, index) => (
                                <div className="col-xl-3 col-md-6 col-sm-12" key={index}>
                                    <Item camera={camera} generator_id={generator.id} key={camera.id} generator_name={generator.name} />
                                </div>
                            )))}
                    </div>
            }
            else {
                results = <h2 className="no-data">No cameras found</h2>
            }
        }
        else if (item === 'reports') {
            results =
                <div className="row ">
                    <Item statistics={this.state.statistics} active={this.state.activeCount} inactive={this.state.inactiveCount} />
                </div>
        }

        return (
            <div style={{ width: "100%" }}>
                <div className="row no-gutters">
                    <h3 style={titleStyle}><span style={spanStyle}>Farm: </span>{farm.name}</h3>
                </div>
                <br />
                {results}
                <br />
            </div>
        )
    }
}


const spanStyle = { color: "#ffffff" }

const titleStyle = {
    fontWeight: "200",
    fontSize: "2rem",
    color: "#ffffff",
}


export default ItemsPerFarm