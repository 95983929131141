import React, { Component } from 'react';
import auth from './helpers/auth';
import axios from 'axios';
import { Link } from 'react-router-dom';

class GeneratorStatsTable extends Component {

    state = {
      farm : this.props.farm
    }
   /**
     * Asks the backend to ping the generator with id gid
     * and then to respong with his status
     * @param {Generator} generator 
     */
    ping = (generator, index) => {
      const {farm} = this.state;
      console.log("pinging generator: " + generator.id)
      const config = {
          headers: { Authorization: `Bearer ${auth.token}` }
      };
      axios.get(process.env.REACT_APP_API_URL + 'generators/' + generator.id + "/ping", config)
          .then(response => {
              let pingResults = response.data
              console.log("ping results: ", pingResults) //<----check that!!!
              generator.twoway = 1;
              farm.generators[index] = generator;
              this.setState({
                farm: farm
              })

          })
          .catch(error => {
              console.log("got error on ping")
              console.log(error)
              generator.twoway = 2;
              farm.generators[index] = generator;
              this.setState({
                farm
              })
          });
  }
  /**
   * some operations on componentDidMount
   * @returns 
   */
  componentDidMount(){
    const {farm} = this.state;
    {farm.generators.map((generator, index) => 
      {
        this.ping(generator, index)
      }
    )};
  }
  render(){
    const {farm} = this.state;
    let results;
    let enabledCircleBtn = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24px" fill="#33cc33"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
    let disabledCircleBtn = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24px" fill="#cdcdcd"><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>

    if (farm.generators.length) {
      results =
        <div style={{ "marginTop": "10px", paddingBottom: "20px"}} className="table-responsive col-12">
                <table className="table table-bordered generator-stats-table">
                  <thead>
                    <tr style={normalBreak} >
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/generator.png'} alt="Generator" style={imgStyle} />
                      </td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/bird.png'} alt="Generator" style={imgStyle} />
                      </td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/bird-radio.png'} alt="Generator" style={imgStyleFullWidth} />
                        {/* <img src={process.env.PUBLIC_URL + '/radio.png'} alt="Generator" style={imgStyle} /> */}
                      </td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/x.png'} alt="Generator" style={imgStyle} />
                      </td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/sun.png'} alt="Generator" style={imgStyle} />
                      </td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/night.png'} alt="Generator" style={imgStyle} />
                      </td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/bats.png'} alt="Generator" style={imgStyle} />
                      </td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>
                        <img src={process.env.PUBLIC_URL + '/2way.png'} alt="Generator" style={imgStyle} />
                      </td>
                    </tr>
                    <tr style={normalBreak} >
                      <td className="d-none d-md-table-cell" style={headerStyle}>WTG</td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>DETECTIONS</td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>DETERRENCES</td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>SHUT-DOWNS</td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>DAY BIRDS</td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>NIGHT BIRDS</td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>BATS</td>
                      <td className="d-none d-md-table-cell" style={headerStyle}>2-WAY COM</td>
                          </tr>
                  </thead>
                    <tbody style={wordBreak}>
                        {farm.generators.map((generator, index) => (
                          <tr style={normalBreak} key={index} className="smallDeviceAdjustment">
                            <td className='d-md-table-cell '> <span className='d-md-none smallDeviceTitle'>WTG:</span> <Link to={"/generators/" + generator.id}>{generator.name}</Link></td>
                            <td className='d-md-table-cell text-md-right '> <span className='d-md-none smallDeviceTitle'>DETECTIONS: </span>{generator.detections} </td>
                            <td className='d-md-table-cell text-md-right '> <span className='d-md-none smallDeviceTitle'>DETERRENCES:</span>{generator.deterrences} </td>
                            <td className=' d-md-table-cell text-md-right '> <span className='d-md-none smallDeviceTitle'>SHUT-DOWNS:</span>{generator.shutdowns} </td>
                            <td className='d-md-table-cell text-md-center '> <span className='d-md-none smallDeviceTitle'>DAY BIRDS:</span>
                            { generator.cameras.filter(camera => camera.type === "RGB").length > 0
                            ? <span>{enabledCircleBtn} {generator.daybirds > 0 ? <span className='d-inline-block d-md-block'>{`( ${generator.daybirds} )`} </span>: null} </span>
                            : disabledCircleBtn
                            }
                            </td>
                            <td className=' d-md-table-cell text-md-center'>  <span className='d-md-none smallDeviceTitle'>NIGHT BIRDS:</span>
                            { generator.cameras.filter(camera => camera.type === "Thermal").length > 0 
                            ? <span>{enabledCircleBtn} { generator.nightbirds > 0 ? <span className='d-inline-block d-md-block'>{`( ${generator.nightbirds} )`} </span> : null}</span>
                            : disabledCircleBtn 
                            } 
                            </td>
                            <td className=' d-md-table-cell text-md-center'>  <span className='d-md-none smallDeviceTitle'>BATS:</span>
                            {generator.bats == 1
                            ? enabledCircleBtn
                            : disabledCircleBtn} 
                            </td>
                            <td className='d-md-table-cell text-md-center'> <span className='d-md-none smallDeviceTitle'>2-WAY COM:</span>
                              {generator.twoway 
                                ? 
                                  (generator.twoway == 1
                                  ? enabledCircleBtn
                                  : disabledCircleBtn
                                  )
                                : <div className="spinner-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                              }
                            </td>
                          </tr>
                        ))}

                    </tbody>
                </table>
          </div>
    }
    return <div style={{width:"100%"}}> 
        {results}
      </div>
  }

}
const normalBreak = {
  wordBreak: "normal"
}

const wordBreak = {
  wordBreak: "break-word"
}
const headerStyle = {
  textAlign: "center",
  fontWeight: "bold",
  wordBreak: "normal"
}
const imgStyle = {
  borderColor: "transparent",
  width: "120px",
  height: "100px",
  maxWidth: "100%",
  minWidth: "120px"
}
const imgStyleFullWidth = {
  borderColor: "transparent",
  height: "100px",
  maxWidth: "100%",
  minWidth: "120px"
}
export default GeneratorStatsTable;
