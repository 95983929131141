import React, { Component } from "react";
import SiteCard from './SiteCard'


class GeneratorSummary extends Component {
    /**
     * Summary about the given generators from the father component
     * - total
     * - Active
     * - Inactive
     */
    render() {
        const { generators } = this.props
        const active = generators.filter(gen => parseInt(gen.isActive) === 1).length
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="flex-col">
                            <h2 className="align-left" style={TitleStyle}>Generator Summary</h2>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        <SiteCard class={"primary all-site-card"} header={"All Generators"} number={generators.length} footer={"All Generators"} />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        <SiteCard class={"success detections-site-card"} header={"All Active Generators"} number={active} footer={"All Active Generators"} />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12">
                        <SiteCard class={"danger shutdowns-site-card"} header={"All Inactive Generators"} number={generators.length - active} footer={"All Inactive Generators"} />
                    </div>
                </div>
            </div>
        );
    }
}

const TitleStyle = {
    marginTop: "1rem",
    marginBottom: "1rem",
    fontWeight: "400",
    fontSize: "1.4rem"
};

export default GeneratorSummary;

