import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'


export class DetectionBirdsBar extends Component {
    getRandomColors = () => {
        const max = 256;
        const min = 0;
        return Math.random() * (max - min) + min;
    }
    getBarColor = index => {
        const BarColors = ["#00FF00", "#0000FF", "#FF0000", "#FFFF00", "#0F0F0F", "#FE00EF"];
        let color = null;
        if (index > BarColors.length) {
            color = this.getRandomColors();
        } else {
            color = BarColors[index];
        }
        return {
            'barColor': color,
            'borderColor': color + "55"
        }
    }

    render() {
        const birdDetectionData = this.props.detection_birds;
        let chartData = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1
                }
            ]
        };
        let totalBirds = 0;
        birdDetectionData.map(detection => {
            totalBirds += detection.detection_count;
        });
        console.log(totalBirds);
        birdDetectionData.map((detection, index) => {
            let birdPercentage = Math.round((parseInt(detection.detection_count) / totalBirds) * 100);
            chartData.labels.push(detection.detection_bird + " ( " + birdPercentage.toString() + "% )");
            chartData.datasets[0].data.push(detection.detection_count);
            let barColor = this.getBarColor(index);
            chartData.datasets[0].backgroundColor.push(barColor.barColor);
            chartData.datasets[0].borderColor.push(barColor.borderColor);
        });

        return (
            <div className="bar-chart">
                <Bar
                    data={chartData}
                    height={200}
                    width={100}

                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Birds Info',
                            lineHeight: 2.7,
                            fontColor: "white"
                        },
                        scales: {
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                    fontColor: "#efefef"
                                }
                            }],
                            xAxes: [{
                                ticks: { fontColor: "#efefef" }
                            }]
                        },
                        datalabels: {
                            display: true,
                            color: "white"
                        }
                    }}
                />
            </div>
        )
    }
}

export default DetectionBirdsBar
