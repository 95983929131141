
import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class ActionDropdown extends Component {
    state = {  }
    render() { 
        const { selectedAction, handleChangeAction } = this.props

        return ( 
            <FormControl style={{margin:"5px auto", width: "100px"}}>
                <InputLabel id="demo-simple-select-helper-label">Select action</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedAction}
                    onChange={handleChangeAction}
                >
                    <MenuItem value="get"> Get </MenuItem>
                    <MenuItem value="set"> Set </MenuItem>
                </Select>
            </FormControl>
         );
    }
}
 
export default ActionDropdown;