import React, { Component } from 'react'
import axios from 'axios'
import auth from '../../helpers/auth'


export class ChangePassword extends Component {
    state = {
        err: 0,
        old_password: '',
        new_password: '',
        repeat_password: '',
    }

    /**
     * Sync the input with the state
     * @param {object} e 
     */
    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    /**
     * Submits the new password
     * @param {bject} e 
     */
    onSubmitPassword(e) {
        e.preventDefault();
        if (this.state.new_password !== this.state.repeat_password) {
            console.log("not same passowrd")
            this.setState({ err: 3 })
            return
        }
        else if (this.state.new_password.length < 8) {
            console.log("passowrd too small")
            this.setState({ err: 4 })
            return
        }

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.post(process.env.REACT_APP_API_URL + 'changepassword', {
            "old_password": this.state.old_password,
            "new_password": this.state.new_password,
            "repeat_password": this.state.repeat_password,
        }, config)
            .then(response => {
                console.log("SUCCESS")
                this.setState({ err: 1 }) //success
                auth.logout(() => console.log("logging out"));

            })
            .catch(error => {
                console.log("ERROR")
                console.log(error)
                this.setState({ err: 2 })
            });
    }

    /**
     * renders the change password view
     */
    render() {

        let error = this.state.err;
        let msg, name;
        if (error === 1) {
            msg = "Password changed with success"
            name = 'alert alert-success'
        }
        else if (error === 2) {
            msg = "Wrong password was given"
            name = 'alert alert-danger'
        }
        else if (error === 3) {
            msg = "Passwords are not the same"
            name = 'alert alert-danger'
        }
        else if (error === 4) {
            msg = "Passwords must be at least 8 characters min"
            name = 'alert alert-danger'
        }

        return (
            <div>
                <div style={ChangePasswordFormStyle}>

                    <div >
                        {error > 0 && <div className={name} role="alert">{msg}</div>}
                    </div>

                    <form className="form-horizontal " method="POST" onSubmit={this.onSubmitPassword.bind(this)}>
                        <div className="form-group">
                            <label htmlFor="old_password" className="control-label">Old Password</label>
                            <input
                                id="old_password"
                                type="password"
                                className="form-control"
                                ref="old_password"
                                name="old_password"
                                value={this.state.old_password}
                                onChange={this.onChange.bind(this)}
                                required autoFocus
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="new_password" className="control-label">New Password</label>
                            <input
                                id="new_password"
                                type="password"
                                className="form-control"
                                ref="new_password"
                                name="new_password"
                                value={this.state.new_password}
                                onChange={this.onChange.bind(this)}
                                required autoFocus
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="repeat_password" className="control-label">Reapeat Password</label>
                            <input
                                id="repeat_password"
                                type="password"
                                className="form-control"
                                ref="repeat_password"
                                name="repeat_password"
                                value={this.state.repeat_password}
                                onChange={this.onChange.bind(this)}
                                required autoFocus
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                                Change
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        )
    }
}

const ChangePasswordFormStyle = {
    margin: "1% 25%"


}

export default ChangePassword
