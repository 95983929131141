import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'

export class StatsBar extends Component {
  render() {
    let chartData = {
      labels: this.props.labels,
      datasets: []
    }
    if (this.props.data.detections) {
      chartData.datasets.push({
        label: "Detections",
        data: this.props.data.detections,
        backgroundColor: '#00FF0055',
        borderColor: '#00FF00',
        borderWidth: 1
      });
    }

    if (this.props.data.deterrences) {
      chartData.datasets.push({
        label: "Deterrences",
        data: this.props.data.deterrences,
        backgroundColor: '#0000FF55',
        borderColor: '#0000FF',
        borderWidth: 1
      });
    }

    if (this.props.data.shutdowns) {
      chartData.datasets.push({
        label: "Shutdown",
        data: this.props.data.shutdowns,
        backgroundColor: '#FF000055',
        borderColor: '#FF0000',
        borderWidth: 1
      });
    }

    return (
      <div className="bar-chart">
        <Bar
          data={chartData}
          options={{
            maintainAspectRatio: true,
            legend: {
              display: true,
              labels: {
                fontColor: "#efefef"
              }
            },
            title: {
              display: true,
              text: 'Detections Per Day',
              lineHeight: 1.7,
              fontColor: "#efefef"
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                  fontColor: "#efefef"
                }
              }],
              xAxes: [{
                ticks: { fontColor: "#efefef" }
              }]
            }
          }}
        />
      </div>
    )
  }
}

// Note: changes to the plugin code is not reflected to the chart, because the plugin is loaded at chart construction time and editor changes only trigger an chart.update().
const image = new Image();
image.src = 'https://www.chartjs.org/img/chartjs-logo.svg';

const plugin = {
  id: 'custom_canvas_background_image',
  beforeDraw: (chart) => {
    if (image.complete) {
      const ctx = chart.ctx;
      const { top, left, width, height } = chart.chartArea;
      const x = left + width / 2 - image.width / 2;
      const y = top + height / 2 - image.height / 2;
      ctx.drawImage(image, x, y);
    } else {
      image.onload = () => chart.draw();
    }
  }
};
export default StatsBar
