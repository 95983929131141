import React, { Component } from 'react'

export class ColoredCircle extends Component {
    /**
     * Simple colored dot with the given color
     */
    render() {
        const { color } = this.props
        return (
            <div className="my-colored-circle" style={{backgroundColor: color}}></div>
        )
    }
}

export default ColoredCircle
