import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import {Slider } from '@material-ui/core';
import { BlockRounded } from '@material-ui/icons';

const SettingsSlider = (props) => {
  const [sliderValue, setSliderValue] = useState(props.value ?? 0);
  const onChangeSlider = (event, value) => {
    setSliderValue(value);
    props.onChange(props.generator_id, value);
  }
  return (
    <div style={sliderComponentStyle}>
      <div style={sliderTitleStyle} className="col-md-3  d-inline-block">
        {props.title}
      </div>
      <div style={sliderValueStyle} className="col-md-2 text-center d-inline-block">
        {sliderValue} {props.measure_label ?? ""}
      </div>
      <div style={sliderIconStyle}  className="col-md-2 text-center align-middle d-inline-block">
        {props.icon}
      </div>
      <div style={sliderContainerStyle}  className="col-md-5  d-inline-block">
        <Slider onChange={(event, value) => { onChangeSlider(event,  value)}} valueLabelDisplay="off" value={sliderValue}  defaultValue={props.value ?? 0} min={props.minimum ?? 0} max={props.maximum ?? 100} aria-label="Default"  style={sliderStyle}/>
        <div style={{"float":"left"}}>
          {props.minimum ?? 0} {props.measure_label ?? ""}
        </div>  
        <div style={{"float":"right"}}>
          {props.maximum ?? 100} {props.measure_label ?? ""}
        </div> 
      </div>  

    </div>
  );
}

const sliderComponentStyle = {
  clear:"both",
  border:"1px solid #ccc",
  padding:"20px",
  overflow:"hidden",
  borderRadius: "10px",
  marginBottom: "20px"
};
const sliderTitleStyle = {
  fontWeight:"bold",
  fontSize: "16px",
  color:"#ffffff"
};
const sliderValueStyle = {
  // color:"#ffffff"
  color: "#FFA500",
  fontWeight:"bold"
};
const sliderIconStyle = {
  color:"#ffffff"
};
const sliderStyle = {
  color: "#ffffff"
};
const sliderContainerStyle = {
  color:"#dedede",
  fontSize:"12px"
};
const sliderRangeContainerStyle = {
  color:"#dedede"
};

export default SettingsSlider;