import React, { Component } from 'react'
import Events from '../Events'

export class DetailsTable extends Component {
    /**
     * displays tables with information about the event
     */
    render() {
        const event = this.props.event
        // const track = event.event_type === "deterrence" ? "BMS-Warning.mp3" : "-"

        return (
            <div style={{ "marginTop": "10px", "width": "100%" }}>
                <h2 className="my-title2" style={{ paddingLeft: "10px" }}>Details</h2>
                <div className="table-responsive">
                    <table className="table events-table">
                        <tbody style={wordBreak}>
                            <tr style={normalBreak} >
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Company</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left"  >Site</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left"  >Farm</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left"  >Generator</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left"  >Camera</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left"  >Action</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Timestamp</th>
                            </tr>
                            <tr>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Company:&nbsp;
                                    </span>
                                    {event.company.name}
                                </td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Site:&nbsp;
                                    </span>
                                    {event.generator.farm.site.name}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Farm:&nbsp;
                                    </span>
                                    {event.generator.farm.name}
                                </td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Generator:&nbsp;
                                    </span>
                                    {event.generator.name}
                                </td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Camera:&nbsp;
                                    </span>
                                    {event.camera.name}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Action:&nbsp;
                                    </span>
                                    <strong>{event.event_type}</strong></td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Timestamp:&nbsp;
                                    </span>
                                    {event.timestamp}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h2 className="my-title2" style={{ paddingLeft: "10px" }}>More Details</h2>
                <div className="table-responsive">
                    <table className="table events-table">
                        <tbody style={wordBreak}>
                            <tr style={normalBreak}>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Warning Duration <br /><small>(seconds)</small></th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Bird</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Sound</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Wind Speed <br /><small>(Km/h)</small></th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Wind Direction <br /><small>(&#730;)</small></th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Nacelle Position</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Node Version</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Model Name</th>

                            </tr>
                            <tr>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Warning Duration <small>(seconds)</small>:&nbsp;
                                    </span>
                                    {event.duration}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Bird:&nbsp;
                                    </span>
                                    {event.bird_name}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Sound:&nbsp;
                                    </span>
                                    {event.horn_sound}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Wind Speed <small>(Km/h)</small>:&nbsp;
                                    </span>
                                    -</td>
                                <td className=" text-lg-center text-left"><span className="d-lg-none font-weight-bold">
                                    Wind Direction  <small>(&#730;)</small>:&nbsp;
                                </span>
                                    -</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Nacelle Position:&nbsp;
                                    </span>
                                    -</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Node Version:&nbsp;
                                    </span>
                                    {event.node_app_version !== undefined ? event.node_app_version : "-"}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Model Name:&nbsp;
                                    </span>
                                    {event.model_name !== undefined ? event.model_name : "-"}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
const normalBreak = {
    wordBreak: "normal",
    backgroundColor: "#6ABBF1"
}

const wordBreak = {
    wordBreak: "break-word"
}

export default DetailsTable
