import React, { Component } from 'react'
import axios from 'axios'
import auth from '../helpers/auth'
import Spinner from '../Spinner'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { smoothToTop } from '../helpers/misc'

export class CameraStreamingDetails extends Component {
    /**
     * Page with steps for streaming RTSP video from a camera
     * throught the VLC
     */

    state = {
        camera: {},
        stream_url: "",
        spinner: true,
        copied: false
    }

    /**
     * Reuquest to get the RTSP stream from the backend
     */
    componentDidMount() {
        document.title = "BMS App - Streaming"

        smoothToTop();
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        let cid = this.props.match.params.id
        axios.get(process.env.REACT_APP_API_URL + 'cameras/' + cid + '/stream-url', config)
            .then(response => {
                const data = response.data
                this.setState({
                    camera: data['camera'],
                    stream_url: data['stream_url'],
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false
                })
            });
    }
    copied = () => {
        this.setState({ copied: true })
        setTimeout(() => this.setState({ copied: false }), 3000);
    }

    render() {
        const { copied, camera, stream_url, spinner } = this.state
        let copy_info
        if (copied) {
            copy_info = <span> Copied! </span>
        }

        if (spinner)
            return (
                <Spinner />
            )

        return (
            <div className="container-inner-wrapper">
                <h2 className="my-title">Camera <small>{camera.name} </small></h2>
                <hr />
                <br />
                <div>
                    <p> Follow the steps below to get the stream on your Computer using the VLC player. </p>
                    <span style={{ color: "#d1950c", fontSize: "15px" }}> This is a first approach for streaming. In future version the streaming will be supported from this WebApp </span>
                    <hr />
                </div>
                <div style={stepsStyle}>
                    <div style={{ marginTop: "10px" }}>
                        <h3> Step 1 </h3>
                        <p>Enable your access to the VPN. This can be done using the OpenVPN.</p>
                    </div>
                    <div style={{ marginTop: "30px" }}>
                        <h3> Step 2 </h3>
                        <p>Copy the <strong>RTSP</strong> url.</p>
                        <span style={streamStyle}> {stream_url} </span>
                        <CopyToClipboard text={stream_url} onCopy={this.copied}>
                            <FontAwesomeIcon icon={faCopy} size='lg' id="copy_button" />
                        </CopyToClipboard>
                        {copy_info}
                    </div>
                    <div style={{ marginTop: "30px" }}>
                        <h3> Step 3 </h3>
                        <p>Open the VLC player in your computer.</p>

                        <div style={stepsStyle}>
                            <div style={{ marginTop: "30px" }}>
                                <p> a. Click on <strong>Media</strong> option in the toolbar at top.</p>
                                <img
                                    src={process.env.PUBLIC_URL + '/stream_info/vlc1.png'}
                                    alt="VLC-step-a"
                                />
                            </div>
                            <div style={{ marginTop: "30px" }}>
                                <p> b. Select the option: <strong>Open Network Stream..</strong></p>
                                <img
                                    src={process.env.PUBLIC_URL + '/stream_info/vlc2.png'}
                                    alt="VLC-step-b"
                                />
                            </div>
                            <div style={{ marginTop: "30px" }}>
                                <p> c. Paste the copied RTSP-URL</p>
                                <img
                                    src={process.env.PUBLIC_URL + '/stream_info/vlc3.png'}
                                    alt="VLC-step-c"
                                />
                            </div>
                            <div style={{ marginTop: "30px" }}>
                                <p> d. Click at <strong>Play</strong> and the streaming will start</p>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        )
    }
}
const stepsStyle = {
    marginTop: "2%",
    marginLeft: "2%",
}
const streamStyle = {
    background: "#aaa",
    padding: "5px",
    borderRadius: "20px"
}



export default CameraStreamingDetails
