import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import auth from '../../helpers/auth'
import axios from 'axios'
import '../../../LoginAnimation.css';

/**
 * Login component
 */
class Login extends Component {

    _isMounted = false

    /**
     * Hides the nav-bar on mount of the component
     */
    componentDidMount() {
        document.title = "BMS App- Login"
        this._isMounted = true
        document.getElementById('navigation-bar').style.display = "none";
        var orig = document.body.className;
        document.body.classList.add('login-page');
    }

    /**
     * Restores the nav-bar on the unmount
     */
    componentWillUnmount() {
        this._isMounted = false
        document.getElementById('navigation-bar').style.display = "flex";
        document.body.classList.remove('login-page');
    }

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            remember: true,
        }
    }

    /**
     * Makes the login request to the backend and handles the response prperly
     * On Successful response: stores the credentials on the auth object and redirects the user in a proper pager
     * Otherwise: error message is displayed
     * @param {object} e triggered event
     */
    onSubmit(e) {
        e.preventDefault();

        const { email, password, remember } = this.state;
        console.log("requsting: " + process.env.REACT_APP_API_URL + 'login');
        axios.post(process.env.REACT_APP_API_URL + 'login', {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            "email": email,
            "password": password
        }).then(response => {
            auth.login(response, remember, () => {
                const { from } = this.props.location.state || { from: { pathname: '/dashboard' } }
                this.props.history.push(from)
            })
            if (this._isMounted) {
                this.setState({ err: false });
            }
            this.props.updateAuth(true);

        }).catch(error => {
            console.log(error);
            this.refs.email.value = "";
            this.refs.password.value = "";
            if (this._isMounted) {
                this.setState({ err: true });
                setTimeout(() => this.setState({
                    err: undefined
                }), 3000);
            }

        });

    }

    /**
     * updates the typeed text in the state
     * @param {object} e triggered event
     */
    onChange(e) {
        const { name, value, type, checked } = e.target;
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    /**
     * shows the component
     */
    render() {
        const { from } = this.props.location.state || { from: { pathname: '/dashboard' } }
        if (auth.isAuthenticated())
            return (
                <Redirect to={from} />
            )

        let error = this.state.err;
        let msg = (!error) ? 'Login Successful' : 'Wrong Credentials';
        let name = (!error) ? 'alert alert-success' : 'alert alert-danger';
        return (
            <div className="limiter">
                <div className="wrapper-login">
                    <div className="logo"></div>
                    <h1>Monitoring, Reporting &amp; Administration Platform</h1>
                    <div className="container-login">
                        <div className="container-login100" >
                            <div className="wrap-login100">
                                <div >
                                    {error !== undefined && <div className={name} role="alert">{msg}</div>}
                                </div>
                                <form className="login100-form validate-form" method="POST" onSubmit={this.onSubmit.bind(this)}>
                                    <span className="login100-form-title p-b-34 p-t-27">
                                        Log in
                            </span>

                                    <div className="wrap-input100 validate-input" data-validate="Enter email">
                                        <input
                                            id="email"
                                            type="email"
                                            ref="email"
                                            className="input100"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onChange.bind(this)}
                                            required
                                            placeholder="Email"
                                        />
                                        <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                    </div>

                                    <div className="wrap-input100 validate-input" data-validate="Enter password">
                                        <input
                                            id="password"
                                            type="password"
                                            ref="password"
                                            className="input100"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.onChange.bind(this)}
                                            required
                                            placeholder="Password"
                                        />
                                        <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                    </div>

                                    <div className="contact100-form-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={this.state.remember}
                                            className="input-checkbox100"
                                            id="ckb1"
                                            name="remember"
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <label className="label-checkbox100" htmlFor="ckb1">
                                            Remember me
                                </label>
                                    </div>

                                    <div className="container-login100-form-btn">
                                        <button type="submit" className="login100-form-btn" style={{ width: "312px" }}>
                                            Login
                                </button>
                                    </div>

                                    <div className="text-center p-t-90">
                                        <Link to="forgotpassword" className="txt1">
                                            Forgot Password?
                                </Link>
                                    </div>
                                    <div className="text-center p-t-90">
                                        <a className="txt1" href="https://digisec.gr/">
                                            For more info visit our website digisec.gr
                                </a>
                                    </div>

                                </form>

                            </div>

                        </div>
                        <div className="bird-container bird-container--one lock">
                            <div className="bird bird--one "></div>
                        </div>
                        <div className="bird-container bird-container--two lock">
                            <div className="bird bird--two "></div>
                        </div>
                        <div className="bird-container bird-container--three lock">
                            <div className="bird bird--three "></div>
                        </div>
                        <div className="bird-container bird-container--four lock">
                            <div className="bird bird--four "></div>
                        </div>
                    </div>
                    <h2>Bird Monitoring<br />
        System</h2>

                    <div className="bottom-login">

                        {/*<!– wind turbine start ––> */}
                        <div className="wind-turbine">
                            <div className="rotor">
                                <div className="blade blade1"></div>
                                <div className="blade blade2"></div>
                                <div className="blade blade3"></div>
                            </div>
                            <div className="pulse"></div>
                            <div className="pole"></div>
                        </div>
                        {/*<! –– wind turbine ends ––>*/}

                    </div>

                </div>
            </div>

        );
    }
}

export default Login;

