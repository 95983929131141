import React, { Component } from 'react'
import axios from 'axios'
import qs from 'qs';

import Modal from 'react-modal'
import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import Spinner from '../Spinner'
import ColoredCircle from '../ColoredCircle'
import VolumeRange from '../VolumeRange'
import CameraCard from '../CameraCard'
import EventsTableList from "../EventsTableList"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faExclamationTriangle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import InfoTable from './InfoTable';
import WaterSpraySystem from '../WaterSpraySystem';

export class GeneratorDetails extends Component {
    /**
     * Generator details page
     */
    state = {
        allow: auth.role_id < 2,
        generator: {},
        generatorReachability: 0,
        spinner: true,
        totalEvents: [],
        tableSpinner: false,
        pagingControl: {
            pageSize: 10,
            currentPage: 0,
            lastPage: 0
        },
        hitSH: false,
        reqSH: false,
        logSH: { vis: false, status: "", msg: "" },

        hitRB: false,
        reqRB: false,
        logRB: { vis: false, status: "", msg: "" },

        hitCS: false,
        reqCS: false,
        logCS: { vis: false, status: "", msg: "" },

        showModal: false
    }
    /**
     * Handles the change in events page.
     * @param {int} pageId 
     */
    changePage = (pageId) => {
        console.log("Changing page")
    }
    /**
     * Initializes the component variables by fetching the generator details
     * and processing properly
     */
    componentDidMount() {
        smoothToTop();
        let params = {}

        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()
        yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate()
        today = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()

        params["from"] = yesterday
        params["to"] = today

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        };
        let gid = this.props.match.params.id
        axios.get(process.env.REACT_APP_API_URL + 'generators/' + gid, config)
            .then(response => {
                let targetGenerator = response.data
                targetGenerator.horns.map(horn => {
                    horn.volume = horn.volume
                    horn['activity'] = 0
                    horn['current_volume'] = Math.round((horn.volume - (-64)) / 64 * 100)
                    horn['volume_control'] = false
                    horn['onSubmiting'] = false
                    horn['log'] = { vis: false, status: "", msg: "" }
                })
                this.setState({
                    generator: targetGenerator,
                    spinner: false
                })
                document.title = "BMS App - Generator " + targetGenerator.name
                this.ping(targetGenerator.id)
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false
                })
            });
    }
    /**
     * Handles the warning modal before the user submit a danger action
     * @param {String} action 
     */
    handleOpenModal = (action) => {
        const { reqCS, reqSH, reqRB } = this.state
        if (action === "shutdown") {
            if (reqCS || reqRB) {
                console.log("Other process in Queue")
                return
            }
            this.setState({
                hitSH: true,
                showModal: true
            })
        }
        else if (action === "reboot") {
            if (reqCS || reqSH) {
                console.log("Other process in Queue")
                return
            }
            this.setState({
                hitRB: true,
                showModal: true
            })
        }
        if (action === "systemstatus") {
            if (reqSH || reqRB) {
                console.log("Other process in Queue")
                return
            }
            this.setState({
                hitCS: true,
                showModal: true
            })
        }
    }
    /**
     * Closes the modal
     */
    handleCloseModal = () => {
        this.setState({
            showModal: false,
            hitRB: false,
            hitSH: false,
            hitCS: false
        });
    }
    /**
     * Handles the Yes option of the modal
     */
    handleYes = () => {
        this.setState({ showModal: false });
        const { hitRB, hitSH, hitCS } = this.state
        if (hitRB) {
            this.requestReboot()
        }
        else if (hitSH) {
            this.requestShutDown()
        }
        else if (hitCS) {
            this.changeCurrentStatus()
        }
    }

    /**
     * Asks the backedn to ping the generator with id gid
     * and then to respong with his status
     * @param {int} gid 
     */
    ping = (gid) => {
        console.log("pinging generator: " + gid)
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.get(process.env.REACT_APP_API_URL + 'generators/' + gid + "/ping", config)
            .then(response => {
                let pingResults = response.data
                console.log("ping results: ", pingResults) //<----check that!!!
                this.setState({
                    generatorReachability: 1
                })

            })
            .catch(error => {
                console.log("got error on ping")
                console.log(error)
                this.setState({
                    generatorReachability: 2
                })
            });
    }
    /**
     * On Demand ping
     */
    rePing = () => {
        const { generator } = this.state
        this.setState({
            generatorReachability: 0
        })
        this.ping(generator.id)
    }
    /**
     * Enables the volume control component for the horn with id hid
     * @param {int} hid 
     */
    enableVolumeControl = (hid) => {
        let tGenerator = this.state.generator
        tGenerator.horns.filter(horn => horn.id === hid)[0]['volume_control'] = true
        this.setState({
            generator: tGenerator
        })
    }
    /**
     * Disables the volume control component for the horn with id hid
     * @param {int} hid 
     */
    disableVolumeControl = (hid) => {
        let tGenerator = this.state.generator
        let targetHorn = tGenerator.horns.filter(horn => horn.id === hid)[0]
        targetHorn['volume_control'] = false
        targetHorn['current_volume'] = Math.round((targetHorn['volume'] - (-64)) / 64 * 100)
        this.setState({
            generator: tGenerator
        })
    }
    /**
     * Changes to the new volume that the user set
     * @param {int} nvolume normilised volume
     * @param {int} hid 
     */
    changingVolume = (nvolume, hid) => {
        let tGenerator = this.state.generator
        tGenerator.horns.filter(horn => horn.id === hid)[0]['current_volume'] = nvolume
        this.setState({
            generator: tGenerator
        })
    }
    /**
     * Sets the volume that the user set to the target horn with hid.
     * It is set by requesting the back end to set it on the generator. 
     * @param {int} hid 
     */
    setNewVolume = (hid) => {
        console.log("seting new volume on hid: ", hid)
        let tGenerator = this.state.generator
        let targetHorn = tGenerator.horns.filter(horn => horn.id === hid)[0] //einai byReference opote oi allages pairnane
        targetHorn['volume_control'] = false
        targetHorn['onSubmiting'] = true
        this.setState({
            generator: tGenerator
        })
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const newRealVolume = Math.round(((targetHorn['current_volume'] / 100) * 64) - 64)
        const data = {
            'volume': newRealVolume
        }
        axios.post(process.env.REACT_APP_API_URL + 'horns/' + hid + "/volume", data, config)
            .then(response => {
                const playResponse = response.data
                let tGenerator = this.state.generator
                let targetHorn = tGenerator.horns.filter(horn => horn.id === hid)[0]
                targetHorn['onSubmiting'] = false
                targetHorn['volume'] = newRealVolume
                targetHorn['log'] = { vis: true, status: "success", msg: "Done" }
                this.setState({
                    generator: tGenerator
                })
                setTimeout(() => {
                    let tGenerator = this.state.generator
                    tGenerator.horns.filter(horn => horn.id === hid)[0]['log'] = { vis: false, status: "", msg: "" }
                    this.setState({
                        generator: tGenerator
                    })
                }, 2000)
            })
            .catch(error => {
                console.log("got error on set new volume")
                console.log(error)
                let tGenerator = this.state.generator
                let targetHorn = tGenerator.horns.filter(horn => horn.id === hid)[0]
                targetHorn['current_volume'] = Math.round((targetHorn['volume'] - (-64)) / 64 * 100)
                targetHorn['onSubmiting'] = false
                targetHorn['log'] = { vis: true, status: "error", msg: "Failure" }
                this.setState({
                    generator: tGenerator
                })
                setTimeout(() => {
                    let tGenerator = this.state.generator
                    tGenerator.horns.filter(horn => horn.id === hid)[0]['log'] = { vis: false, status: "", msg: "" }
                    this.setState({
                        generator: tGenerator
                    })
                }, 2000)
            });
    }
    /**
     * Make a request to request the horn to start playing
     * @param {int} hid 
     */
    requestToPlayHorn = (hid) => {
        let tGenerator = this.state.generator
        let targetHorn = tGenerator.horns.filter(horn => horn.id === hid)[0]
        targetHorn['activity'] = 1
        this.setState({
            generator: tGenerator
        })
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.get(process.env.REACT_APP_API_URL + 'horns/' + hid + "/play", config)
            .then(response => {
                let playResponse = response.data
                const duration = playResponse['duration'] * 1000
                let tGenerator = this.state.generator
                let targetHorn = tGenerator.horns.filter(horn => horn.id === hid)[0]
                targetHorn['activity'] = 2
                targetHorn['log'] = { vis: true, status: "success", msg: "Done" }

                this.setState({
                    generator: tGenerator
                })
                setTimeout(() => {
                    let tGenerator = this.state.generator
                    tGenerator.horns.filter(horn => horn.id === hid)[0]['activity'] = 0
                    this.setState({
                        generator: tGenerator
                    })
                }, 3000)
                setTimeout(() => {
                    let tGenerator = this.state.generator
                    tGenerator.horns.filter(horn => horn.id === hid)[0]['log'] = { vis: false, status: "", msg: "" }
                    this.setState({
                        generator: tGenerator
                    })
                }, 2000)
            })
            .catch(error => {
                console.log("got error on play")
                console.log(error)
                let tGenerator = this.state.generator
                let targetHorn = tGenerator.horns.filter(horn => horn.id === hid)[0]
                targetHorn['log'] = { vis: true, status: "error", msg: "Failure" }
                targetHorn['activity'] = 0
                this.setState({
                    generator: tGenerator
                })
                setTimeout(() => {
                    let tGenerator = this.state.generator
                    tGenerator.horns.filter(horn => horn.id === hid)[0]['log'] = { vis: false, status: "", msg: "" }
                    this.setState({
                        generator: tGenerator
                    })
                }, 2000)

            });
    }
    /**
     * Requests to the backend to reboot the pc system of the generator
     */
    requestReboot = () => {
        const { generator, reqSH } = this.state
        const gid = generator.id
        console.log("reboot request started gid: " + gid)
        if (reqSH) {
            console.log("shut down already in queue!!")
            return
        }
        console.log("request to: " + process.env.REACT_APP_API_URL + 'generators/' + gid + "/rebootpc")
        this.setState({
            reqRB: true,
        })
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.get(process.env.REACT_APP_API_URL + 'generators/' + gid + "/rebootpc", config)
            .then(response => {
                this.setState({
                    logRB: { vis: true, status: "success", msg: "Done" },
                    reqRB: false,
                    hitRB: false
                })
                setTimeout(() => {
                    this.setState({
                        logRB: { vis: false, status: "", msg: "" },
                    })
                }, 2000)
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    logRB: { vis: true, status: "error", msg: "Failure" },
                    reqRB: false,
                    hitRB: false
                })
                setTimeout(() => {
                    this.setState({
                        logRB: { vis: false, status: "", msg: "" },
                    })
                }, 2000)
            });
    }
    /**
     * Requests the backend to do a ondeman shutdown
     */
    requestShutDown = () => {
        const { generator, reqRB } = this.state
        const gid = generator.id
        console.log("reboot request started gid: " + gid)
        if (reqRB) {
            console.log("reboot already in queue!!")
            return
        }
        console.log("request to: " + process.env.REACT_APP_API_URL + 'generators/' + gid + "/shutdown")
        this.setState({
            reqSH: true
        })
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const url = process.env.REACT_APP_API_URL + 'generators/' + gid + "/shutdown"
        console.log("sh==>", url)
        axios.get(url, config)
            .then(response => {
                this.setState({
                    logSH: { vis: true, status: "success", msg: "Done" },
                    reqSH: false,
                    hitSH: false
                })
                setTimeout(() => {
                    this.setState({
                        logSH: { vis: false, status: "", msg: "" },
                    })
                }, 2000)
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    reqSH: false,
                    hitSH: false,
                    logSH: { vis: true, status: "error", msg: "Failure" },
                })
                setTimeout(() => {
                    this.setState({
                        logSH: { vis: false, status: "", msg: "" },
                    })
                }, 2000)
            });
    }
    /**
     * Requests to the backend to change the current system status
     * of the generator.
     */
    changeCurrentStatus = () => {
        const { id, system_status } = this.state.generator
        const { reqCS } = this.state
        console.log("Change Current Status started gid: " + id)
        if (reqCS) {
            console.log("changing current status already in queue!!")
            return
        }
        this.setState({
            reqCS: true
        })
        const action = system_status === 1 ? "down" : "up"
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const url = process.env.REACT_APP_API_URL + 'generators/' + id + '/status/' + action;
        axios.post(url, {}, config)
            .then(response => {
                let tGenerator = this.state.generator
                tGenerator.system_status = action === "up" ? 1 : 0
                this.setState({
                    generator: tGenerator,
                    logCS: { vis: true, status: "success", msg: "Done" },
                    reqCS: false
                })
                setTimeout(() => {
                    this.setState({
                        logCS: { vis: false, status: "", msg: "" },
                    })
                }, 2000)
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    reqCS: false,
                    logCS: { vis: true, status: "error", msg: "Failure" },
                })
                setTimeout(() => {
                    this.setState({
                        logCS: { vis: false, status: "", msg: "" },
                    })
                }, 2000)
            });
    }

    /**
     * Displays all the view.
     */
    render() {
        Modal.setAppElement("#root")
        const { generator, generatorReachability, spinner, allow, showModal } = this.state
        const { reqSH, logSH, hitSH, reqRB, logRB, hitRB, reqCS, logCS, hitCS } = this.state
        console.log("system status:: === > ", generator.system_status)
        if (spinner)
            return (
                <Spinner />
            )
        let generatorStatus
        if (generatorReachability === 0) //fetching
            generatorStatus =
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
        else if (generatorReachability === 1)//pinged
            generatorStatus =
                <div style={{ display: "inline" }}>
                    <ColoredCircle color="#49E20E" />
                    <button style={{ float: "right" }} onClick={() => this.rePing()}>
                        <FontAwesomeIcon style={{ marginLeft: "10px" }} className="text-light" icon={faSyncAlt} size={"xs"} />
                    </button>
                    <div style={{ clear: "both" }}></div>
                </div>
        else if (generatorReachability === 2)//not pinged
            generatorStatus =
                <div style={{ display: "inline" }}>
                    <ColoredCircle color="#BF0000" />
                    <button style={{ float: "right" }} onClick={() => this.rePing()}>
                        <FontAwesomeIcon style={{ marginLeft: "10px" }} className="text-light" icon={faSyncAlt} size={"xs"} />
                    </button>
                    <div style={{ clear: "both" }}></div>
                </div>

        const allowOnDemand = allow && (generatorReachability === 1)
        // console.log(allowOnDemand, "---", allow, "---", generatorReachability === 1)

        return (
            <div className="container-inner-wrapper">
                < Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    style={modalStyle}
                    shouldReturnFocusAfterClose={false}
                >
                    <div className="text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size={"2x"} style={{ color: "#f5bd1f" }} />  <strong>Warning</strong>
                        <div className="container" style={sureStyle}>Are you sure you want to <strong>{hitRB ? "Reboot the PC on" : hitSH ? "Shut Down" : "change Status of"} {generator.name}</strong></div>
                        <div className="text-center" style={modalButtons}>
                            <a href="#" onClick={this.handleCloseModal} className="btn btn-secondary">Cancel</a>
                            <span style={spanStyle}></span>
                            <a href="#" onClick={this.handleYes} className="btn btn-danger">Yes, I am sure</a>
                        </div>
                    </div>
                </Modal >
                <h1 className="my-title"> Generator <strong>{generator.name}</strong> {generatorStatus}</h1>
                <hr className="my-Hr" />
                <InfoTable generator={generator} />
                <WaterSpraySystem generator={generator} />
                <h2 className="my-title2">Cameras</h2>

                <div className="row">
                    {generator.cameras.map((camera, index) => (
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12" key={index}>
                            <CameraCard camera={camera} generator_id={generator.id} generator_name={generator.name} key={camera.id} />
                        </div>
                    ))}
                </div>

                <h2 className="my-title2">Horns</h2>
                <table className="table events-table">
                    <tbody>
                        <tr style={bgColor} >
                            <th className="d-none d-md-table-cell">Name</th>
                            <th className="d-none d-md-table-cell">Status</th>
                            <th className="d-none d-md-table-cell">Volume</th>
                            <th className="d-none d-md-table-cell">Play</th>
                            <th className="d-none d-md-table-cell">Change Volume</th>
                            <th className="d-none d-md-table-cell" style={{ borderLeft: "solid 1.5px #888" }}>Logs</th>

                        </tr>
                        {generator.horns.map((horn, index) => (
                            <tr key={index}>

                                <td ><span className="d-lg-none font-weight-bold">Name: </span>{horn.name}</td>
                                <td><span className="d-lg-none">Status: </span>{horn.status === 1 ? "Active" : "Inactive"}</td>
                                <td><span className="d-lg-none">Volume: </span>{String(Math.round((horn.volume - (-64)) / 64 * 100)) + "%"}</td>
                                <td>
                                    <span className="d-lg-none">Play: </span>
                                    {horn['activity'] === 0 &&
                                        <button className={!allowOnDemand ? "disabled" : ""} onClick={() => this.requestToPlayHorn(horn.id)}>
                                            <FontAwesomeIcon icon={faBullhorn} />
                                        </button>}
                                    {horn['activity'] === 1 && <div className="spinner-grow" style={{ marginTop: "12px" }} role="status"><span className="sr-only">Loading...</span></div> /*requesting */}
                                    {horn['activity'] === 2 && <div>
                                        <FontAwesomeIcon icon={faBullhorn} />
                                        <span>Playing!</span>
                                    </div>}
                                </td>
                                <td style={{ width: "420px" }}>
                                    <div>
                                        <span className="d-lg-none">Change Volume: </span>
                                        {!horn.volume_control && !horn.onSubmiting && <button className={!allowOnDemand ? "disabled btn btn-link" : "btn btn-link"} onClick={() => this.enableVolumeControl(horn.id)}>Change</button>}
                                        {horn.volume_control && !horn.onSubmiting &&
                                            <VolumeRange id={horn.id} step={1} min={0} max={100} volume={horn.current_volume} changingVolume={this.changingVolume} onCancel={this.disableVolumeControl} onSubmit={this.setNewVolume} />}

                                        {horn.onSubmiting && <div className="spinner-grow" style={{ marginBottom: "-5px", marginLeft: "40px" }} role="status"><span className="sr-only">Loading...</span></div>}
                                    </div>
                                </td>
                                <td style={{ borderLeft: "solid 1.5px #888" }}>
                                    <span className="d-lg-none">Logs: </span> {horn.log.vis && <div style={horn.log.status === "error" ? errorLog : successLog} > {horn.log.msg} </div>}
                                </td>
                                <td className="d-lg-none border-top table-separator-sm" ></td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2 className="my-title2">Control Panel</h2>
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr style={bgColor}>
                                <th>Reboot</th>
                                <th>Shut Down</th>
                                <th>Detection System</th>
                            </tr>
                            <tr>
                                <td>Make a reboot on the Generator's computer on Demand.</td>
                                <td>Shut down the Generator on Demand.</td>
                                <td>Activate or Deactivate the Detection System.
                                    <div style={{ color: "#f5bd1f", display: "inline-flex", marginLeft: "15px" }}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} size={"1x"} className="my-auto " />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button className={(allowOnDemand && !reqRB) ? "btn btn-danger" : "disabled btn btn-danger"} onClick={() => this.handleOpenModal("reboot")}>
                                        Reboot {reqRB && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                    </button>
                                    {logRB.vis && <div style={logRB.status === "error" ? errorLog2 : successLog2} > {logRB.msg}</div>}
                                </td>
                                <td>
                                    <button className={(allowOnDemand && !reqSH) ? "btn btn-danger" : "disabled btn btn-danger"} onClick={() => this.handleOpenModal("shutdown")}>
                                        Shut Down {reqSH && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                    </button>
                                    {logSH.vis && <div style={logSH.status === "error" ? errorLog2 : successLog2} > {logSH.msg} </div>}
                                </td>
                                <td>
                                    <button className={
                                        allowOnDemand ?
                                            generator.system_status === 0 ? "btn btn-primary" : "btn btn-danger" : "disabled btn btn-secondary"  //fix: the first need 'danger'
                                    } onClick={() => this.handleOpenModal("systemstatus")}>
                                        {generator.system_status === 0 ? "Activate" : "Deactivate"} {reqCS && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                    </button>
                                    {logCS.vis && <div style={logCS.status === "error" ? errorLog2 : successLog2} > {logCS.msg} </div>}



                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <EventsTableList content={generator} selectedGenerators={[generator]} selectedFarms={[]} selectedCameras={[]} />

            </div>
        )
    }
}

const bgColor = {
    backgroundColor: "#2A4B6E"
}
const successLog = {
    textAlign: "center",
    backgroundColor: "#c5e1a5",
    borderRadius: "5px",
    padding: "3px 3px",
    margin: "3px"
}
const errorLog = {
    textAlign: "center",
    backgroundColor: "#ce8d8d",
    borderRadius: "5px",
    padding: "3px 3px",
    margin: "3px"
}

const successLog2 = {
    display: "inline",
    textAlign: "center",
    backgroundColor: "#c5e1a5",
    borderRadius: "5px",
    padding: "10px 10px",
    margin: "5px 10px"
}
const errorLog2 = {
    display: "inline",
    textAlign: "center",
    backgroundColor: "#ce8d8d",
    borderRadius: "5px",
    padding: "10px 10px",
    margin: "5px 10px"
}

const modalStyle = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: { zIndex: 20000 }
}


const modalButtons = {
    padding: "4% 0 2%"
}

const spanStyle = {
    margin: "1px"
}

const sureStyle = {
    padding: "15px 100px",
    fontSize: "120%"
}

export default GeneratorDetails




