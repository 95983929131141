import React, { Component } from 'react'
import FarmsPerSite from "../FarmsPerSite";
import { DateRangePicker } from '../DateRangePicker'

import axios from 'axios'
import qs from 'qs';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import Spinner from '../Spinner'


class CamerasGrid extends Component {
    /**
     * Fetches the data and displays a grid with camera cards per
     * company or per site according to the user role
     */

    state = {
        showCalendar: false,
        dateRange: [],
        data: {},
        spinner: true,
        error: false
    }

    showCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }
    onChangeDate = date => this.setState({
        dateRange: date
    }, () => this.fetchOverAllData())

    componentDidMount() {
        smoothToTop();
        document.title = "BMS App - Cameras "
        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()
        this.setState({
            dateRange: [yesterday, today]
        }, () => this.fetchOverAllData())
    }

    fetchOverAllData() {
        //set the correct day
        const { dateRange } = this.state
        let params = {}
        params["from"] = dateRange[0].getFullYear() + "-" + (dateRange[0].getMonth() + 1) + "-" + dateRange[0].getDate()
        params["to"] = dateRange[1].getFullYear() + "-" + (dateRange[1].getMonth() + 1) + "-" + dateRange[1].getDate()
        console.log("camera data for date range: ", params['from'], "--", params['to'])
        this.setState({
            spinner: true
        })
        //set the header
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        };

        const id = this.props.match.params.id;
        const specified = this.props.specified;
        let route;
        if (id) {
            if (specified === "companies")
                route = "fetch_companys_data/" + id
            else if (specified === "sites")
                route = "fetch_site_data/" + id
        }
        else
            route = 'fetch_overall_data'

        axios.get(process.env.REACT_APP_API_URL + route, config)
            .then(response => {
                this.setState({
                    data: response.data,
                    spinner: false,
                    error: false,
                    showCalendar: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false,
                    error: true
                })
            });
    }

    render() {
        const { data, spinner, error, dateRange, showCalendar } = this.state
        const { specified } = this.props
        if (spinner)
            return (
                <Spinner />
            )

        let content = <h2 className="no-data">No Cameras found</h2>
        if (error)
            return (
                <div>
                    <h2 className="my-title"> Cameras </h2>
                    <hr />
                    <div>
                        {content}
                    </div>
                </div>
            );

        if (specified === "companies") {
            content =
                <div>
                    <h3 style={titleStyle}> <span style={spanStyle}>Company: </span> {data.company_name} </h3>
                    {data.sites.length > 0 ?
                        data.sites.map(site =>
                            <FarmsPerSite
                                site={site}
                                item={"cameras"}
                                id={site.id}
                                key={site.id}
                                company_name={data.company_name}
                                changeCurrentPlaceholder={this.props.changeCurrentPlaceholder}
                            />
                        )
                        : <h2 className="no-data">No sites found</h2>
                    }
                </div >
        }
        else if (specified === "sites") {
            content =
                <div>
                    <h3 style={titleStyle}> <span style={spanStyle}>Company: </span> {data.company_name} </h3>
                    <FarmsPerSite
                        site={{ "name": data.name, "id": data.id, "farms": data.farms }}
                        item={"cameras"}
                        company_name={data.company_name}
                        changeCurrentPlaceholder={this.props.changeCurrentPlaceholder}
                    />
                </div >
        }
        else {
            if (data.length) {
                content =
                    <div>
                        {data.map((data, index) =>
                            <div key={index}>
                                <h3 style={titleStyle}>  <span style={spanStyle}>Company: </span>{data.company_name} </h3>
                                {data.sites.length > 0 ?
                                    data.sites.map(site =>
                                        <FarmsPerSite
                                            site={site}
                                            item={"cameras"}
                                            id={site.id} key={site.id}
                                            company_name={data.company_name}
                                            changeCurrentPlaceholder={this.props.changeCurrentPlaceholder}
                                        />
                                    )
                                    : <h2 className="no-data">No sites found</h2>
                                }

                            </div>
                        )}
                    </div >
            }
        }

        return (
            <div className="container-inner-wrapper">
                <div>
                    <h1 className="my-title"> Cameras
                        <button onClick={this.showCalendar}>
                            <FontAwesomeIcon icon={faCalendar} size={"xs"} />
                        </button>
                    </h1>
                    {showCalendar &&
                        <div style={{ marginLeft: "30px" }}>
                            <DateRangePicker onChangeDate={this.onChangeDate} date={dateRange} />
                        </div>
                    }
                </div>
                <hr />
                <div>
                    {content}
                </div>

            </div>
        );
    }
}

const titleStyle = {
    fontWeight: "200",
    fontSize: "2rem",
    color: "#000"
}

const spanStyle = { "color": "#555" }


export default CamerasGrid
