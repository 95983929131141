import React, { Component } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router'

import FormFarmDetails from './RegisterCompany/FormFarmDetails'
import auth from '../helpers/auth'
import { smoothToTop } from '../helpers/misc'
import Spinner from '../Spinner'


export class RegisterFarm extends Component {
    /**
     * A component to create and store new farmsfor a specific site
     */

    state = {
        alert: false,
        msg: '',
        spinner: true,
        farmName: '',
        latitude: '',
        longitude: '',
        siteId: this.props.match.params.id,
        siteName: '',
        companyId: '',
        companyName: '',
        success: false
    }

    componentDidMount() {
        smoothToTop()
        this.fetchFarmCount()
        document.title = "BMS App - Register Farm"
    }

    fetchFarmCount() {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const sid = this.props.match.params.id
        axios.get(process.env.REACT_APP_API_URL + 'sites/' + sid + '/details', config)
            .then(response => {
                this.setState({
                    companyName: response.data.company_name,
                    siteName: response.data.site_name,
                    companyId: response.data.company_id,
                    farmName: "Farm " + (response.data.farms.length + 1) ,
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    error: true,
                    spinner: false

                })
            });
    }

    // Handle field change
    handleChange = input => e => {
        this.setState({ [input]: e.target.value })
    }

    setAlert = (type, msg) => {
        this.setState({
            alert: true,
            alertClass: type,
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => this.setState({
            alert: false,
            alertClass: '',
            alertMsg: '',
        }), 3000)
    }

    registerFarm = () => {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const data = {
            company_id: this.state.companyId,
            site_id: this.state.siteId,
            name: this.state.farmName,
            latitude: this.state.latitude,
            longitude: this.state.longitude
        }
        axios.post(process.env.REACT_APP_API_URL + 'farms', data, config)
            .then(response => {
                setTimeout(() => this.setState({
                    success: true
                }), 1000)
                this.setAlert('alert alert-success', 'Farm\'s fields set')
            })
            .catch(error => {
                console.log("ERROR: got error")
                if( error.response === undefined )
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }

    handleErrorResponse = (error_response) => {
        console.log("ERROR: => ", error_response)
        let header = "Errors on: "
        let details = []

        for(var key in error_response){
            header += ' ' + key + ',';
            error_response[key].forEach( msg => {
                console.log(msg)
                details.push(msg)
            })
        }
        header = header.substring(0, header.length -1)
        let error_header = <p> {header} </p>
        let error_details = <ul> <strong>Details:</strong> {details.map(msg => <li>{msg}</li> )} </ul>
        let error_message = <div>{error_header} {error_details}</div>
        this.setAlert('alert alert-danger', error_message) // text-center
    }

    render() {
        const { companyName, farmName, siteName, latitude, longitude, companyId, spinner, success, siteId } = this.state
        const values = { farmName, latitude, longitude, companyId, siteId }
        if (spinner)
            return (
                <Spinner />
            )
        // if (success)
        //     this.props.history.push("/sites/" + siteId)

        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} role="alert">{this.state.alertMsg}</div> //alert alert-danger text-center

        return (
            <div className="my-form" style={cardStyle}>
                {success && <Redirect to={"/sites/" + siteId}/>}

                <h2 className="text-center"> Farm Registration</h2>
                <h4 className="text-center" style={this.props.secTitleStyle}>
                    {companyName}: {siteName}
                </h4>
                < br />
                <div>{alert}</div>
                <FormFarmDetails
                    handleChange={this.handleChange}
                    setAlert={this.setAlert}
                    register={this.registerFarm}
                    imgStyle={imgStyle}
                    values={values}
                />
            </div>
        )
    }
}

const cardStyle = {
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "5%",
    marginLeft: "20%",
    marginRight: "20%",
}

const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "200px",
    height: "200px",
    maxWidth: "100%",
    minWidth: "200px",
    margin: "0, 25%",
    marginBottom: "1%",
}


export default RegisterFarm
