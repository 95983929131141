import React, { Component } from 'react'
import FormCompanyDetails from './FormCompanyDetails'
import FormUserDetails from './FormUserDetails'
import FormSiteDetails from './FormSiteDetails'
import SuccessView from './SuccessView'
import axios from 'axios'
import auth from '../../helpers/auth'
import { smoothToTop } from '../../helpers/misc'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"



export class CompanyForm extends Component {
    /**
     * Page that starts the registration of a company. This actions contain
     * many steps.
     *
     * Step 1: collects the details of a company
     * Step 2: collects the details of the client admin of the company
     * Step 3: To register the first site, farm and annotations of the site.
     * Step 4: success message
     */
    state = {
        step: 1,
        alert: false,
        msg: '',
        companyName: '',
        companyDescription: '',
        country: '',
        city: '',
        address: '',
        zipCode: '',
        phone: '',
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        speciality: '',
        password: '',
        siteName: 'Site 1',
        latitude: '',
        longitude: '',
        farms: [],
        bCategories: [],
        siteSkiped: false
    }
    componentDidMount() {
        smoothToTop()
        console.log(process.env.REACT_APP_API_URL)
    }

    setPassword = (pswd) => {
        this.setState({ password: pswd })
    }

    // Handle field change
    handleChange = input => e => {
        if(input === "companyDescription" && e.target.value.length > 1000){
             return;  //not update
        }
        this.setState({ [input]: e.target.value })
    }


    registerCompany = () => {
        const { step } = this.state;
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        console.log("request to " + process.env.REACT_APP_API_URL + 'companies')
        const data = {
            name: this.state.companyName,
            description: this.state.companyDescription,
            country: this.state.country,
            city: this.state.city,
            address: this.state.address,
            zip_code: this.state.zipCode,
            phone: this.state.phone,
        }
        axios.post(process.env.REACT_APP_API_URL + 'companies', data, config)
            .then(response => {
                console.log("SUCCESS: got response")
                const company = response.data.company
                this.setState({
                    companyId: company.id,
                    companyToken: company.token,
                    step: step + 1
                });
                this.props.setDropDownSync(true) // to update the dropdown
                this.setAlert('alert alert-success', 'Company\'s fields set')
            })
            .catch(error => {
                console.log("ERROR: got error")
                console.log(error)
                if( error.response === undefined )
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }


    registerCompanyAdmin = () => {
        const { step } = this.state;
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        console.log("request to " + process.env.REACT_APP_API_URL + 'users')
        const data = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            speciality: this.state.speciality,
            company_id: this.state.companyId,
            company_token: this.state.companyToken,
        }
        axios.post(process.env.REACT_APP_API_URL + 'users', data, config)
            .then(response => {
                console.log("SUCCESS: user stored")
                const user_data = response.data
                this.setState({
                    step: step + 1,
                    clientAdminToken: user_data.access_token,
                });
                this.setAlert('alert alert-success', 'Company Admin fields set')
            })
            .catch(error => {
                console.log("ERROR: got error")
                if( error.response === undefined )
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }

    registerSite = () => {
        const { step } = this.state;
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        console.log("request to " + process.env.REACT_APP_API_URL + 'sites')
        const data = {
            company_id: this.state.companyId,
            name: this.state.siteName,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
        }
        axios.post(process.env.REACT_APP_API_URL + 'sites', data, config)
            .then(response => {
                console.log("SUCCESS: site stored")
                const sid = response.data.site.id;
                this.setAlert('alert alert-success', 'Site\'s fields set')
                const { farms } = this.state
                farms.forEach( (farm) => this.registerFarm(farm, sid))
                this.setState({
                    step: step + 1,
                })
            })
            .catch(error => {
                console.log("ERROR: got error")
                if( error.response === undefined )
                    return
                console.log(error.response.data.error)
                this.handleErrorResponse(error.response.data.error)
            });
    }

    registerFarm = (farm, sid) => {
        const farmData = {
            name: farm.name,
            latitude: farm.latitude,
            longitude: farm.longitude,
            site_id: sid,
            company_id: this.state.companyId
        }
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        console.log("request to " + process.env.REACT_APP_API_URL + 'farms')
        axios.post(process.env.REACT_APP_API_URL + 'farms', farmData, config)
            .then(response => {
                console.log("SUCCESS: farm stored")
                this.setAlert('alert alert-success', 'Farm '+ farm.name +' fields set')
            })
            .catch(error => {
                console.log("ERROR: got error")
                if( error.response === undefined )
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }

    registerBirdCategories = (sid) => {
        const categories = this.state.bCategories.map(bCategory => bCategory.category)
        console.log("array cats: ", categories)

        const data = {
            categories: categories
        }
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.post(process.env.REACT_APP_API_URL + 'sites/' + sid + '/annotations-array', data, config)
            .then(response => {
                console.log("SUCCESS: annotations stored")
                this.setAlert('alert alert-success', 'All Bird Categories stored')
            })
            .catch(error => {
                console.log("ERROR: got error")
                if( error.response === undefined )
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }

    syncbCategories = (newbCategories) => {
        this.setState({bCategories: newbCategories})
    }

    skipStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1,
            siteSkiped: true
        })
        console.log("site skiped")
    }

    handleErrorResponse = (error_response) => {
        console.log("ERROR: => ", error_response)
        let header = "Errors on: "
        let details = []

        for(let key in error_response){
            header += ' ' + key + ',';
            error_response[key].forEach( msg => {
                console.log(msg)
                details.push(msg)
            })
        }
        header = header.substring(0, header.length -1)
        let error_header = <p> {header} </p>
        let error_details = <ul> <strong>Details:</strong> {details.map(msg => <li>{msg}</li> )} </ul>
        let error_message = <div>{error_header} {error_details}</div>
        this.setAlert('alert alert-danger', error_message) // text-center
    }

    setAlert = (type, msg) => {
        this.setState({
            alert: true,
            alertClass: type,
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => this.setState({
            alert: false,
            alertClass: '',
            alertMsg: '',
        }), 5000)
    }

    syncFarms = (newfarms) => {
        this.setState({farms: newfarms})
    }



    render() {
        const { step, companyName, companyDescription, country, city, address, zipCode, phone,
            username, firstName, lastName, email, speciality, password, companyId, companyToken, clientAdminToken,
            siteName, longitude, latitude, siteSkiped, farms, bCategories } = this.state

        const values = {
            companyName, companyDescription, country, city, address, zipCode, phone,
            username, firstName, lastName, email, speciality, password, companyId, companyToken, clientAdminToken,
            siteName, longitude, latitude, siteSkiped, farms, bCategories
        }

        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} role="alert">{this.state.alertMsg}</div> //alert alert-danger text-center

        switch (step) {
            case 1:
                return (
                    <div className="my-form" style={cardStyle}>
                        <h1 className="text-center"> Register Company </h1>
                        <h4 className="text-center" style={this.props.secTitleStyle}> Add Company's Details </h4>
                        <p className="text-center"> 1/3 </p>
                        <br />
                        <div>{alert}</div>
                        <FormCompanyDetails
                            registerCompany={this.registerCompany}
                            handleChange={this.handleChange}
                            setAlert={this.setAlert}
                            imgStyle={imgStyle}
                            values={values}
                        />
                    </div>
                )
            case 2:
                return (
                    <div className="my-form" style={cardStyle}>
                        <h2 className="text-center"> Company Registration  </h2>
                        <h4 className="text-center" style={this.props.secTitleStyle}> Add Company's Administrator Details </h4>
                        <p className="text-center"> 2/3 </p>
                        <br />
                        <div>{alert}</div>
                        <FormUserDetails
                            handleChange={this.handleChange}
                            setPassword={this.setPassword}
                            setAlert={this.setAlert}
                            register={this.registerCompanyAdmin}
                            imgStyle={imgStyle}
                            secTitleStyle={secTitleStyle}
                            values={values}
                            step={true}
                        />
                    </div>
                )
            case 3:
                return (
                    <div className="my-form" style={cardStyle}>
                        <h2 className="text-center"> Site Registration</h2>
                        <h4 className="text-center" style={this.props.secTitleStyle}> Add the first Site & farms </h4>
                        <p className="text-center"> 3/3 </p>
                        <p className="text-center">You can skip this step and add sites and farms later</p>

                        <br />
                        <div>{alert}</div>
                        <FormSiteDetails
                            handleChange={this.handleChange}
                            setAlert={this.setAlert}
                            register={this.registerSite}
                            imgStyle={imgStyle}
                            secTitleStyle={secTitleStyle}
                            values={values}
                            skipStep={this.skipStep}
                            fromCompany={true}
                            syncFarms={this.syncFarms}
                            syncbCategories={this.syncbCategories}
                        />
                    </div>
                )
            case 4:
                return (
                    <div className="my-form" style={cardStyle}>
                        <h1 className="text-center"> Complete
                            <FontAwesomeIcon icon={faCheckCircle} style={checkStyle} />
                        </h1>
                        <h4 className="text-center" style={secTitleStyle}> Company successfully registered </h4>
                        <div>{alert}</div>

                        <br />
                        <SuccessView
                            setAlert={this.setAlert}
                            values={values}
                            imgStyle={imgStyle}
                        />
                    </div>

                )


            default:
        }

        return (
            <div>

            </div>
        )
    }
}

const checkStyle = {
    height: "30px",
    color: "#4dd66e"
}

const cardStyle = {
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "5%",
    marginLeft: "20%",
    marginRight: "20%",
}

const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "200px",
    height: "200px",
    maxWidth: "100%",
    minWidth: "200px",
    margin: "0, 25%",
    marginBottom: "1%",
}

const secTitleStyle = {
    color: "#6a6a6a",
}

export default CompanyForm

