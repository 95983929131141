import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Navbar from './components/layout/Navbar';
import Login from './components/pages/auth/Login';
import Register from './components/pages/auth/Register';
import ForgotPassword from './components/pages/auth/ForgotPassword';
import ResetPassword from './components/pages/auth/ResetPassword';
import MainContent from './components/layout/MainContent';
import { ProtectedRoute } from './components/helpers/ProtectedRoute'
import auth from './components/helpers/auth';

/**
 * Main component of all the application
 */
export class App extends Component {
    state = {
        isAuth: false,
        toBoard: false //<--deprecated
    }

    /**
     * updates the authentication and redirects user properly
     * @param {bool} flag 
     * @param {object} redirect 
     */
    updateAuth = (flag, redirect = false) => {
        this.setState({ isAuth: flag, toBoard: redirect })
    }

    componentDidMount() {
        const remember = localStorage.getItem('remember') === 'true';
        if (remember) {
            auth.remind()
            this.updateAuth(true, true)
        }

    }

    render() {
        return (
            <div>
                <Navbar updateAuth={this.updateAuth} />
                <Switch>
                    <Route path="/login" render={props => (<Login {...props} updateAuth={this.updateAuth} />)} />
                    <Route path="/register" component={Register} />
                    <Route path="/forgotpassword" component={ForgotPassword} />
                    <Route path="/resetpassword/token=:token" component={ResetPassword} />
                    <Route exact path="/">
                        <Redirect to="/dashboard" />
                    </Route>
                    <ProtectedRoute path="/" component={MainContent} />

                </Switch>
            </div>
        )
    }
}

export default App



