/**
 * scrolls at the top of the calling page, with a smooth way
 */
export function smoothToTop(){
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(smoothToTop);
        window.scrollTo(0, c - c / 8);
    }
}

/**
 * Generates a soft password with given length.
 * The password it will contain some letter and numbers (or not)
 * @param {int} length of the password
 */
export function getSoftPassword( length=8 ){
    let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

/**
 * Generates a strong password with the given length that will contain
 * letters + numbers + symbos
 * @param {int} len 
 */
export function getStrongPassword( len=10 ){
    var length = (len)?(len):(10);
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*()_+~|}{[];?></-=';
    var password = "";
    var character = "";
    // var crunch = true;
    while( password.length<length ) {
        let entity1 = Math.ceil(string.length * Math.random()*Math.random());
        let entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
        let entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
        let hold = string.charAt( entity1 );
        hold = (password.length%2 === 0)?(hold.toUpperCase()):(hold);
        character += hold;
        character += numeric.charAt( entity2 );
        character += punctuation.charAt( entity3 );
        password = character;
    }
    password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
    return password.substr(0,len);
}