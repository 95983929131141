import { Company } from './models'
import axios from 'axios'

/**
 * Class that handles the authentication system
 * of the application
 */
class Auth {

    constructor() {
        this.authenticated = false;

        this.id = -1;
        this.username = '';
        this.first_name = ''
        this.last_name = ''
        this.speciality = ''
        this.role_id = '';
        this.email = '';
        this.token = '';
        this.email_verfied = '';
        this.companies = [];

    }

    roles_titles = ['Developer', 'Admin', 'Company Admin', 'User', 'Ornithologist']

    /**
     * Implements the storing of user information. If remeberme is true,
     * then those information are stored in the browser cashe
     * @param {object } response
     * @param {bool} remember
     * @param {function} cb what to do after that
     */
    login(response, remember, cb) {
        this.id = parseInt(response.data.user.id)
        this.email = response.data.user.email
        this.username = response.data.user.username
        this.first_name = response.data.user.first_name
        this.last_name = response.data.user.last_name
        this.speciality = response.data.user.speciality
        this.role_id = parseInt(response.data.user.role_id)
        this.token = response.data.access_token
        this.email_verfied = response.data.user.email_verified_at

        response.data.user.companies.map(company => {
            this.companies.push(new Company(company))
        })

        this.authenticated = true;

        localStorage.setItem('remember', remember);
        localStorage.setItem('id', remember ? this.id : '');
        localStorage.setItem('email', remember ? this.email : '');
        localStorage.setItem('username', remember ? this.username : '');
        localStorage.setItem('first_name', remember ? this.first_name : '');
        localStorage.setItem('last_name', remember ? this.last_name : '');
        localStorage.setItem('speciality', remember ? this.speciality : '');
        localStorage.setItem('role_id', remember ? this.role_id : '');
        localStorage.setItem('email_verfied', remember ? this.email_verfied : '');
        localStorage.setItem('token', remember ? this.token : '');

        cb();
    }

    /**
     * If the user information are stored in the cach, here are restored
     * and parsed
     */
    remind() {
        this.id = parseInt(localStorage.getItem('id'));
        this.email = localStorage.getItem('email');
        this.username = localStorage.getItem('username');
        this.first_name = localStorage.getItem('first_name');
        this.last_name = localStorage.getItem('last_name');
        this.speciality = localStorage.getItem('speciality');
        this.role_id = parseInt(localStorage.getItem('role_id'));
        this.email_verfied = localStorage.getItem('email_verfied');
        this.token = localStorage.getItem('token');
        // console.log("getting companies")
        const config = {
            headers: { Authorization: `Bearer ${this.token}` }
        };
        axios.get(process.env.REACT_APP_API_URL + 'companies/', config)
            .then(response => {
                response.data.map(company => {
                    this.companies.push(new Company(company))} )
            })
            .catch(error => {
                console.log("ERROR: got error at auth.gettingcompanies")
                console.log(error)

            });

        this.authenticated = true;
    }

    /**
     * Implements the logout functionality
     */
    logout() {
        this.authenticated = false;

        this.username = '';
        this.first_name = ''
        this.last_name = ''
        this.speciality = ''
        this.role_id = '';
        this.email = '';
        this.token = '';
        this.email_verfied = '';

        localStorage.setItem('remember', 'false');
        localStorage.setItem('id', this.id);
        localStorage.setItem('email', this.email);
        localStorage.setItem('username', this.username);
        localStorage.setItem('first_name', this.first_name);
        localStorage.setItem('last_name', this.last_name);
        localStorage.setItem('speciality', this.speciality);
        localStorage.setItem('role_id', this.role_id);
        localStorage.setItem('email_verfied', this.email_verfied);
        localStorage.setItem('token', this.token);
    }
    /**
     * Checks if the current user is authenticated or not
     */
    isAuthenticated() {
        return this.authenticated
    }

}

export default new Auth();