import React, { Component } from 'react'
import axios from 'axios'

class Register extends Component {
    /**
     * Page for createing a new user.
     */
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
        }
    }
    /**
     * submits the registration form
     * @param {object} e 
     */
    onSubmit(e) {
        e.preventDefault();
        const { name, email, password, password_confirmation } = this.state;
        axios.post(process.env.REACT_APP_API_URL + 'register', {
            headers: {
                'Access-Control-Allow-Origin': '*',

                // 'Content-Type': 'application/json',
                // 'Accept': 'application/json',
                // 'Authorization' : 'Bearer <token_here>'
            },
            name,
            email,
            password,
            password_confirmation
        })
            .then(response => {
                this.setState({ err: false });
                this.props.history.push("home");
            })
            .catch(error => {
                this.refs.name.value = "";
                this.refs.password.value = "";
                this.refs.email.value = "";
                this.refs.confirm.value = "";
                this.setState({ err: true });
                console.log(error)
            });
    }
    /**
     * Syncs the state with the input
     * @param {object} e 
     */
    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        let error = this.state.err;
        let msg = (!error) ? 'Registered Successfully' : 'Oops!  Something went wrong.';
        let name = (!error) ? 'alert alert-success' : 'alert alert-danger';
        return (
            <div className="my-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto">{/*col-md-offset-2*/}
                            <div className="card">
                                <div className="card-header">
                                    <h2 className="card-title">Register</h2>
                                </div>
                                <div className="card-body">

                                    <div className="">{/**col-md-offset-2 col-md-8 col-md-offset-2 */}
                                        {error !== undefined && <div className={name} role="alert">{msg}</div>}
                                    </div>

                                    <form className="form-horizontal"  onSubmit={this.onSubmit.bind(this)}>

                                        <div className="form-group">
                                            <label htmlFor="name" className="col-md-4 control-label">Name</label>
                                            <div className="">
                                                <input
                                                    id="name"
                                                    type="text"
                                                    className="form-control"
                                                    ref="name"
                                                    name="name"
                                                    value={this.state.name}
                                                    onChange={this.onChange.bind(this)}
                                                    required autoFocus
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="email" className="col-md-4 control-label">E-Mail Address</label>
                                            <div className="">
                                                <input
                                                    id="email"
                                                    type="email"
                                                    className="form-control"
                                                    ref="email"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.onChange.bind(this)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="password" className="col-md-4 control-label">Password</label>
                                            <div className="">
                                                <input
                                                    id="password"
                                                    type="password"
                                                    className="form-control"
                                                    ref="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.onChange.bind(this)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="password-confirm" className="col-md-4 control-label">Confirm Password</label>
                                            <div className="">
                                                <input
                                                    id="password-confirm"
                                                    type="password"
                                                    className="form-control"
                                                    ref="confirm"
                                                    name="password_confirmation"
                                                    value={this.state.password_confirmation}
                                                    onChange={this.onChange.bind(this)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-md-6 col-md-offset-4">
                                                <button type="submit" className="btn btn-primary">
                                                    Register
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register