import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import axios from 'axios'


export class SuccessView extends Component {
    /**
     * This component displays a success view and the details of the
     * new company
     */
    state = {
        copied: false,
        validated: false
    }

    copied = () => {
        this.setState({ copied: true })
        setTimeout(() => this.setState({ copied: false }), 3000);
    }

    componentDidMount() {
        const clientAdminToken = this.props.values.clientAdminToken
        //validation request
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + clientAdminToken,
            }
        };
        console.log("request to " + process.env.REACT_APP_API_URL + 'email/resend')
        axios.get(process.env.REACT_APP_API_URL + 'email/resend', config)
            .then(response => {
                console.log("SUCCESS: validation")
                console.log(response)
                this.setState({ validated: true })
                this.props.setAlert('alert alert-success text-center', 'Verification Email sent')
            })
            .catch(error => {
                console.log("ERROR: got error valid")
                console.log(error.response)
                this.setState({ validated: false })
                this.props.setAlert('alert alert-danger text-center', 'Verification Email could not be sent')
            });
    }

    render() {
        const { values } = this.props
        let valed
        if (this.state.validated)
            valed =
                <span style={{ color: "green", fontSize: "12px" }}>
                    Verification Email sent
                </span>
        else
            valed =
                <span style={{ color: "warning", fontSize: "12px" }}>
                    Verification Email is been sent
                </span>

        let copy_info = ''
        if (this.state.copied) {
            copy_info = <span style={{ color: '#6a6a6a', fontSize: '10px' }}> Copied! </span>
        }
        return (
            <div>
                <table className="table">
                    <tbody style={wordBreak}>
                        <tr style={normalBreak}>
                            <th>Company</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{values.companyName}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>{values.companyDescription}</td>
                        </tr>
                        <tr style={normalBreak}>
                            <th>Company Admin</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>
                                {values.email}
                                <br />
                                {valed}
                            </td>
                        </tr>
                        <tr>
                            <td>First Name</td>
                            <td>{values.firstName}</td>
                        </tr>
                        <tr>
                            <td>Last Name</td>
                            <td>{values.lastName}</td>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td>{values.username}</td>
                        </tr>
                        <tr>
                            <td>Password</td>
                            <td>
                                {values.password}
                                <CopyToClipboard text={values.password} onCopy={this.copied}>
                                    <FontAwesomeIcon icon={faCopy} size='lg' id="copy_button" />
                                </CopyToClipboard>
                                <br />
                                {copy_info}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {!values.siteSkiped &&

                <table className="table">
                    <tbody style={wordBreak}>
                        <tr style={normalBreak}>
                            <th>Site</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td> {values.siteName}</td>
                        </tr>
                        <tr>
                            <td>Latitude</td>
                            <td>{values.latitude}</td>
                        </tr>
                        <tr>
                            <td>Longitude</td>
                            <td>{values.longitude}</td>
                        </tr>
                        <tr>
                            <td>Farms</td>
                            <td>{'Added ' + values.farms.length + ' Farm(s)'}</td>
                        </tr>

                    </tbody>
                </table>}


                <div className="text-center">

                    <Link className="btn btn-primary" style={{ margin: "10px 10px" }} to='/dashboard'>
                        Dashboard </Link>
                    <Link className="btn btn-primary" style={{ margin: "10px 10px" }} to={'/company_profile/' + values.companyId}>
                        Company Profile </Link>
                    <button className="btn btn-primary" style={{ margin: "10px 10px" }} onClick={() => this.props.setAlert('alert alert-warning text-center', 'Sorry, this action in not implemented yet')} >
                        <span className="d-none d-lg-inline">Download PDF</span>
                        <FontAwesomeIcon icon={faFilePdf} style={{ marginLeft: "5px" }} />
                    </button>
                </div>

            </div>
        )
    }
}

const normalBreak = {
    wordBreak: "normal",
    backgroundColor: "#fff"
}

const wordBreak = {
    wordBreak: "break-word"
}

export default SuccessView
