import React, { Component } from 'react'
import axios from 'axios'

import auth from '../../helpers/auth'
import Warning from './Warning'
import GetConfig from './GetConfig'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import GetSetModel from './GetSetModel'
import GetSetImageKey from './GetSetImageKey'
import GetSetVisionCoefs from './GetSetVisionCoefs'
import GetSetItem from './GetSetItem'
import GetSetItemKey from './GetSetItemKey'




export class ConfigControl extends Component {
    state = {
        generators: [],
        alert: {
            msg: "",
            status: "",
            visible: false
        },
        fetching: true,
    }

    componentDidMount() {
        document.title = "BMS App - Config Control"

        if (auth.role_id !== 0)
            return
        console.log("fetching the generators")
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const url = process.env.REACT_APP_API_URL + 'generators/all/info'
        axios.get(url, config)
            .then(response => {

                this.setState({
                    generators: response.data,
                    fetching: false
                })
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)

                this.setState({
                    generators: 0,
                    fetching: false
                })

            });
    }
    setAlert = (status, message) => {
        this.setState({
            alert: {
                visible: true,
                status: status,
                msg: message
            }
        }, () => setTimeout(() => {
            this.setState({
                alert: {
                    visible: false,
                    status: "",
                    msg: ""
                }
            })
        }, 5000)
        )
    }

    render() {
        const { alert, generators, fetching } = this.state
        const header =
            <div>
                <h1 className="my-title"> Config Control </h1>
                <hr className="my-Hr" />
            </div>
        let content
        if (auth.role_id !== 0)
            return (
                <div>
                    {header}
                    <p> Sorry, only developers are allowed </p>
                </div>
            )

        const generators_info = fetching ? <span className="spinner-border spinner-border-sm" role="status" style={{ margin: "0px 5px" }} /> : <span style={{ margin: "0px 5px", color: "#00f" }}><strong>{generators.length}</strong></span>

        return (
            <div className="container-inner-wrapper">
                {header}
                {alert.visible && <div className={"alert alert-" + alert.status} >
                    <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} style={{ marginRight: "10px" }} />
                    {alert.msg}
                </div>}
                <p>This page is for checking the configurations of one or multiple Generators, or sending new ones. <strong>Sending new configurations to a Generator, will modify its functionality</strong>. </p>
                <ul>
                    <li>
                        Found {generators_info} Generators
                    </li>
                </ul>
                <Warning />
                <GetConfig generators={generators} />
                <GetSetItem generators={generators} />
                <GetSetItemKey generators={generators} />
                <GetSetModel generators={generators} />
                <GetSetImageKey generators={generators} />
                <GetSetVisionCoefs generators={generators} />





            </div>

        )
    }
}

const alertStyel = { margin: "0 20%", padding: "25px auto" }

export default ConfigControl
