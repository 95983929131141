import React, { Component } from 'react'

export class InfoTable extends Component {
    /**
     * displays table with information about the generator
     */
    render() {
        const generator = this.props.generator
        return (
            <div style={{ "marginTop": "10px", "width": "100%" }}>
                <h2 className="my-title2">Details</h2>
                <table className="table events-table">
                    <tbody style={wordBreak}>
                        <tr style={normalBreak}>
                            <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Company</th>
                            <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Site</th>
                            <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Farm</th>
                            <th className="d-none d-md-table-cell" style={{ textAlign: "center", color: "#E4BA06" }}>Generator ID</th>
                        </tr>
                        <tr>
                            <td className="text-lg-center text-left">
                                <span className="d-lg-none font-weight-bold">
                                    Company:&nbsp;
                                </span>
                                {generator.company_name}</td>
                            <td className="text-lg-center text-left">
                                <span className="d-lg-none font-weight-bold">
                                    Site:&nbsp;
                                </span>
                                {generator.site_name}</td>
                            <td className="text-lg-center text-left">
                                <span className="d-lg-none font-weight-bold">
                                    Farm:&nbsp;
                                </span>
                                {generator.farm_name}</td>
                            <td className="text-lg-center text-left">
                                <span className="d-lg-none font-weight-bold">
                                    Generator ID:&nbsp;
                                </span>
                                {generator.id}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
const normalBreak = {
    wordBreak: "normal",
    backgroundColor: "#2A4B6E",
    color: "#E4BA06",
    borderColor: "#2A4B6E",
}

const wordBreak = {
    wordBreak: "break-word"
}

export default InfoTable