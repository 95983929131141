import React, { Component } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import { ProgressBar } from 'react-bootstrap'
import auth from '../../helpers/auth'
import axios from 'axios'




export class UploadSetup extends Component {
    state ={
        uploadPercentage: 0,
    }
    setUploadFile = ({target: { files } }) => {
        this.setState({
            file: files[0]
        })
    }

    uploadFile = () => {
        const { file } = this.state
        let data = new FormData();
        data.append('file', file)
        
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor((loaded * 100) / total)
                console.log( loaded + "Kb of " + total + "Kb | " + percent + "%")
                if(percent < 100 ){
                    this.setState({
                        uploadPercentage: percent
                    })
                }
            }
        }
        axios.post(process.env.REACT_APP_API_URL + 'post_setup', data, config)
        .then(res => {
            console.log(res)
            this.setState({
                uploadPercentage: 100
            }, () => {
                this.setState({
                    uploadPercentage: 0,
                    modelDescription: "",
                    file: {}
                })
                this.props.setAlert("success", "Upload Completed")

            })
        }).catch(error => {
            console.log("ERROR: got error")
            console.log(error)
            console.log("===>", error.response.data.error)
            this.props.setAlert("danger", "Got Error on Upload. Info: Read console")
        });
    }
    submitModel = () => {
        this.uploadFile()
    }
    
    render() {
        const { uploadPercentage } = this.state;
        return (
            <div className="text-center">
                <h2 style={{fontWeight: "300", fontSize: "1.7rem"}}><FontAwesomeIcon icon={faCloudUploadAlt} size={"xs"} style={{marginRight: "10px"}}/>Setup</h2>

                <div style={{margin: "20px 0"}}>
                    <input type="file" style={fileUploaderStyle} className="text-center" onChange={this.setUploadFile}/>
                </div>


                <button className="btn btn-primary" onClick={this.submitModel}> Submit</button>

                {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={uploadPercentage + "%"} />}
            </div>
        )
    }
}
const fileUploaderStyle = {
    border: "solid 1px #999",
    borderRadius: "5px",
    padding:"20px 20px",
    margin: "20px 0"
}


export default UploadSetup
