import React, { Component } from 'react'
import axios from 'axios'
import auth from '../helpers/auth'
import { Company } from '../helpers/models'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export class CompanyProfile extends Component {
    /**
     * A component that contains in details information about a company.
     *
     * For admin, it contails all the companies
     */

    state = {
        fetched: false
    }

    render() {
        let companies_view = ''
        if (!this.state.fetched) {
            const config = {
                headers: { Authorization: `Bearer ${auth.token}` }
            };
            let fetchUrl, all;
            if (this.props.match.params.id === undefined) {
                all = true;
                fetchUrl = process.env.REACT_APP_API_URL + 'companies'
            }
            else {
                all = false
                fetchUrl = process.env.REACT_APP_API_URL + 'companies/' + this.props.match.params.id
            }
            axios.get(fetchUrl, config)
                .then(response => {
                    let companies = [];
                    if (all)
                        response.data.map(company => {
                            companies.push(new Company(company))
                        })
                    else {
                        companies.push(new Company(response.data))
                    }
                    console.log('len' + companies.length)
                    this.setState({
                        fetched: true,
                        companies: companies
                    })
                })
                .catch(error => {
                    console.log("ERROR: got error")
                    console.log(error)

                });
        }

        else {
            this.state.companies.map(company => console.log("COMP name: " + company.name))
            companies_view =
                <div style={cardStyle}>
                    {
                        this.state.companies.map((company) => (
                            <CompanyInstance company={company} key={company.id} />
                        ))
                    }
                </div>
        }
        return (
            <div className="container" style={{ marginTop: "5%" }}>
                {companies_view}
            </div>
        )
    }
}

class CompanyInstance extends Component {

    render() {
        const { company } = this.props

        let add_user = ''
        if (auth.role_id <= 2)
            add_user =
                <div>
                    <Link to={"/registeruser/" + company.id} className="btn btn-primary" style={{ margin: "10px 5px" }}>
                        <FontAwesomeIcon icon={faPlus} style={{ margin: "auto" }} /> <span className="d-none d-lg-inline">Register User</span>
                    </Link>
                    <Link to={"/notexist"} className="btn btn-danger disabled" style={{ margin: "10px 5px" }}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ margin: "auto" }} /> <span className="d-none d-lg-inline">Delete</span>
                    </Link>
                </div>
        return (
            <div className="container-inner-wrapper">
                <div className="row">
                    <img
                        src={process.env.PUBLIC_URL + '/default_company_logo.png'}
                        alt="default company logo"
                        style={imgStyle}
                        className="w-200"
                    />
                    <div className="col" style={titleStyle}>
                        <div className="float-right text-center">
                            <h1><Link to={'/companies/' + company.id} className="black-link"> {company.name} </Link></h1>
                            {add_user}
                        </div>
                    </div>
                    <div className="clearfix" /><br />
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>
                                    Description
                        </th>
                                <td>
                                    {company.description}
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    Coutry
                        </th>
                                <td>
                                    {company.country}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    City
                        </th>
                                <td>
                                    {company.city}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Address
                        </th>
                                <td>
                                    {company.address}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Zip Code
                        </th>
                                <td>
                                    {company.zip_code}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Phone
                        </th>
                                <td>
                                    {company.phone}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Admin
                        </th>
                                <td>
                                    {company.admins.length ? company.admins[0].first_name + " " + company.admins[0].last_name : ""}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}


const titleStyle = {
    wordWrap: "break-word",
}

const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "200px",
    height: "200px",
    maxWidth: "100%",
    minWidth: "200px",
    margin: "0, 25%",
    marginBottom: "1%",
}

const cardStyle = {
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "5%",
    marginLeft: "20%",
    marginRight: "20%",
}

export default CompanyProfile
