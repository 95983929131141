import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export class FormCompanyDetails extends Component {
    /**
     * Form that collects from the user the company details.
     * 
     * In order to pass this step all the filds should be filled
     */

    state = {

    }

    validate() {
        const { values } = this.props
        console.log("company details validate")
        if (
            values.companyName.length > 0 &&
            values.companyDescription.length > 0 &&
            values.country.length > 0 &&
            values.city.length > 0 &&
            values.address.length > 0 &&
            values.zipCode.length > 0 &&
            values.phone.length > 0
        ) {
            return true
        }
        else {
            // console.log("name " + values.companyName.length)
            // console.log("desc " + values.companyDescription.length)
            // console.log("coutry " + values.country.length)
            // console.log("city " + values.city.length)
            // console.log("address " + values.address.length)
            // console.log("zip " + values.zipCode.length)
            // console.log("phone " + values.phone.length)
            return false // <--
        }
    }

    continue = e => {
        e.preventDefault();
        this.validate() ? this.props.registerCompany() : this.props.setAlert('alert alert-danger text-center', 'All the fields should be filled')
    }


    render() {
        const { values, handleChange } = this.props
        const currentDescriptionSize = values.companyDescription.length
        return (
            <div className="form-horizontal">

                <div className="form-group">
                    <label htmlFor="companyName" className="control-label">Company Name</label>
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            ref="companyName"
                            name="companyName"
                            value={values.companyName}
                            onChange={handleChange('companyName')}
                            required autoFocus
                        />
                    </div>
                </div>


                {/* <TextField
                        hintText="Enter the name"
                        floatingLabelText="Company's Name"
                        onChange={handleChange('companyName')}
                        defaultValue={values.companyName}
                        style={{ width: "85%" }}
                    /> */}

                <div className="row">
                    <div className="col">
                        {/* <TextField
                                hintText="Enter the Country"
                                floatingLabelText="Country"
                                onChange={handleChange('country')}
                                defaultValue={values.country}

                            /> */}
                        <div className="form-group">
                            <label htmlFor="country" className="control-label">Country</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="country"
                                    name="country"
                                    value={values.country}
                                    onChange={handleChange('country')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">

                        {/* <TextField
                                hintText="Enter the City"
                                floatingLabelText="City"
                                onChange={handleChange('city')}
                                defaultValue={values.city}
                            /> */}

                        <div className="form-group">
                            <label htmlFor="city" className="control-label">City</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="city"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange('city')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        {/* <TextField
                                hintText="Enter the Address"
                                floatingLabelText="Address"
                                onChange={handleChange('address')}
                                defaultValue={values.address}

                            /> */}

                        <div className="form-group">
                            <label htmlFor="address" className="control-label">Address</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="address"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange('address')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {/* <TextField
                                hintText="Enter the zip code"
                                floatingLabelText="Zip Code"
                                onChange={handleChange('zipCode')}
                                defaultValue={values.zipCode}
                            /> */}
                        <div className="form-group">
                            <label htmlFor="zipCode" className="control-label">Zip Code</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="zipCode"
                                    name="zipCode"
                                    value={values.zipCode}
                                    onChange={handleChange('zipCode')}
                                    inputMode="numeric"
                                    // pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {/* <TextField
                        hintText="Enter a phone"
                        floatingLabelText="Company's Phone"
                        onChange={handleChange('phone')}
                        defaultValue={values.phone}
                        type="tel"

                    /> */}
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="phone" className="control-label">Phone Number</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="phone"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange('phone')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <TextField
                        hintText="Enter a small description about the company"
                        floatingLabelText="Company Description"
                        onChange={handleChange('companyDescription')}
                        defaultValue={values.companyDescription}
                        style={textStyle}
                        multiLine
                    /> */}

                <div className="form-group">
                    <label htmlFor="companyDescription" className="control-label">Company Description</label>
                    <div>
                        <textarea
                            type="text"
                            className="form-control"
                            ref="companyDescription"
                            name="companyDescription"
                            value={values.companyDescription}
                            onChange={handleChange('companyDescription')}
                            style={{maxHeight: "300px"}}
                            rows="3"
                            required autoFocus
                        />
                        <label style={{float: "right"}}> {currentDescriptionSize} /1000</label>
                    </div>
                </div>

                <br />
                <br />


                <button className="btn btn-primary float-right" onClick={this.continue} >
                    <span className="d-none d-lg-inline">Continue  </span>
                    <FontAwesomeIcon icon={faAngleDoubleRight} style={{ marginLeft: "5px" }} />
                </button>
                <div className="clearfix" /><br />

                <div className="text-center">
                    <img
                        src={process.env.PUBLIC_URL + '/default_company_logo.png'}
                        alt="default company logo"
                        style={this.props.imgStyle}
                    />
                </div>

            </div>
        )
    }
}

export default FormCompanyDetails
