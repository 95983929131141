import React, {Component} from 'react'
import axios from 'axios'

import FormSiteDetails from './RegisterCompany/FormSiteDetails'
import auth from '../helpers/auth'
import {smoothToTop} from '../helpers/misc'
import Spinner from '../Spinner'
import {Redirect} from 'react-router'
import {Multiselect} from "multiselect-react-dropdown";


export class RegisterSite extends Component {
    /**
     * A component to create and store a new site for specific company
     */

    multiselectRefMembers = React.createRef();

    state = {
        alert: false,
        msg: '',
        spinner: true,
        siteName: '',
        latitude: '',
        longitude: '',
        companyId: this.props.match.params.id,
        companyName: '',
        farms: [],
        bCategories: [],
        companyMembers: [],
        selectedMembers: [],
        success: false,
    }

    componentDidMount() {
        smoothToTop()
        document.title = "BMS App - Register Site"
        this.fetchSiteCount()
    }

    fetchSiteCount() {
        const config = {
            headers: {Authorization: `Bearer ${auth.token}`}
        };
        const cid = this.props.match.params.id
        const url = process.env.REACT_APP_API_URL + 'companies/' + cid + "/details"
        axios.get(url, config)
            .then(response => {
                console.log(response.data)
                this.setState({
                    companyName: response.data.name,
                    siteName: "Site " + (response.data.sites.length + 1),
                    companyMembers: response.data.members,
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    error: true,
                    spinner: false

                })
            });
    }

    // Handle field change
    handleChange = input => e => {
        this.setState({[input]: e.target.value})
    }

    setAlert = (type, msg) => {
        this.setState({
            alert: true,
            alertClass: type,
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => this.setState({
            alert: false,
            alertClass: '',
            alertMsg: '',
        }), 3000)
    }

    registerSite = () => {
        const config = {
            headers: {Authorization: `Bearer ${auth.token}`}
        };
        const data = {
            company_id: this.state.companyId,
            name: this.state.siteName,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
        }
        axios.post(process.env.REACT_APP_API_URL + 'sites', data, config)
            .then(response => {
                console.log("SUCCESS: site stored")
                const sid = response.data.site.id;
                const {farms} = this.state
                farms.forEach((farm) => this.registerFarm(farm, sid))
                this.registerBirdCategories(sid)
                if(this.state.selectedMembers.length > 0)
                    this.registerMembersMassively(sid);
                this.setAlert('alert alert-success', 'Site\'s fields set')
                this.props.setDropDownSync(true) // to update the dropdown
                setTimeout(() => this.setState({
                    success: true, newSiteId: sid
                }), 1000)
            })
            .catch(error => {
                console.log("ERROR: got error")
                if (error.response === undefined)
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }

    registerFarm = (farm, sid) => {
        const farmData = {
            name: farm.name,
            latitude: farm.latitude,
            longitude: farm.longitude,
            site_id: sid,
            company_id: this.state.companyId
        }
        const config = {
            headers: {Authorization: `Bearer ${auth.token}`}
        };
        console.log("request to " + process.env.REACT_APP_API_URL + 'farms')
        axios.post(process.env.REACT_APP_API_URL + 'farms', farmData, config)
            .then(response => {
                console.log("SUCCESS: farm stored")
                this.setAlert('alert alert-success', 'Farm ' + farm.name + ' fields set')
            })
            .catch(error => {
                console.log("ERROR: got error")
                if (error.response === undefined)
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }

    registerBirdCategories = (sid) => {
        const categories = this.state.bCategories.map(bCategory => bCategory.category)
        if (categories.length === 0)
            return ;
        console.log("array cats: ", categories)
        const data = {
            categories: categories
        }
        const config = {
            headers: {Authorization: `Bearer ${auth.token}`}
        };
        axios.post(process.env.REACT_APP_API_URL + 'sites/' + sid + '/annotations-array', data, config)
            .then(response => {
                console.log("SUCCESS: annotations stored")
                this.setAlert('alert alert-success', 'All Bird Categories stored')
            })
            .catch(error => {
                console.log("ERROR: got error")
                if (error.response === undefined)
                    return
                this.handleErrorResponse(error.response.data.error)
            });
    }

    registerMembersMassively = (sid) => {
        const config = {
            headers: {Authorization: `Bearer ${auth.token}`}
        };
        const user_ids = this.state.selectedMembers.map(user => user.id)
        console.log("User IDs: ", user_ids)
        const data = {
            users: user_ids
        }
        axios.post(process.env.REACT_APP_API_URL + 'sites/' + sid + '/mass-register', data, config)
            .then(response => {
                console.log("SUCCESS: users are registered")
                this.setAlert('alert alert-success', 'Selected users are registered')
            })
            .catch(error => {
                console.log("ERROR: got error")
                if (error.response === undefined)
                    return
                this.handleErrorResponse(error.response.data.error)
            });

    }

    handleErrorResponse = (error_response) => {
        console.log("ERROR: => ", error_response)
        let header = "Errors on: "
        let details = []
        for (var key in error_response) {
            header += ' ' + key + ',';
            error_response[key].forEach(msg => {
                console.log(msg)
                details.push(msg)
            })
        }
        header = header.substring(0, header.length - 1)
        let error_header = <p> {header} </p>
        let error_details = <ul><strong>Details:</strong> {details.map(msg => <li>{msg}</li>)} </ul>
        let error_message = <div>{error_header} {error_details}</div>
        this.setAlert('alert alert-danger', error_message) // text-center
    }

    syncFarms = (newfarms) => {
        this.setState({farms: newfarms})
    }
    syncbCategories = (newbCategories) => {
        this.setState({bCategories: newbCategories})
    }
    onSelect = (selectedList, selectedItem) => {
        console.log(selectedList)
        this.setState({
            selectedMembers: selectedList
        }, () => console.log("updted: ", this.state.selectedMembers))
    }
    onRemove = (selectedList, removedItem) => {
        console.log(selectedList)
        this.setState({
            selectedMembers: selectedList
        }, () => console.log("remed: ", this.state.selectedMembers))
    }

    render() {
        const {siteName, latitude, longitude, companyId, spinner, success, newSiteId, farms, bCategories, companyMembers} = this.state
        const values = {siteName, latitude, longitude, companyId, farms, bCategories}
        if (spinner)
            return (
                <Spinner/>
            )

        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} role="alert">{this.state.alertMsg}</div>

        return (
            <div className="my-form" style={cardStyle}>
                {success && <Redirect to={"/sites/" + newSiteId}/>}
                <h2 className="text-center"> Site Registration</h2>
                <h4 className="text-center" style={secTitleStyle}>
                    {this.state.companyName}
                </h4>
                < br/>
                <div>{alert}</div>
                <FormSiteDetails
                    handleChange={this.handleChange}
                    setAlert={this.setAlert}
                    register={this.registerSite}
                    imgStyle={imgStyle}
                    values={values}
                    fromCompany={false}
                    syncFarms={this.syncFarms}
                    syncbCategories={this.syncbCategories}
                />
                <h5 className="text-center" style={{color: "#6a6a6a", marginTop:"50px"}}>
                    Register Members
                </h5>
                <p className="text-center">Register the company members that will be able to see that site.</p>
                <Multiselect
                    options={companyMembers}
                    onSelect={this.onSelect.bind(this)}
                    onRemove={this.onRemove.bind(this)}
                    displayValue="display_name"
                    placeholder="Company Members"
                    closeIcon="cancel"
                    name="registerUsers"
                    avoidHighlightFirstOption={true}
                    ref={this.multiselectRefMembers}
                />
            </div>
        )
    }
}

const cardStyle = {
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "5%",
    marginLeft: "20%",
    marginRight: "20%",
}

const secTitleStyle = {
    color: "#6a6a6a",
}

const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "200px",
    height: "200px",
    maxWidth: "100%",
    minWidth: "200px",
    margin: "0, 25%",
    marginBottom: "1%",
}


export default RegisterSite
