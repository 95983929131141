import React, { Component } from 'react'
import Modal from 'react-modal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faTrashAlt, faPlus, faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import auth from './helpers/auth'
import axios from 'axios'
import { Link } from 'react-router-dom'


export class DevsList extends Component {
    /**
     * Lists the developer users
     */
    render() {
        const { developers } = this.props;
        if (developers === undefined){
            return (
                <p> No developers found </p>
            )
        }
        return (
            <div>
                <div className="my-subtitle">
                    <FontAwesomeIcon icon={faLaptopCode} size={"sm"} style={{marginRight: "10px"}}/>
                    Developers
                    {auth.role_id <=1 &&
                    <Link to={"/registeruser/" + developers.id } className="btn mb-1 mt-auto" style={addStyle}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Link>}
                </div><br />

                <table className="table table-hover " >
                    <tbody style={wordBreak}>
                        <tr style={normalBreak}>
                            <th>Username</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th></th>
                        </tr>
                        {developers["users"].map(user => <UserRow user={user} key={user.id} setAlert={this.props.setAlert} /> )}
                    </tbody>
                </table>
                <br/>
                <hr className="my-hr" />
            </div>
        )
    }
}

export class UserRow extends Component {

    constructor() {
        super();
        this.state = {
            showModal: false
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleYes = this.handleYes.bind(this);
    }

    /**
     *  opens the modal to warn the delete
     */
    handleOpenModal() {
        this.setState({ showModal: true });
    }

    /**
     * closes the modal to warn the delete
     */
    handleCloseModal() {
        this.setState({ showModal: false });
    }

    /**
     * handles the yes answer on the delete modal. It makes a delete request
     * to the backend
     */
    handleYes() {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.delete(process.env.REACT_APP_API_URL + 'users/' + this.props.user.id, config)
            .then(response =>{
                this.setState({ showModal: false });
                this.props.setAlert("success", "User " + this.props.user.username + " deleted", true);
            })
            .catch(error => {
                this.setState({ showModal: false });
                this.props.setAlert("danger", "Action not accepted.", false);
            })
    }

    /**
     * Each row contains a user
     */
    render() {
        Modal.setAppElement("#root")
        return (
            <tr style={normalBreak}>
                <td>{this.props.user.username}</td>
                <td>{this.props.user.first_name + " " + this.props.user.last_name}</td>
                <td>{this.props.user.email}</td>
                <td>{auth.roles_titles[this.props.user.role_id] }</td>
                {auth.role_id <= 2 && <td>
                    <button style={iconStyle} onClick={()=>this.props.setAlert("warning", "Feature under construction!", false)}>
                        <FontAwesomeIcon icon={faComment} size="lg" />
                    </button>
                    {this.props.user.role_id > 2 && <button style={trashStyle} onClick={this.handleOpenModal}>
                        <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </button>}
                    <Modal
                        isOpen={this.state.showModal}
                        onRequestClose={this.handleCloseModal}
                        style={modalStyle}
                    >
                        <div className="container" style={{ padding: "40px 90px" }}>Are you sure you want to delete <strong>{this.props.user.username}</strong>?</div>
                        <button onClick={this.handleCloseModal} className="float-left">Cancel</button>
                        <button onClick={this.handleYes} className="float-right">Yes, I am sure</button>
                    </Modal>

                </td>}

            </tr>
        )
    }
}

const iconStyle = {
    color: "var(--main-primary)",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
}

const trashStyle = {
    color: "var(--main-danger)",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
}

const normalBreak = {
    wordBreak: "normal"
}

const wordBreak = {
    wordBreak: "break-word"
}

const modalStyle = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: { zIndex: 10000 }
}

const addStyle = {
    padding: "0px 5px",
    marginRight: "6px",
    color: "#fcfcfc",
    backgroundColor:"#97bdde"
}

export default DevsList
