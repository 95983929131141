import React, { Component } from 'react'
import axios from 'axios'
import auth from '../helpers/auth'
import ChangePassword from './auth/ChangePassword'
import { Company } from '../helpers/models'


export class UserProfile extends Component {
    /**
     * Displays the page with the user profile.
     * In this page the user can change his password or to ask for a verification email.
     */
    state = {
        changePassword: false,
        alert: false,
        msg: "",
        alarm_type: "",
        fetched: false,
        spinner: false,
    }

    resendVerificationMail() {
        this.setState({spinner: true})
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + auth.token,
            }
        };
        axios.get(process.env.REACT_APP_API_URL + 'email/resend', config)
            .then(response => {
                // console.log(response)
                this.setState({ alert: true, msg: "Email sent", alarm_type: "alert alert-primary" })
                setTimeout(() => this.setState({ alert: false, msg: "", alarm_type: "", spinner: false }), 5000)

            })
            .catch(error => {
                console.log(error)
                this.setState({ alert: true, msg: "An error has been occurred", alarm_type: "alert alert-danger" })
                setTimeout(() => this.setState({ alert: false, msg: "", alarm_type: "", spinner: false }), 5000)
            })
    }

    enableChpView() {
        this.setState({ changePassword: !this.state.changePassword })
    }

    render() {
        document.title = "BMS App - Profile"

        let chp_view, msg, type, resend = "";
        if (this.state.changePassword)
            chp_view = <ChangePassword />
        else
            chp_view = ''
        let spinner = <div></div>
        if (this.state.spinner) {
			spinner =
				<div className="spinner-border spinner-border-sm text" role="status" style={spinnerStyle}>
					<span className="sr-only">Loading...</span>
				</div>
        }
        const flag = (String(auth.email_verfied) === "null");
        console.log("--> Value: ", auth.email_verfied+ " type: " + typeof(auth.email_verfied) + " Boolean: " + flag)
        if (flag) {
            msg = "Email not verified!"
            type = "alert alert-danger"
            resend = <button className="btn btn-link" onClick={this.resendVerificationMail.bind(this)} disabled={this.state.spinner}> 
                {this.state.spinner ? "Resending.." : "Resend"} 
                {spinner}
            </button>
        }
        else {
            msg = "Email verified at " + auth.email_verfied
            type = "alert alert-success"
        }
        
        let companies_view = ''
        if (!this.state.fetched) {
            const config = {
                headers: { Authorization: `Bearer ${auth.token}` }
            };
            axios.get(process.env.REACT_APP_API_URL + 'companies', config)
                .then(response => {
                    let companies = [];
                    response.data.map(company => { companies.push(new Company(company)) })
                    this.setState({
                        fetched: true,
                        companies: companies
                    })
                })
                .catch(error => {
                    console.log("ERROR: on fetching companies")
                    console.log(error)

                });
        }
        else {
            companies_view =
                <div style={{maxHeight: "200px", overflow:"auto"}}>
                    {
                        this.state.companies.map((company) => (
                            <CompanyInstance company={company} key={company.id} />
                        ))
                    }
                </div>
        }

        return (
            <div className="container" style={{ marginTop: "5%" }}>
                <div style={cardStyle}>

                    <img
                        src={process.env.PUBLIC_URL + '/default_user_logo.png'}
                        alt="default user logo"
                        style={imgStyle}
                    />
                    <div className="float-right">
                        <h1>{auth.first_name}</h1>
                        <h1>{auth.last_name}</h1>
                        <h4 style={secTitleStyle}>{auth.username}</h4>
                    </div>
                    <div className="clearfix" /><br />
                    {this.state.alert ? <div className={this.state.alarm_type} role="alert">{this.state.msg}</div> : <div />}
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>
                                    Verified
                                </th>
                                <td className={type} role="alert">
                                    {msg} {resend}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Email
                                </th>
                                <td>
                                    {auth.email}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Role
                                </th>
                                <td>
                                    {auth.roles_titles[auth.role_id]}
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    Position
                                </th>
                                <td>
                                    {auth.speciality}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Companies
                                </th>
                                <td>
                                    <ul>
                                        {companies_view}
                                    </ul>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div className="text-center">
                        <button type="button" className="btn  btn-primary" onClick={() => { this.enableChpView() }}>
                            Change Password
                        </button>

                        {chp_view}
                    </div>
                </div>

            </div>
        )
    }
}

class CompanyInstance extends Component {
    render() {
        return (
            <li>{this.props.company.name}</li>
        )
    }
}
const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "200px",
    height: "200px",
    maxWidth: "100%",
    minWidth: "200px",
    margin: "0, 25%",
    marginBottom: "1%",
}

const cardStyle = {
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "5%",
    marginLeft: "20%",
    marginRight: "20%",
}

const secTitleStyle = {
    color: "#6a6a6a",
}

const spinnerStyle = {
	width: "1.2rem",
	height: "1.2rem",
	marginLeft: "5px"
}

export default UserProfile
