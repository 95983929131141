import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class GeneratorCard extends Component {
    /**
     * Renders a card that contains details about a generator
     * Those details are:
     *  - generatos's name
     *  - RGBs and Thermal cameras
     *  - total Detections / Deterrences / ShutDowns
     *  - Link for more details.
     */
    render() {
        const { generator } = this.props
        const num_RGB = generator.cameras.filter(camera => camera.type === "RGB").length
        const num_thermals = generator.cameras.filter(camera => camera.type === "Thermal").length

        return (
            <div className="card generator" style={cardStyle}>
                <div className="card-header">
                    <p >
                        {generator.name}
                    </p>
                </div>
                <div className="card-body" style={bodyStyle}>
                    <div className="row ">
                        <div className="col-6 text-center">
                            <div className="rgb float-left">
                                <div className="vertical-align">
                                    <p style={wrapStyle}> RGB <br />
                                        <FontAwesomeIcon icon={faVideo} size="1x" /><br />
                                        {num_RGB}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 text-center">
                            <div className="thermal  float-right">
                                <div className="vertical-align">
                                    <p style={wrapStyle}> Thermal <br />
                                        <FontAwesomeIcon icon={faVideo} size="1x" /><br />
                                        {num_thermals} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center" style={eventsContainerStyle}>
                        <div className="col-lg-4 col-sm-12 detections">
                            <div className="vertical-align">
                                <p style={wrapStyle}> Detections <br />
                                    {generator.detections}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 deterrences">
                            <div className="vertical-align">
                                <p style={wrapStyle}> Deterrences  <br />
                                    {generator.deterrences} </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 shutdowns">
                            <div className="vertical-align">
                                <p style={wrapStyle}> Shut Downs  <br />
                                    {generator.shutdowns} </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card-footer text-right more-details">
                    <Link to={"/generators/" + generator.id}>More Details</Link>
                </div>
            </div>
        )
    }
}

const bodyStyle = {
    padding: "20px",
    paddingTop: "0px"
}

const wrapStyle = {
    wordWrap: "normal"
}

const cardStyle = {
    marginBottom: "5%",
    border: "0px"
    /*  backgroundSize: "100%"
       minHeight: "500px" */
}
const eventsContainerStyle = {
    paddingTop: "40%"
}
export default GeneratorCard
