import { Autorenew } from '@material-ui/icons';
import React, { Component } from 'react';
//import GoogleMapReact from 'google-maps-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Gps extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ "marginTop": "10px", "width": "100%" }} className="container-inner-wrapper">
        <h2 className="my-title2">GPS / GIS Page</h2>
        <div className="text-light" >
          <h3>Not available for your subscription</h3><br />
        </div>
        <div className="gpsMap">
          <iframe style={{ width: "100%", height: "80vh" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6432901.280937284!2d19.987897915216934!3d38.06886548799333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135b4ac711716c63%3A0x363a1775dc9a2d1d!2sGreece!5e0!3m2!1sen!2sgr!4v1622475496410!5m2!1sen!2sgr">

          </iframe>
        </div>

        {/* <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyArDRJqjFliXGiY4ClhsQBqyiD6z0zQ_Jo" }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            google={this.props.google}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact>
        </div> */}
      </div>
    );
  }
}

const normalBreak = {
  wordBreak: "normal",
  backgroundColor: "#f4f4f6"
}

const wordBreak = {
  wordBreak: "break-word"
}

const gpsMap = {
  width: "100%"
}
const gpsMapFrame = {
  width: "100% !important",
  height: "500px !important"
}


export default Gps