import React, { Component } from 'react'
import EventsBar from './EventsBar'
import StatusPie from './StatusPie'

export class ReportSet extends Component {

    render() {
        const { statistics, active, inactive } = this.props
        return (
            <div className="container justify-center">
                <div className="row" style={{ marginTop: '35px' }}>
                    <div className="col-lg-6">
                        <EventsBar style={{ height: "300px" }}
                            detections={statistics.detections}
                            deterrences={statistics.deterrences}
                            shutdowns={statistics.shutdowns}
                        />
                    </div>
                    <div className="col-lg-6">
                        <StatusPie
                            active={active}
                            inactive={inactive}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default ReportSet
