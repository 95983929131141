import React, {Component} from "react";
import auth from "./helpers/auth";
import axios from "axios";
import Spinner from "./Spinner";
import {smoothToTop} from "./helpers/misc";

export class UserSettingsModal extends Component {
    state = {
        sites: [],
        userSites: [],
        spinner: true,
        alert: false,
        alertClass: "alert alert-info text-center",
        alertMsg: "this is a temp alert",
    }
    user = this.props.user
    config = {
        headers: {Authorization: `Bearer ${auth.token}`}
    };

    setAlert = (type, msg) => {
        this.setState({
            alert: true,
            alertClass: "alert alert-" + type + " text-center",
            alertMsg: msg,
        })
        smoothToTop();
        setTimeout(() => {
            this.setState({
                alert: false,
                alertClass: '',
                alertMsg: '',
            })
        }, 2000)
    }

    fetchUserSites = () => {
        axios.get(process.env.REACT_APP_API_URL + 'users/' + this.user.id + '/sites', this.config)
            .then(response => {
                this.setState({
                    userSites: response.data
                })
            })
            .catch(error => {
                console.log(error)
                this.setAlert("danger", "Error, could not fetch registered sites for user")
            });
    }

    fetchAvailableSites = () => {
        axios.get(process.env.REACT_APP_API_URL + 'users/' + this.user.id + "/sites_non_visible", this.config)
            .then(response => {
                this.setState({
                    sites: response.data,
                })
            })
            .catch(error => {
                console.log(error)
                this.setAlert("danger", "Error: could not get your available sites")
            });
    }

    unregisterSite = (site_id, site_name) => {
        axios.get(process.env.REACT_APP_API_URL + 'sites/' + site_id + '/users/' + this.user.id + "/unregister",
            this.config)
            .then(response => {
                this.setState({
                    userSites: [],
                    sites: [],
                })
                this.fetchUserSites();
                this.fetchAvailableSites()
                this.setAlert("success", "User unregister from the site:" + site_name)
            })
            .catch(error => {
                console.log(error)
                this.setAlert("danger", "Error could not unregister the user in site: " + site_name)
            });
    }

    registerSite = (site_id, site_name) => {
        const url = process.env.REACT_APP_API_URL + 'sites/' + site_id + '/users/' + this.user.id + "/register"
        axios.get(url, this.config)
            .then(response => {
                this.setState({
                    userSites: [],
                    sites: []
                })
                this.fetchUserSites();
                this.fetchAvailableSites()
                this.setAlert("success", "User register to site:" + site_name)
            })
            .catch(error => {
                console.log(error)
                this.setAlert("danger", "Error could not register the user in the site: " + site_name)
            });
    }

    async componentDidMount() {
        await this.fetchUserSites()
        await this.fetchAvailableSites()
        this.setState({
            spinner: false
        })
    }

    render() {
        const {spinner, userSites, sites} = this.state
        const {alert, alertStyle, alertClass, alertMsg} = this.state
        if (spinner)
            return <Spinner/>
        return (
            <div style={contentStyle} className="text-center">
                <h1 style={titleStyle}> User Settings </h1>
                <hr/>
                {alert && <div style={alertStyle} className={alertClass} role="alert">{alertMsg}</div>}
                <table className="table" style={{textAlign: "center", margin: "20px auto"}}>
                    <tbody>
                    <tr style={bgColor}>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                    </tr>
                    <tr>
                        <td>{this.user.username}</td>
                        <td>{this.user.first_name + " " + this.user.last_name}</td>
                        <td>{this.user.email}</td>
                        <td>{auth.roles_titles[this.user.role_id]}</td>
                    </tr>
                    </tbody>
                </table>

                <h2 style={titleStyle2}> Registered Sites </h2>
                <p>Sites that are visible to the user.</p>
                <div style={{maxHeight: "400px", overflow: "auto"}}>
                    <table className="table" style={{textAlign: "center", width: "70%", margin: "20px auto"}}>
                        <tbody>
                        <tr style={bgColor}>
                            <th>Site Name</th>
                            <th>Action</th>
                        </tr>
                        {
                            userSites.map(site => {
                                return (
                                    <tr key={site.id}>
                                        <td>{site.name}</td>
                                        <td>
                                            <button className="btn btn-danger"
                                                    onClick={() => this.unregisterSite(site.id, site.name)}>
                                                Unregister
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <h2 style={titleStyle2}> Non-Registered Sites </h2>
                <p>Sites that are not visible to the user but are visible for you.</p>
                <div style={{maxHeight: "400px", overflow: "auto"}}>
                    <table className="table" style={{textAlign: "center", width: "70%", margin: "20px auto"}}>
                        <tbody>
                        <tr style={bgColor}>
                            <th>Site Name</th>
                            <th>Action</th>
                        </tr>
                        {
                            sites.map(site => {
                                return (
                                    <tr key={site.id}>
                                        <td>{site.name}</td>
                                        <td>
                                            <button className="btn btn-primary"
                                                    onClick={() => this.registerSite(site.id, site.name)}>
                                                Register
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <button className="btn btn-danger" style={{marginTop: "50px"}} onClick={this.props.closeMe}>Close
                </button>
            </div>
        )
    }
}

const contentStyle = {
    margin: "5% 50px",
}

const titleStyle = {
    fontWeight: "300",
    fontSize: "2rem"
}
const titleStyle2 = {
    fontWeight: "300",
    fontSize: "1.5rem"
}
const bgColor = {
    backgroundColor: "#f4f4f6"
}
export default UserSettingsModal