import React, { Component } from "react"
import axios from 'axios'
import auth from '../helpers/auth'
import qs from 'qs';
import { smoothToTop } from '../helpers/misc'
import Spinner from '../Spinner'
import EventsTableList from "../EventsTableList"
import { Link } from 'react-router-dom';


class CameraDetails extends Component {
    /**
     * Page that displays the details of a camera
     * It contains a table with information of the camera
     * An Event summary component
     * and a Table with some recent events
     */

    state = {
        camera: {},
        spinner: true
    }

    componentDidMount() {
        smoothToTop();
        let params = {}
        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()

        params["from"] = yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate()
        params["to"] = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        };
        let cid = this.props.match.params.id
        axios.get(process.env.REACT_APP_API_URL + 'cameras/' + cid, config)
            .then(response => {
                this.setState({
                    camera: response.data,
                    spinner: false
                })
                document.title = "BMS App - Camera " + response.data.name

            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false
                })
            });
    }

    render() {
        const { camera, spinner } = this.state
        if (spinner)
            return (
                <Spinner />
            )

        return (
            <div className="container-inner-wrapper">
                <h2 className="my-title">Camera <strong>{camera.name} </strong></h2>
                <hr />
                <br />
                {/* Specs Table */}
                <div className="table-responsive">
                    <table className="table events-table">
                        <tbody>
                            <tr style={bgColor}>
                                <th className="d-none d-md-table-cell">Site</th>
                                <th className="d-none d-md-table-cell">Farm</th>
                                <th className="d-none d-md-table-cell">Generator</th>
                                <th className="d-none d-md-table-cell">Name</th>
                                <th className="d-none d-md-table-cell">Type</th>
                                <th className="d-none d-md-table-cell">Horn Name</th>
                                <th className="d-none d-md-table-cell">Horn Volume</th>
                                <th className="d-none d-md-table-cell">Live Video</th>
                            </tr>
                            <tr>
                                <td>
                                    <span className="d-lg-none">Site: </span>{camera.site_name}</td>
                                <td><span className="d-lg-none">Farm: </span>{camera.farm_name}</td>
                                <td><span className="d-lg-none">Generator: </span>{camera.generator_name}</td>
                                <td><span className="d-lg-none">Name: </span>{camera.name}</td>
                                <td><span className="d-lg-none">Type: </span>{camera.type}</td>
                                <td><span className="d-lg-none">Horn Name: </span>{camera.horn.name}</td>
                                <td><span className="d-lg-none">Horn Volume: </span>{String(Math.round((camera.horn.volume - (-64)) / 64 * 100)) + "%"}</td>
                                <td><span className="d-lg-none">Live Video: </span> <Link to={"/cameras/" + camera.id + "/streaming-info"} >Live</Link></td> {/* className="disabled" */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br /><br />
                <EventsTableList content={camera} selectedCameras={[camera]} selectedFarms={[]} selectedGenerators={[]} />
                <br /><br />

            </div >
        );
    }
}

const bgColor = {
    backgroundColor: "#f4f4f6"
}


export default CameraDetails;



