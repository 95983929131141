import React, { Component } from 'react'
import axios from 'axios'
import qs from 'qs';
import auth from './helpers/auth'
import EventSummary from '../components/EventSummary'
import EventsTable from '../components/EventsTable'


export class EventsTableList extends Component {

    state = {
        date: [new Date(), new Date()],
        selectedGenerators: [],
        selectedCameras: [],
        selectedFarms: [],
        events: [],
        totalEvents: 0,
        tableSpinner: false,
        statisticsSpinner: false,
        pagingControl: {
            pageSize: 20,
            currentPage: 0,
            lastPage: 0
        },
        statistics: {
            detections: 0,
            deterrences: 0,
            shutdowns: 0
        },
        alert: false
    }

    onChangeDate = date => this.setState({ date: date }, () => this.updateAll())

    componentDidMount() {
        const { content, selectedFarms, selectedGenerators, selectedCameras } = this.props
        let yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)
        let today = new Date()
        this.setState({
            selectedFarms: selectedFarms,
            selectedGenerators: selectedGenerators,
            selectedCameras: selectedCameras,
            date: [yesterday, today]

        }, () => this.updateAll())

    }

    updateAll = () => {
        this.search()
        this.fetchStatistics()
    }
    /**
     * Displayes an alert on the top of the view
     * @param {string} type the type of the alert
     * @param {string} msg The message of the alert
     */
    setAlert = (type, msg, fetch = false) => {
        this.setState({
            alert: true,
            alertClass: "alert alert-" + type + " text-center",
            alertMsg: msg,
        })

        setTimeout(() => {
            this.setState({
                alert: false,
                alertClass: '',
                alertMsg: '',
            });
            if (fetch) {
                this.updateAll()
            }

        }, 2000)
    }

    fetchStatistics = () => {
        console.log("Fetching statistics in EventsTableList")
        let { date } = this.state
        const selectedTypes = ['detection', 'deterrence', 'shutdown']
        let params = {}

        const { selectedGenerators, selectedFarms, selectedCameras } = this.state
        if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        } else {
            console.log("Unexpected input in Events Summary request")
            return
        }
        params["from"] = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        params["to"] = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        console.log(params["from"], "----", params["to"])
        params["eventtypes"] = selectedTypes
        const requestRoute = process.env.REACT_APP_API_URL + "events/statistics"
        console.log("requesting to :: " + requestRoute)
        this.setState({
            statisticsSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            const statistics = {
                detections: response.data.detections,
                deterrences: response.data.deterrences,
                shutdowns: response.data.shutdowns
            }
            this.setState({
                statistics: statistics,
                statisticsSpinner: false
            })
        })
            .catch(error => {
                console.log("Got error on statistics request")
                console.log(error.response)
                this.setState({
                    statisticsSpinner: false
                })
            })
    }

    search = () => {
        console.log("Searching in EventsTableList")
        const { date } = this.state
        const selectedTypes = ['detection', 'deterrence', 'shutdown']
        let params = {}
        const { selectedGenerators, selectedFarms, selectedCameras } = this.state
        if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        } else {
            console.log("Unexpected input in EventsTableList request")
            return
        }

        params["from"] = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        params["to"] = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        params["page"] = 1
        params["eventtypes"] = selectedTypes
        const requestRoute = process.env.REACT_APP_API_URL + "events/search"
        console.log("requesting to :: " + requestRoute)

        this.setState({
            tableSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            // this.setAlert("success", "Successful request")
            const myPagingControl = {
                currentPage: response.data.current_page,
                lastPage: response.data.last_page,
            }
            let events = response.data.data
            events.map(event => event['generator'] = selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
            this.setState({
                tableSpinner: false,
                totalEvents: response.data.total,
                events: events,
                pagingControl: myPagingControl,
            })
        })
            .catch(error => {
                console.log("Got error on search request")
                console.log(error.response)
                this.setState({
                    tableSpinner: false,
                })
            })
    }

    changePage = (pageId) => {
        const { date } = this.state
        const { selectedGenerators, selectedFarms, selectedCameras } = this.state
        let params = {}

        const selectedTypes = ['detection', 'deterrence', 'shutdown']

        if (selectedFarms.length > 0) {
            const farmsIds = selectedFarms.map(farm => farm.id)
            params["farm"] = farmsIds
        }
        else if (selectedGenerators.length > 0) {
            const genIds = selectedGenerators.map(gen => gen.id)
            params["gen"] = genIds
        }
        else if (selectedCameras.length > 0) {
            const camIds = selectedCameras.map(cam => cam.id)
            params["cam"] = camIds
        } else {
            return
        }
        params["from"] = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        params["to"] = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        params["page"] = pageId
        params["eventtypes"] = selectedTypes
        const requestRoute = process.env.REACT_APP_API_URL + "events/search"
        this.setState({
            tableSpinner: true
        })
        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            const myPagingControl = {
                currentPage: response.data.current_page,
                lastPage: response.data.last_page,
            }
            let events = response.data.data
            // events.map(event => event['generator'] = this.state.selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
            this.setState({
                tableSpinner: false,
                totalEvents: response.data.total,
                events: events,
                pagingControl: myPagingControl
            })
        })
            .catch(error => {
                console.log("Got error on search request")
                console.log(error.response)
                this.setState({
                    tableSpinner: false,
                })
            })
    }

    render() {
        const { content, selectedGenerators, selectedFarms, selectedCameras } = this.props
        const { date, statistics, totalEvents, events, tableSpinner, pagingControl, statisticsSpinner } = this.state
        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} style={{ margin: "0 20%" }} role="alert">{this.state.alertMsg}</div>
        return (
            <div style={{ "marginTop": "10px" }}>

                <EventSummary
                    content={content}
                    selectedFarms={selectedFarms}
                    selectedGenerators={selectedGenerators}
                    selectedCameras={selectedCameras}
                    onChangeDate={this.onChangeDate}
                    date={date}
                    statistics={statistics}
                    statisticsSpinner={statisticsSpinner}
                />
                <div>{alert}</div>
                {totalEvents > 0 && <EventsTable
                    events={events}
                    tableSpinner={tableSpinner}
                    pagingControl={pagingControl}
                    changePage={this.changePage}
                    setAlert={this.setAlert}
                />}
            </div>
        )
    }
}




export default EventsTableList