import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import axios from 'axios'
import Modal from 'react-modal'

import auth from '../../helpers/auth'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class GetConfig extends Component {

    state = {
        selectedGenerator: "",
        fetchedConfigId: "",
        config: {},
        fetching: false,
        modal: false,
        alert:{
            visible: false,
            status: "",
            msg: ""
        }
    }

    handleChangeGenerator = (event) => {
        const selectedGenerator = event.target.value
        this.setState({
            selectedGenerator: selectedGenerator
        });
    }

    handleOpenModal = () => {
        this.setState({modal: true})
    }

    handleCloseModal = () => {
        this.setState({modal: false})
    }

    fetchConfig = () =>{
        const { selectedGenerator, fetchedConfigId } = this.state
        if (selectedGenerator == "")
            return;

        // dont fetch again the same
        if (fetchedConfigId == selectedGenerator){
            this.handleOpenModal()
            return
        }

        console.log("will fetch CONFIG for ", selectedGenerator)
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        this.setState({
            fetching: true
        })
        const url = process.env.REACT_APP_API_URL + 'generators/' + selectedGenerator + '/config_file'
        axios.get(url, config)
            .then(response => {
                this.setState({
                    fetchedConfigId: selectedGenerator,
                    config: response.data,
                    fetching: false
                }, () => this.handleOpenModal())
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                this.setAlert("danger", "Error: " + error.response.data['error'])
                this.setState({
                    fetching: false
                })

            });
    }    
    setAlert = (status, message) =>{
        this.setState({
            alert: {
                visible: true,
                status: status,
                msg: message
            }
        }, () => setTimeout( () => {
            this.setState({
                alert: {
                    visible: false,
                    status: "",
                    msg: ""
                }
            })
        }, 5000)
        )
    }

    render() { 
        Modal.setAppElement("#root")

        const { generators } = this.props
        const { selectedGenerator, fetching, modal, config, alert} = this.state

        const config_modal = selectedGenerator !== "" ?
            < Modal
                isOpen = { modal }
                onRequestClose = { this.handleCloseModal }
                style = { modalStyle }
                shouldReturnFocusAfterClose={false}
            >
                <div className="text-center">
                    <div>
                        <h1 style={{"fontWeight": 300, "fontSize": "2rem"}}> Generator Config: {selectedGenerator + " | " + generators.filter(gen => gen.id == selectedGenerator)[0].name} </h1>
                        <hr className="my-Hr"/>
                    </div>
                    <div className="text-left" style={codeblockStyle} >
                        <pre>{JSON.stringify(config, null, 2) }</pre>
                    </div>
                    
                    <button className="btn btn-danger" onClick={this.handleCloseModal} >Close</button>
                </div>
            </Modal >
            : 
            < Modal
                isOpen = { modal }
                onRequestClose = { this.handleCloseModal }
                style = { modalStyle }
                shouldReturnFocusAfterClose={false}
            >
                <div>Error (?) </div>
            </Modal>

        return ( 
            <div>
                {config_modal}
                <h2 className="my-title2">Get Config</h2>
                <p> Fetch and preview the current configuration file from the selected generator.</p>
                
                {alert.visible && <div className={"alert alert-" + alert.status}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} style={{marginRight: "10px"}}/>
                    {alert.msg}
                </div>}

                <FormControl style={{margin:"5px auto", width: "300px"}}>
                    <InputLabel id="demo-simple-select-helper-label">Select Generator</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedGenerator}
                        onChange={this.handleChangeGenerator}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {generators.map(gen => <MenuItem key={gen.id} value={gen.id}>{gen.id + " ) " + gen.site + " / " + gen.farm + " / " + gen.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>The available Generators</FormHelperText>
                </FormControl>
                <button className="btn btn-primary" onClick={this.fetchConfig} style={fetchButtonStyle}> 
                    Fetch
                    {fetching && <span className="spinner-border spinner-border-sm" role="status" style={{margin: "0px 5px"}}/> }
                </button>
            </div>
         );
    }
}

const fetchButtonStyle = {
    margin: "30px 30px",
    padding: "auto 30px"
 }
 
 const modalStyle = {
    content: {
        width: "90%",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: { zIndex: 20000 }
}

const codeblockStyle = {
    padding: "150px, 125px",
    backgroundColor: "#a3a3a3",
    overflow: "auto",
    height: "700px",
    margin: "50px 20px"
}


export default GetConfig;