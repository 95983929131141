import React, { Component } from 'react'

import InputLabel from '@material-ui/core/InputLabel';
import { Multiselect } from 'multiselect-react-dropdown';

class GeneratorsMultiselect extends Component {
    multiselectRefGenerators = React.createRef();

    onSelect = (selectedList, selectedItem) => {
        this.props.syncSelectedList(selectedList)
    }

    onRemove = (selectedList, removedItem) => {
        this.props.syncSelectedList(selectedList)
    }

     prepocessGeneratorsViewname(generators){
        generators.map(gen => {
            gen["view_name"] = gen.id + ") " + gen.site + " / " + gen.farm + " / " + gen.name
        })
    }

    render() { 
        const { generators, selectedGenerators } = this.props
        if(generators !== undefined)
            this.prepocessGeneratorsViewname(generators)
        return ( 
            <div>
                <InputLabel> Select the targeted Generators</InputLabel>
                <Multiselect
                    options={generators}
                    selectedValues={selectedGenerators.map(gen => gen)}
                    onSelect={this.onSelect.bind(this)}
                    onRemove={this.onRemove.bind(this)}
                    displayValue="view_name"
                    placeholder="Target generators"
                    closeIcon="cancel"
                    avoidHighlightFirstOption={true}
                    ref={this.multiselectRefGenerators}
                />
                {selectedGenerators.length > 0 && 
                    <button type="button" className="btn btn-link float-right sm" onClick={() => this.props.syncSelectedList([])}>Clear</button>}
            </div>
         );
    }
}
 
export default GeneratorsMultiselect;