import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'

export class EventsBar extends Component {

    render() {
        const chartData = {
            labels: ['Detections', 'Deterrences', 'Shut Downs'],
            datasets: [{
                data: [this.props.detections, this.props.deterrences, this.props.shutdowns],
                backgroundColor: ["#00FF0055", "#0000FF55", "#FF000055"],
                borderColor: ["#00FF00", "#0000FF", "#FF0000"],
                borderWidth: 1

            }]
        }
        return (
            <div className="bar-chart">
                <Bar
                    data={chartData}
                    height={200}
                    width={100}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Event Summary',
                            lineHeight: 1.7,
                            fontColor: "white"
                        },
                        scales: {
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                    fontColor: "#efefef"
                                }
                            }],
                            xAxes: [{
                                ticks: { fontColor: "#efefef" }
                            }]
                        }
                    }}
                />
            </div>
        )
    }
}

export default EventsBar
