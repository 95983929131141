import React, { Component } from 'react'

export class NotExist extends Component {
    /**
     * A page that is displayed if the user searched for a not existing path
     */
    render() {
        return (
            <div className='text-center' style={{marginTop: "5%"}}>
                <h2 className='no-data'> Sorry, page under construction</h2>                
            </div>
        )
    }
}

export default NotExist
