import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap'


import auth from '../helpers/auth'
import axios from 'axios'
import { smoothToTop } from '../helpers/misc';


export class UploadAnnotations extends Component {
    /**
     * This page is active only in development and is used
     * for testing new features there before they are deployed
     */
    state = {
        uploadPercentage: 0,
        showUploadCompleteText: false,
        enableUploadButton: false,
        invalidExtension: false,
        alert: {
            appear: false,
            status: "primary",
            message: "Nice work"
        }
    }

    componentDidMount() {
        smoothToTop();
    }

    setAlert = (status, message) => {
        console.log("new alert")
        this.setState({
            alert: {
                appear: true,
                status: status,
                message: message
            }
        }, () => {
            setTimeout(() => {
                this.setState({
                    alert: {
                        appear: false,
                        status: "",
                        message: ""
                    }
                })
            }, 3)
            smoothToTop()
        }
        );
    }

    setUploadFile = ({ target: { files } }) => {
        const acceptedExtensions = ["zip", "rar", "7z", "7zip", "gz"]
        let fileName = files[0]['name']
        let fileExtension = fileName.split('.').pop();
        if (acceptedExtensions.includes(fileExtension)) {
            this.setState({
                file: files[0],
                showUploadCompleteText: false,
                enableUploadButton: true,
                invalidExtension: false
            })
        }
        else {
            this.setState({
                file: {},
                enableUploadButton: false,
                invalidExtension: true
            })
            this.setAlert("danger", "Not supported extension")
        }
    }

    uploadFile = () => {
        const { file } = this.state
        let data = new FormData();
        data.append('file', file)

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor((loaded * 100) / total)
                console.log(loaded + "Kb of " + total + "Kb | " + percent + "%")
                if (percent < 100) {
                    this.setState({
                        uploadPercentage: percent
                    })
                }
            }
        }
        axios.post(process.env.REACT_APP_API_URL + 'ornithologist2S3', data, config)
            .then(res => {
                console.log(res)
                this.setState({
                    uploadPercentage: 100
                }, () => {
                    this.setState({
                        uploadPercentage: 0,
                        showUploadCompleteText: true,
                        file: {}
                    })
                    this.setAlert("success", "Upload Completed")

                })
            }).catch(error => {
                console.log("ERROR: got error")
                console.log(error)
                console.log("===>", error.response.data.error)
                this.setAlert("danger", "Got Error on Upload. Info: Read console")
            });
    }

    render() {
        const { uploadPercentage, showUploadCompleteText, enableUploadButton, invalidExtension } = this.state;
        return (
            <div className="container-inner-wrapper">
                <div className="row text-center">
                    <div className="col">
                        {showUploadCompleteText &&
                            <div className="alert alert-success text-center" style={{ marginTop: "15px" }} role="alert">
                                Upload complete!
                            </div>}
                        {invalidExtension &&
                            <div className="alert alert-danger text-center" style={{ marginTop: "15px" }} role="alert">
                                Invalid Extension. Try again with a compressed folder.
                            </div>}

                        <h2 style={{ marginTop: "30px", fontWeight: "300", fontSize: "2rem" }}> Upload</h2>
                        <div style={{ margin: "20px 0" }}>
                            <p>Select a compressed (zip, rar, 7z, 7zip, gz) file to upload to S3</p>
                            <input type="file" accept=".zip,.rar,.7z,.7zip,.gz" style={fileUploaderStyle} className="text-center" onChange={this.setUploadFile} />
                        </div>

                        <button className={enableUploadButton ? "btn btn-primary" : "btn btn-primary disabled"} onClick={this.uploadFile} style={{ marginBottom: "20px" }}> Submit</button>
                        {uploadPercentage > 0 &&
                            <div style={{ margin: "auto", width: "50%" }}>
                                <ProgressBar now={uploadPercentage} active="true" label={uploadPercentage + "%"} />
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

const fileUploaderStyle = {
    border: "solid 1px #999",
    borderRadius: "5px",
    padding: "20px 20px",
    margin: "0 0"
}


export default UploadAnnotations
