import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faBullhorn, faFire, faPlay } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';


export class CameraCard extends Component {

    /**
     * Renders a Card that contains details about a camera
     * The card contains in details:
     *  - camera name
     *  - camera type
     *  - connected horn's name
     *  - live stream link
     *  - Detections / Deterrences / Shutdowns
     *  - link for more Details
     */
    render() {
        const { camera, generator_name } = this.props
        return (
            <div className="card camera" style={cardStyle}>
                <div className="card-header">
                    <p >
                        {generator_name}_{camera.type === "RGB" ? "RGB" : "Thermal"}_{camera.name}
                        <FontAwesomeIcon
                            className="float-right"
                            icon={camera.type === "RGB" ? faStar : faFire}
                            size="sm"
                        />
                    </p>
                </div>
                <div className="card-body" >
                    <div className="row">
                        <div className="col-6 text-center">
                            <div className=" active-round horn float-left">
                                <div className="vertical-align">
                                    <FontAwesomeIcon icon={faBullhorn} size="lg" /><br />
                                    {camera.horn_name}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-center">
                            <div className="active-round stream float-right">
                                <div className="vertical-align">
                                    <FontAwesomeIcon icon={faPlay} size="lg" /><br />
                                    <Link to={"/cameras/" + camera.id + "/streaming-info"} style={wrapStyle}><span style={{ fontSize: "0.90rem" }}>Live<br />Streaming</span></Link> {/* className="disabled" */}
                                </div></div>
                        </div>
                    </div>
                    <br />

                    <div className="row text-center" style={{ paddingTop: "40%" }}>
                        <div className="col-lg-4 col-sm-12 detections">

                            <div className="vertical-align">
                                <p style={wrapStyle}> Detections
                                <br />{camera.detections} </p>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-12 deterrences">
                            <div className="vertical-align">
                                <p style={wrapStyle}> Deterrences <br />
                                    {camera.deterrences} </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 shutdowns">
                            <div className="vertical-align">
                                <p style={wrapStyle}> Shut Downs
                                <br />{camera.shutdowns} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right more-details">
                    <Link to={"/cameras/" + camera.id}>More Details</Link>
                </div>
            </div>
        )
    }
}

const wrapStyle = {
    wordWrap: "normal"
}

const cardStyle = {
    marginBottom: "5%"
}

const disabledLinkStyle = {
    wordWrap: "normal",
    pointerEvents: "none",
    color: "#ccc"
}
export default CameraCard
