import React, {Component} from 'react'
import ReactPlayer from 'react-player'
import {smoothToTop} from "../helpers/misc";
import ReactHlsPlayer from "react-hls-player";

export class TestPage extends Component {
    /**
     * This page is active only in development and is used
     * for testing new features there before they are deployed
     */
    state = {
        inputText: "",
        givenURL: "",
        showPlayer: false,
        alert: {
            appear: false,
            status: "primary",
            message: "Nice work"
        }
    }

    setAlert = (status, message) => {
        this.setState({
                alert: {
                    appear: true,
                    status: status,
                    message: message
                }
            }, () => {
                setTimeout(() => {
                    this.setState({
                        alert: {
                            appear: false,
                            status: "",
                            message: ""
                        }
                    })
                }, 3000)
            }
        );
    }

    enablePlayer = () => {
        const inputText = this.state.inputText
        if (inputText === "") {
            this.setAlert("danger", "Input url is empty")
            return
        }
        this.setState({
            givenURL: inputText,
            showPlayer: true
        });
    }
    onInputChange = (e) => {
        const text = e.target.value;
        this.setState({
            inputText: text
        });
    };

    render() {
        let q = new URLSearchParams(this.props.location.search);
        const {alert, givenURL, showPlayer} = this.state
        let token = q.get("keycode");
        let allowed = (token === "of9xZNmCmx");


        const demoLiveStreamming = allowed ?
            <div style={{margin: "50px 50px"}}>
                <h3>Live Streaming Demo</h3>
                <div style={{width: "90%", marginTop: "20px"}}>
                    <div style={{padding: "20px 20px"}}>
                        <label>Paste the Generated URL:</label>
                        <input type="text" className="form-control" placeholder="rtmp://"
                               onChange={this.onInputChange}/>
                    </div>
                    <div style={{padding: "0px 20px"}}>
                        <button className="btn btn-primary" onClick={this.enablePlayer}>Test</button>
                        <small style={{color: "#666"}}> A react player will be appear after submitting</small>
                    </div>
                </div>
                {showPlayer &&
                <div style={{marginTop: "50px"}}>
                    <h3>Players </h3>
                    <div className="row">
                        <div className="col">
                            <h4>
                                Simple player
                            </h4>
                            <ReactPlayer url={givenURL} style={videoPlayerStyle} controls={true}/>
                        </div>
                        <div className="col">
                            <h4>
                                HLS player
                            </h4>
                            <ReactHlsPlayer
                                src={givenURL}
                                autoPlay={false}
                                controls={true}
                                width="100%"
                                height="100%"
                             />
                        </div>
                    </div>
                </div>
                }
            </div>
            : <p>This page is empty </p>
        return (
            <div>
                <h1> Testing Page </h1>
                {alert.appear &&
                <div className={"alert alert-" + alert.status + " text-center"} style={{margin: "15px 20%"}}
                     role="alert">{alert.message}</div>}
                {demoLiveStreamming}
            </div>
        )
    }
}

const videoPlayerStyle = {
    marginTop: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#000"
}

export default TestPage
