import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import axios from 'axios'
import auth from '../../helpers/auth'
import qs from 'qs';

import ActionDropdown from './ActionDropdown'
import GeneratorsMultiselect from './GeneratorsMultiselect'
import ResultsTable from './ResultsTable';

class GetSetItem extends Component {
    state = { 
        selectedAction: "get",
        selectedGenerators: [],
        results: [],
        showResults: false,
        fetching: false,
        alert:{
            visible: false,
            status: "",
            msg: ""
        },
        item: "",
        newValue: ""
     }

     setAlert = (status, message) =>{
        this.setState({
            alert: {
                visible: true,
                status: status,
                msg: message
            }
        }, () => setTimeout( () => {
            this.setState({
                alert: {
                    visible: false,
                    status: "",
                    msg: ""
                }
            })
        }, 5000)
        )
    }

    handleChangeAction = (event) => {
        const selectedAction = event.target.value
        this.setState({
            selectedAction: selectedAction
        });
    }

    syncSelectedList = (selectedList) => {
        this.setState({
            selectedGenerators: selectedList,
        })
    }

    hideResults = () => {
        this.setState({
            showResults: false
        })
    }

    handleInput = input => e => {
        this.setState({ [input]: e.target.value })
    }
    applyAction = () => {
        const { selectedAction, selectedGenerators, item } = this.state
        if (selectedGenerators.length === 0 ){
            this.setAlert("warning", "No generators has been selected!")
            return
        }
        if(item === ""){
            this.setAlert("warning", "Please fill all the required fields")
            return
        }
        let targetGenerators = selectedGenerators.map(gen => gen.id);
        console.log("target generators: ", targetGenerators)
        console.log("APPLING ACTION " + selectedAction)
        console.log("TARGET: " + item)
        this.setState({
            fetching:true,
            showResults: false
        })
        const url = process.env.REACT_APP_API_URL + "generators/item/" + item
        if(selectedAction === "get"){
            let params = {}
            params["gens"] = targetGenerators

            axios.get(url, {
                headers: { Authorization: `Bearer ${auth.token}` },
                params, 
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
                }).then(response => {
                    console.log(response.data)
                    this.setAlert("success", "Request completed with success")
                    this.setState({
                        fetching:false,
                        showResults: true,
                        results: response.data
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.setState({
                        fetching: false,
                        showResults: false
                    })
                    if(error.response === undefined)
                        return
                    console.log(error.response)
                    this.setAlert("danger", "Error: " + error.response.data['error'])
                })
        }
        else if(selectedAction === "set"){
            const { newValue } = this.state
            if(newValue === ""){
                this.setAlert("danger", "Error: the new value is empty")
                this.setState({
                    fetching:false,
                })
                return
            }
            const data = {
                gens: targetGenerators,
                value: newValue
            }
            const config = {
                headers: { Authorization: `Bearer ${auth.token}` }
            };
            axios.post(url, data, config)
            .then(response => {
                console.log(response.data)
                this.setAlert("success", "Request completed with success")
                this.setState({
                    fetching: false,
                    showResults: true,
                    results: response.data
                })
            })
            .catch(error => {
                this.setState({
                    fetching: false,
                    showResults: false
                })
                console.log(error)
                if(error.response === undefined)
                    return
                console.log(error.response)
                this.setAlert("danger", "Error: " + error.response.data['error'])
            })
        }
    }


    render() { 
        let { generators } = this.props
        const { selectedAction, results, showResults, alert, selectedGenerators, fetching } = this.state
        const { item, newValue } = this.state
        const buttonClass = (selectedAction === "set") ? "danger" : "primary"
        const buttonContext = (selectedAction === "set") ? "Apply" : "Fetch"

        return ( 
            <div>
                <h2 className="my-title2">Get/Set Items</h2>
                <p> Handle a specific item from the configuration </p>

                {alert.visible && <div className={"alert alert-" + alert.status}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} style={{marginRight: "10px"}}/>
                    {alert.msg}
                </div>}

                <div className="row">
                    <div className="col-1">
                        <ActionDropdown selectedAction={selectedAction} handleChangeAction={this.handleChangeAction}/>
                    </div>

                    <div className="col-4">
                        <GeneratorsMultiselect
                            syncSelectedList={this.syncSelectedList}
                            selectedGenerators={selectedGenerators}
                            generators = {generators}
                        />
                     </div>
                     <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="item">Item</label>
                            <input 
                                className="form-control" 
                                placeholder="Item"
                                ref="item"
                                value={item}
                                onChange={this.handleInput("item")}
                            />
                            <small className="form-text text-muted">e.g.: base_url</small>
                        </div>  
                        { selectedAction === "set" &&
                            <div className="form-group"> 
                                <label htmlFor="newValue">New value</label>
                                <input 
                                    className="form-control" 
                                    placeholder="Enter model name"
                                    ref="newValue"
                                    value={newValue}
                                    onChange={this.handleInput("newValue")}
                                />
                                <small className="form-text text-muted">e.g.: 4.2</small>
                            </div>
                        }

                </div>
                <div className="col-2">
                        <button className={"btn btn-" + buttonClass} style={applyButtonStyle} onClick={this.applyAction}>
                            {buttonContext}
                            {fetching && <span className="spinner-border spinner-border-sm" role="status" style={{margin: "0px 5px"}}/> }
                        </button>
                    </div>
                </div>

                {showResults && <ResultsTable results={results} handleHide={this.hideResults}/>}

            </div>
         );
    }
}

const applyButtonStyle = {
    margin: "15px 30px",
    padding: "auto 30px"
 }
 
export default GetSetItem;