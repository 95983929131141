import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import auth from './helpers/auth'
import axios from 'axios';
import qs from 'qs';

import ItemsPerFarm from './ItemsPerFarm'
import ReportSet from './charts/ReportSet'
import Spinner from './Spinner'
import GeneratorStatsTable from './GeneratorStatsTable';

class FarmsPerSite extends Component {

    state = {
        statistics: {
            detections: -1,
            deterrences: -1,
            shutdowns: -1
        },
        activeCount: -1,
        inactiveCount: -1,
        spinner: true,
    }

    componentDidMount() {
        if (this.props.item === 'reports') {
            this.fetchStatistics()
        }
    }

    fetchStatistics() {
        const { generators, date, eventTypes } = this.props
        let params = {}

        const genIds = generators.map(gen => gen.id)
        params["gen"] = genIds
        const fromDate = date[0].getFullYear() + "-" + (date[0].getMonth() + 1) + "-" + date[0].getDate()
        const toDate = date[1].getFullYear() + "-" + (date[1].getMonth() + 1) + "-" + date[1].getDate()
        const requestRoute = process.env.REACT_APP_API_URL + "events/statistics"

        let selectedTypes = []
        if (eventTypes['detection'])
            selectedTypes.push('detection')
        if (eventTypes['deterrence'])
            selectedTypes.push('deterrence')
        if (eventTypes['shutdown'])
            selectedTypes.push('shutdown')


        params["from"] = fromDate
        params["to"] = toDate
        params["eventtypes"] = selectedTypes

        axios.get(requestRoute, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(response => {
            const statistics = {
                detections: response.data.detections,
                deterrences: response.data.deterrences,
                shutdowns: response.data.shutdowns
            }
            this.setState({
                statistics: statistics
            })
        })
            .catch(error => {
                console.log("Got error on statistics (FarmsPerSite) request")
                console.log(error.response)
            })
        this.setState({
            activeCount: this.getActiveCount(),
            inactiveCount: this.getInactiveCount(),
            spinner: false
        })
    }

    getActiveCount() {
        return this.props.generators.filter(gen => gen.isActive === 1).length
    }

    getInactiveCount() {
        return this.props.generators.filter(gen => gen.isActive === 0).length
    }

    render() {

        const { site, item } = this.props
        if (item === 'reports') {
            const { spinner } = this.state
            if (spinner)
                return (
                    <Spinner />
                )
        }

        let results = <h2 className="no-data"> No wind farms found</h2>
        let generatorStatsTables;

        if (site.farms.length) {

            if (item === 'reports') {
                const { selectedFarms, eventTypes } = this.props
                const farmIds = selectedFarms.map(farm => farm.id)
                results =
                    <div className="row" >
                        {
                            site.farms.map((farm) =>
                                farmIds.includes(farm.id)
                                && <ItemsPerFarm farm={farm} item={item} key={farm.id} date={this.props.date} eventTypes={eventTypes} />
                            )
                        }
                    </div>
            }
            else {
                results = null;
                    // commented the below lines to remove the old views of generator
                    // <div className="row no-gutters" >
                    //     {
                    //         site.farms.map((farm) =>
                    //         <ItemsPerFarm farm={farm} item={item} key={farm.id} />
                                
                    //         )
                    //     }
                    // </div>
                
                // add generator stats table
                generatorStatsTables = 
                    <div className="row no-gutters" >
                    {
                        site.farms.map((farm) =>
                        <GeneratorStatsTable farm={farm} item={item} key={farm.id} />
                        )
                    }
                </div>
                    
            }
        }

        return (
            <div>
                <h4 style={titleStyle}>
                    <span style={spanStyle}>Site: </span>
                    <Link
                        to={"/sites/" + site.id}
                        className="black-link"
                        onClick={() => this.props.changeCurrentPlaceholder(
                            auth.role_id > 1 ? site.name : this.props.company_name
                        )}
                    >{site.name}
                    </Link>
                </h4>
                {item === 'reports' && <ReportSet statistics={this.state.statistics} active={this.state.activeCount} inactive={this.state.inactiveCount} />}
                {results}
                {generatorStatsTables}
                
            </div>
        )
    }
}

const titleStyle = {
    fontWeight: "200",
    fontSize: "2rem",
    // marginLeft: "2%",
    color: "#ffffff"
}

const spanStyle = { color: "#ffffff" }

export default FarmsPerSite