import React, { Component } from 'react'
import { Pie } from 'react-chartjs-2'

export class StatusPie extends Component {

    render() {
        const chartData = {
            labels: ['Active', 'Inactive'],
            datasets: [{
                data: [this.props.active, this.props.inactive],
                backgroundColor: ["#87abc9", "#ce8d8d"]
            }]
        }
        return (
            <div className="pie-hart">
                <Pie
                    data={chartData}
                    height={250}
                    width={180}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'right',
                            labels: {
                                fontColor: "#efefef"
                            }
                        },
                        title: {
                            display: true,
                            text: 'Generator Current Status',
                            lineHeight: 1.7,
                            fontColor: "#efefef"
                        },
                        layout: {
                            padding: {
                                left: 190,
                                right: 20,
                                top: 0,
                                bottom: 30
                            }
                        }
                    }}
                />
            </div>
        )
    }
}

export default StatusPie
