import React, { Component } from 'react'
import axios from 'axios'
import Spinner from '../../Spinner'
import { smoothToTop } from '../../helpers/misc'
import auth from '../../helpers/auth'
import Modal from 'react-modal'

import DetailsTable from './DetailsTable'
import ImagePreview from './ImagePreview'
import ClipPreview from './ClipPreview'


export class EventDetails extends Component {
    /**
     * Displays the details of the event page
     */
    state = {
            eventID: this.props.match.params.id,
            event: {},
            spinner: true,
            error: false,
            errorLog: "",
            event_spin: false
        }
    /**
     * gets the event from the database
     */
    componentDidMount(){
        smoothToTop();
        document.title = "BMS App - CCTV Event " + this.state.eventID
        const eid = this.state.eventID
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.get(process.env.REACT_APP_API_URL + 'cctv_events/' + eid, config)
            .then(response => {
              console.log( response.data );
                let event = response.data
                this.setState({
                    event: event,
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    spinner: false,
                    error: true,
                    errorLog: error.response.data.error,
                })
            });
    }

    handleOpenModal = () => {
        this.setState({coefs_modal: true})
    }

    handleCloseModal = () => {
        this.setState({coefs_modal: false})
    }



    /**
     * Renders the event details page
     */
    render() {
        Modal.setAppElement("#root")
        const { spinner, eventID, event, event_spin  } = this.state
        const { error, errorLog } = this.state
        let header, body     
        if ( spinner )
            return (
                <Spinner />
            )
                

        header = 
                <div style={{display: "inline"}}>
                    <h1 className="my-title"> CCTV Event <strong>#{eventID}</strong> </h1>
                    <hr className="my-Hr" />
                </div>
        if (error){
            body = <p className="text-center">{errorLog}</p>
        }
        else {
            body = 
                <div>
                    <DetailsTable event={event}/>
                    {(event.image_url != "") && <ImagePreview imageUrl={event.image_url}/>}
                    {(event.video_url != "") && <ClipPreview videoUrl={event.video_url}/>}
                    
                    <div style={{marginBottom: "300px"}}/>
                </div>
        }
                
        
        return (
            <div>
                {header}
                {body}
            </div>
                
        )
    }
}

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: { zIndex: 20000 }
}


export default EventDetails