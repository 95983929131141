import React, { Component } from 'react'

export class LittleSpinner extends Component {
    /**
     * just a little cute spinner
     */
    render() {
        return (
            <div className={`spinner-border my-little-spinner spinner-border-sm ${this.props.className}`} role="status" style={this.props.style}>
                <span className="sr-only">Loading...</span>
            </div>
        )
    }
}

export default LittleSpinner
