import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';


export class ToBeAnnotatedCheckbox extends Component {

    render() {
        const {checked, spinner} = this.props
        return (
            <div>

                <FormControlLabel
                    value="To Be Annotated"
                    control={<Checkbox color="primary" /> }
                    label="To Be Annotated"
                    labelPlacement="end"
                    onChange={()=> this.props.ToggleToBeAnnotated()}
                    checked= {checked == 1}
                >
                </FormControlLabel>
                {spinner &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div> }


            </div>
                
        )
    }
}


export default ToBeAnnotatedCheckbox
