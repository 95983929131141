import React, { Component } from 'react'
import Events from '../Events'

export class DetailsTable extends Component {
    /**
     * displays tables with information about the event
     */
    render() {
        const event = this.props.event
        // const track = event.event_type === "deterrence" ? "BMS-Warning.mp3" : "-"

        return (
            <div style={{ "marginTop": "10px", "width": "100%" }}>
                <h2 className="my-title2" style={{ paddingLeft: "10px" }}>Details</h2>
                <div className="table-responsive">
                    <table className="table events-table">
                        <tbody style={wordBreak}>
                            <tr style={normalBreak}>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Company</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Site</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Farm</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Generator</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Time</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left" >Type</th>
                            </tr>
                            <tr>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Company:&nbsp;
                                    </span>
                                    {event.company.name}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Site:&nbsp;
                                    </span>
                                    {event.generator.farm.site.name}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Farm:&nbsp;
                                    </span>
                                    {event.generator.farm.name}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Generator:&nbsp;
                                    </span>
                                    {event.generator.name}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Timestamp:&nbsp;
                                    </span>
                                    {event.StartTime}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Type:&nbsp;
                                    </span>
                                    {event.Type}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h2 className="my-title2" style={{ paddingLeft: "10px" }}>More Details</h2>
                <div className="table-responsive">
                    <table className="table events-table">
                        <tbody style={wordBreak}>
                            <tr style={normalBreak}>
                                <th className="d-none d-md-table-cell text-lg-center text-left">Duration <br /><small>(seconds)</small></th>
                                <th className="d-none d-md-table-cell text-lg-center text-left">Start Time</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left">End Time</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left">Length</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left">Event</th>
                                <th className="d-none d-md-table-cell text-lg-center text-left">Flag</th>

                            </tr>
                            <tr>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Duration <small>(seconds)</small>:&nbsp;
                                    </span>
                                    {event.Duration}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Start Time:&nbsp;
                                    </span>
                                    {event.StartTime}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        End Time:&nbsp;
                                    </span>
                                    {event.EndTime}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Length:&nbsp;
                                    </span>
                                    {event.Length}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Event:&nbsp;
                                    </span>
                                    {event.Events_0} - {event.Events_1}</td>
                                <td className=" text-lg-center text-left">
                                    <span className="d-lg-none font-weight-bold">
                                        Flag:&nbsp;
                                    </span>
                                    {event.Flags_0}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
const normalBreak = {
    wordBreak: "normal",
    backgroundColor: "#6ABBF1"
}

const wordBreak = {
    wordBreak: "break-word"
}

export default DetailsTable
