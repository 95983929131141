import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class CompanyCard extends Component {

    /**
     * Renders a card that contains details about a company
     * The card contains in details:
     *  - company name
     *  - company description
     *  - link for more details
     *  - summary for sites / farms / generators
     */
    render() {
        const { company } = this.props
        let admin_info = ''
        if ( company.admins.length )
            admin_info = <div><br />Administrator: {company.admins[0].first_name + " " + company.admins[0].last_name}</div>

        return (
            <div style={{ width: "100%" }}>
                <div style={cardStyle}>
                    <div className="container-fluid" >
                        <div className="row">
                            <div className="col-3" style={iconColumn}>
                                <br />
                                <Link to={"/company_profile/" + company.id}>
                                    <img src={process.env.PUBLIC_URL + '/default_company_logo.png'}
                                        alt="Company logo"
                                        style={imgStyle}
                                    />
                                </Link>
                            </div>

                            <div className="col">
                                <h4><Link to={"/companies/" + company.id}>{company.name}</Link></h4>
                                <p className="d-none d-xl-inline">{company.description}</p>
                                {admin_info}
                                <p className="small"><br/><Link to={"/company_profile/" + company.id}>Details</Link></p>
                            </div>
                        </div>
                        <hr className="my-hr" />
                        <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-sm-4" style={{ wordWrap: "normal" }}>
                                <p>Sites</p>
                                <p>{company.sites_num}</p>
                            </div><div className="col-sm-4">
                                <p>Farms</p>
                                <p>{company.farms_num}</p>
                            </div><div className="col-sm-4">
                                <p>Generators</p>
                                <p>{company.generator_num}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const iconColumn = {
    padding: "0",
    minWidth: "140px",
    textAlign: "center"
}

const cardStyle = {
    marginBottom: "5%",
    border: "1px solid",
    borderColor: "transparent",
    borderWidth: "15px 7px 15px 7px",
    borderRadius: "5px",
    boxShadow: "rgba(0.2, 0, 0, 0.2) 0px 3px 4px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
}

const imgStyle = {
    border: "1px solid",
    borderRadius: "50%",
    borderColor: "transparent",
    width: "120px",
    height: "120px",
    maxWidth: "100%",
    minWidth: "120px"
}

export default CompanyCard
