import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { Route } from 'react-router';
import axios from 'axios'


import Sidebar from './Sidebar'
import CamerasGrid from '../pages/CamerasGrid'
import GeneratorsGrid from '../pages/GeneratorsGrid'
import Users from '../pages/Users'
import Events from '../pages/Events'
import UploadAnnotations from '../pages/UploadAnnotations'
import Reports from '../pages/Reports'
import Overview from '../pages/Overview'
import CameraDetails from '../pages/CameraDetails'
import CameraStreamingDetails from '../pages/CameraStreamingDetails'

import AdminDashboard from '../pages/AdminDashboard';
import NotExist from '../pages/NotExist';
import EventDetails from '../pages/EventDetails/EventDetails';
import GeneratorDetails from '../pages/GeneratorDetails'
import UserProfile from '../pages/UserProfile'
import CompanyForm from '../pages/RegisterCompany/CompanyForm';
import CompanyProfile from '../pages/CompanyProfile';
import RegisterUser from '../pages/RegisterUser';
import RegisterAdmin from '../pages/RegisterAdmin';
import RegisterSite from '../pages/RegisterSite';
import RegisterFarm from '../pages/RegisterFarm';
import CompanyData from '../pages/CompanyData';
import ConfigControl from '../pages/ConfigControl/ConfigControl';
import GeneratorConfigControl from '../pages/ConfigControl/GeneratorConfigControl';
import SiteData from '../pages/SiteData';
import "../../Layout.css";
import auth from '../helpers/auth'
import NoMatchPage from '../NoMatchPage';

import TestPage from '../pages/TestPage'
import UploadView from '../pages/UploadView/UploadView'

import Stats from '../pages/Stats'
import CCTVEvents from '../pages/CCTVEvents'
import CCTVEventDetails from '../pages/CCTVEventDetails/EventDetails';
import Gps from '../pages/Gps';
import HealthStatus from '../pages/HealthStatus';
import GeneratorAlgorithmSettings from '../pages/GeneratorAlgorithmSettings';



export class MainContent extends Component {

    state = {
        hideSiderbar: false,
        currentPlaceHolder: "All",
        currentLocation: "",
        dropDownContent: auth.role_id <= 1 ? "companies" : "sites",
        dropDownData: [],
        dropDownSync: true,
        dropDownSelectedValue: "all"
    }
    handleMenuToggle = () => {
        this.setState({ hideSiderbar: !this.state.hideSiderbar });
    };

    selectOnDropDown = (value) => {
        if (value === "All")
            this.setState({
                currentPlaceHolder: "All",
                dropDownSelectedValue: "All"
            })
        else
            this.setState({
                currentPlaceHolder: value.name,
                dropDownSelectedValue: value
            })
    }

    changeCurrentLocation = (value) => {
        this.setState({ currentLocation: value })
    }

    changeCurrentPlaceholder = (value) => {
        let newitem;
        for (let i = 0; i < this.state.dropDownData.length; i++) {
            if (this.state.dropDownData[i].name === value) {
                newitem = this.state.dropDownData[i];
            }
        }
        this.setState({
            currentPlaceHolder: value,
            dropDownSelectedValue: newitem,
        })
    }
    setDropDownSync = (flag) => {
        if (flag)
            this.getDropDownContents()
        // this.setState({dropDownSync: flag})
    }
    getDropDownContents = () => {
        const config = {
            headers: { Authorization: 'Bearer ' + auth.token }
        };
        console.log("Requesting for dd_data at: " + process.env.REACT_APP_API_URL + this.state.dropDownContent)
        axios.get(process.env.REACT_APP_API_URL + this.state.dropDownContent, config)
            .then(response => {
                this.setState({ dropDownData: response.data, dropDownSync: true })
            })
            .catch(error => {
                console.log("ERROR: got error on getting dd_data")
                console.log(error)
            }
            );
    }
    resetDropDown = () => {
        this.setState({
            currentPlaceHolder: "All",
            currentLocation: "overview"
        })
    }

    render() {
        let dashboard;
        const { hideSiderbar, currentPlaceHolder, dropDownSync,
            dropDownData, dropDownContent, currentLocation,
            dropDownSelectedValue } = this.state;
        if (auth.role_id <= 1)
            dashboard = <Route path="/dashboard" component={AdminDashboard} />
        else
            dashboard = <Route path="/dashboard" component={Overview} />
        return (
            <div className={`d-flex ${hideSiderbar ? "toggled" : ""}`} id="wrapper" style={marginTop}>
                <Sidebar
                    changePlaceHolder={this.changeCurrentPlaceholder}
                    getDropDownContents={this.getDropDownContents}
                    setDropDownSync={this.setDropDownSync}
                    selectOnDropDown={this.selectOnDropDown}
                    changeCurrentLocation={this.changeCurrentLocation}
                    currentPlaceHolder={currentPlaceHolder}
                    dropDownSync={dropDownSync}
                    dropDownData={dropDownData}
                    dropDownContent={dropDownContent}
                    currentLocation={currentLocation}
                    dropDownSelectedValue={dropDownSelectedValue}
                />

                {/* content */}
                <div className="container-fluid no-gutters p-0 overflow-hidden" >
                    <div className="row no-gutters">
                        <button id="menu-toggle" onClick={this.handleMenuToggle.bind(this)} style={{ position: "fixed" }}>
                            <FontAwesomeIcon icon={hideSiderbar ? faToggleOff : faToggleOn} id="icon" />
                        </button>
                    </div>
                    <div style={mainStyle}>
                        {/* Comment that on Production */}
                        <Route path="/testpage" component={TestPage} />
                        <Route path="/config-control" component={ConfigControl} />
                        <Route path="/generator-config-control" component={GeneratorConfigControl} />

                        <Route path="/upload-files" component={UploadView} />

                        <Route exact path="/cameras" render={(props) => (
                            <CamerasGrid changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />

                        <Route exact path="/cameras/:id" component={CameraDetails} />
                        <Route exact path="/cameras/:id/streaming-info" component={CameraStreamingDetails} />

                        <Route exact path="/generators" render={(props) => (
                            <GeneratorsGrid changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />
                        <Route path="/generators/:id" component={GeneratorDetails} />
                        <Route path="/users" component={Users} />
                        <Route path="/upload-annotations" component={UploadAnnotations} />
                        <Route exact path="/events" component={Events} />
                        <Route exact path="/cctv_events" component={CCTVEvents} />
                        <Route path="/cctv_events/:id/details" component={CCTVEventDetails} />
                        <Route exact path="/stats" component={Stats} />
                        <Route path="/reports" component={Reports} />
                        <Route exact path="/gps" component={Gps} />
                        <Route exact path="/health-status" component={HealthStatus} />
                        <Route exact path="/algorithm-settings" component={GeneratorAlgorithmSettings} />
                        {dashboard}
                        {auth.role_id <= 1 && <Route exact path="/companies/:id/dashboard" render={(props) => (
                            <AdminDashboard key={props.match.params.id} {...props} />)
                        } />}
                        <Route path="/notexist" component={NotExist} />
                        <Route path="/events/:id/details" component={EventDetails} />
                        <Route exact path="/overview" render={(props) => (
                            <Overview changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />
                        <Route path="/user_profile" component={UserProfile} />

                        <Route exact path="/company_profile" component={CompanyProfile} />
                        <Route path="/company_profile/:id" component={CompanyProfile} />

                        <Route exact path="/sites/:id" render={(props) => (
                            <SiteData key={props.match.params.id} {...props} />)
                        } />

                        <Route exact path="/companies/:id/registeradmin" render={(props) => (
                            <RegisterAdmin key={props.match.params.id} {...props} />)
                        } />
                        {/* <Route path="/registeruser/:id" component={RegisterUser} /> */}
                        {/* <Route path="/registerornithologist/:id" component={RegisterUser} /> */}
                        <Route exact path="/registeruser/:id" render={(props) => (
                            <RegisterUser key={props.match.params.id} registerType={"user"} {...props} />)
                        } />
                        <Route exact path="/registerornithologist/:id" render={(props) => (
                            <RegisterUser key={props.match.params.id} registerType={"ornithologist"} {...props} />)
                        } />

                        <Route path="/sites/:id/registerfarm/" component={RegisterFarm} />
                        <Route exact path="/registersite/:id" render={(props) => (
                            <RegisterSite key={props.match.params.id} setDropDownSync={this.setDropDownSync} {...props} />)
                        } />
                        <Route exact path="/registercompany" render={(props) => (
                            <CompanyForm setDropDownSync={this.setDropDownSync} {...props} />)
                        } />

                        {/* new paths for sidebar support */}
                        <Route exact path="/companies/:id" render={(props) => (
                            <CompanyData key={props.match.params.id} changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />
                        <Route exact path="/companies/:id/generators" render={(props) => (
                            <GeneratorsGrid key={props.match.params.id} specified={"companies"} changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />
                        <Route exact path="/sites/:id/generators" render={(props) => (
                            <GeneratorsGrid key={props.match.params.id} specified={"sites"} changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />
                        <Route exact path="/companies/:id/cameras" render={(props) => (
                            <CamerasGrid key={props.match.params.id} specified={"companies"} changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />
                        <Route exact path="/sites/:id/cameras" render={(props) => (
                            <CamerasGrid key={props.match.params.id} specified={"sites"} changeCurrentPlaceholder={this.changeCurrentPlaceholder} {...props} />)
                        } />
                        <Route exact path="/companies/:id/users" render={(props) => (
                            <Users key={props.match.params.id} {...props} />)
                        } />

                        <Route path='/404' component={NoMatchPage} />
                        {/* <Redirect to="/404"/> */}

                    </div>

                    <div style={{ marginBottom: "10%" }} /> {/**just for same space **/}
                </div>
            </div>
        )
    }
}

const marginTop = {
    marginTop: "90px"
}

const mainStyle = { margin: "0" }

export default MainContent
