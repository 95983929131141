import React, { Component } from 'react'
import axios from 'axios'


class ForgotPassowrd extends Component {
	/**
	 * Hides the navigation bar
	 */
	componentDidMount(){
        document.getElementById('navigation-bar').style.display = "none";
    }

	/**
	 * Displays the navigation bar
	 */
    componentWillUnmount(){
        document.getElementById('navigation-bar').style.display = "flex";
    }

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			spinner: false,
		}
	}

	/**
	 * Sumbit the form with the given email
	 * @param {object} e 
	 */
	onSubmit(e) {
		e.preventDefault();
		const { email } = this.state;
		this.setState({ spinner: true })
		axios.post(process.env.REACT_APP_API_URL + 'password/email', {
			headers: {
				'Access-Control-Allow-Origin': '*',
			},
			"email": email,
		})
			.then(response => {
				this.setState({ err: false, spinner: false });
			})
			.catch(error => {
				this.setState({ err: true, spinner: false });
				console.log(error)
				this.refs.email.value = "";
			});
	}

	/**
	 * Syncs the state with the input value
	 * @param {object} e 
	 */
	onChange(e) {

		const email = e.target.value;
		this.setState({ email: email });
	}

	/**
	 * displays the forgot password form
	 */
	render() {
		let error = this.state.err;
		let msg = (!error) ? 'We have e-mailed your password reset link!' : 'User doesn\'t exist';
		let name = (!error) ? 'alert alert-success' : 'alert alert-danger';
		let spinner = ""
		if (this.state.spinner) {
			spinner =
				<div className="spinner-border spinner-border-sm text-primary" role="status" style={spinnerStyle}>
					<span className="sr-only">Loading...</span>
				</div>
		}

		return (
			<div className="limiter" style={loginImg}>
			<div className="container-login100" style={loginImg}>
			<div className="wrap-login100" >
				<div className="container">
					<div className="row">
						<div className="col mx-auto">
							<span className="login100-form-title p-b-34 p-t-27">
								Reset Password
                            </span>
							<div >
								{error !== undefined && <div className={name} role="alert">{msg}</div>}
							</div>
							<form className="form-horizontal login100-form validate-form" method="POST" onSubmit={this.onSubmit.bind(this)}>
								<div className="form-group">
									{/* <label htmlFor="email" className="col-md-4 control-label">E-Mail Address</label> */}

									<div >
										<input
											id="email"
											type="email"
											ref="email"
											className="form-control input100"
											name="email"
											onChange={this.onChange.bind(this)}
											value={this.state.email}
											placeholder="Email"
											required
										/>
									</div>
								</div>

								<div className="form-group">
									<div className="container-login100-form-btn">
										<button type="submit" className="login100-form-btn" value={this.state.email} disabled={this.state.spinner}>
											Send Password Reset Link
										</button>
										{spinner}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			</div>
			</div>

		)
	}
}

const spinnerStyle = {
	width: "1.2rem",
	height: "1.2rem",
	marginLeft: "5px"
}

const loginImg = {
    backgroundImage: "url(bms_landing_2.jpg)"
}


export default ForgotPassowrd
