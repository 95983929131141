import React, { Component } from 'react'
import LittleSpinner from '../LittleSpinner'
import Spinner from '../Spinner'
import Filters from '../Filters';
import axios from 'axios';
import qs from 'qs';
import auth from '../helpers/auth'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

export class HealthStatus extends Component {
  state = {
    spinner: false,
    healthStatusData: [],
    reloadingGeneratorIds: []
  }
  componentDidMount() {
    // smoothToTop() //Commented for "show more details" rerendering
    document.title = "BMS App - Wind Turbine Health Status"

    //set the first value from the defaults
  }
  removeGeneratorFromFilter(removedItem) {
    let newHealthStatusData = this.state.healthStatusData;
    newHealthStatusData = newHealthStatusData.filter(gen => gen.generator.id !== removedItem.id)
    this.setState({
      healthStatusData: newHealthStatusData
    });
  }
  refreshHealthStatus(generator) {
    let newReloadingGeneratorId = this.state.reloadingGeneratorIds;
    newReloadingGeneratorId.push(generator.id);
    this.setState({ reloadingGeneratorIds: newReloadingGeneratorId });
    this.getGeneratorHealthData(generator.id, true);
  }
  getGeneratorHealth(selectedCompanies, selectedSites, selectedFarms, selectedGenerators) {
    this.setState({
      spinner: true
    })

    let generator_id = selectedGenerators[selectedGenerators.length - 1].id;
    this.getGeneratorHealthData(generator_id);

  }
  getGeneratorHealthData(generator_id, reload) {
    const requestRoute = process.env.REACT_APP_API_URL + "generators/" + generator_id + "/health-status";

    let myPagingControl = {};
    axios.get(requestRoute, {
      headers: { Authorization: `Bearer ${auth.token}` }
    }).then(response => {
      let newHealthStatusData = this.state.healthStatusData;

      // events.map(event => event['generator'] = this.state.selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
      let newReloadingGeneratorIds = this.state.reloadingGeneratorIds;
      if (reload) {
        newHealthStatusData = newHealthStatusData.map((gen) => {
          if (gen.generator.id == generator_id) {
            gen = response.data;
          }
          return gen;
        });
        newReloadingGeneratorIds = newReloadingGeneratorIds.filter(genId => genId !== generator_id)
      } else {
        newHealthStatusData.push(response.data)
      }
      this.setState({
        spinner: false,
        healthStatusData: newHealthStatusData,
        pagingControl: myPagingControl,
        reloadingGeneratorIds: newReloadingGeneratorIds
      })
    })
      .catch(error => {
        // this.setAlert("danger", "Errorfull request")
        console.log("Generator Not reachable")
        console.log(error.response)
        this.setState({
          spinner: false,
        })
      })
  }
  render() {
    const { spinner, healthStatusData, reloadingGeneratorIds } = this.state;
    let results = ""
    console.log(reloadingGeneratorIds);
    results = <div>{
      healthStatusData.map((data, index) =>
        <div key={index} >
          {data.generator.name ?
            <h3 >Wind Turbine Name: {data.generator.farm.site.name} >> {data.generator.farm.name} >> {data.generator.name}
              {reloadingGeneratorIds.includes( data.generator.id) && <div  style={{ float:"right" }} ><LittleSpinner/></div>}
              {!reloadingGeneratorIds.includes(data.generator.id) &&
                <button style={{ float: "right" }} onClick={() => this.refreshHealthStatus(data.generator)}>
                  <FontAwesomeIcon style={{ marginLeft: "10px" }} className="text-light" icon={faSyncAlt} size={"xs"} />
                </button>
              }
            </h3>
            : ""
          }

          {data.statuses.length > 0 ?
            <div>
              <table className="table">
                <tbody>
                  {data.statuses.map((status, statusIndex) =>
                    <tr key={statusIndex}>
                      <td className="status-name" style={{ width: "50%" }}>{status.name}</td>
                      <td className="status-value">{status.value}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <br />
              <br />
            </div>
            : <div>Error Reaching Generator</div>

          }
        </div>
      )

    }
    </div>
    return (
      <div style={{ "marginTop": "10px", "width": "100%" }} className="container-inner-wrapper">
        <h2 className="my-title2">Health Stauts page </h2>
        <hr />
        <Filters showGenerators="{true}" onGeneratorRemove={(removedItem) => { this.removeGeneratorFromFilter(removedItem) }} onGeneratorSelect={(c, s, f, g) => { this.getGeneratorHealth(c, s, f, g) }} />
        {results}
        {spinner && <Spinner />}
      </div>
    )
  }
}
const normalBreak = {
  wordBreak: "normal",
  backgroundColor: "#f4f4f6"
}

const wordBreak = {
  wordBreak: "break-word"
}
const titleStyle = {
  fontWeight: "200",
  fontSize: "2rem",
  color: "#000",
  padding: "20px",
  backgroundColor: "#31628A",
  width: "auto",
  maxWidth: "400px",
  height: "auto",
  color: "#ffffff",
  marginBottom: "30px"
}

export default HealthStatus