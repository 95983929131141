import React, { Component } from 'react'

export class NoMatchPage extends Component {
    /**
     * page that is displayed if the route is unknown
     */
    render() {
        return (
            <div className='text-center' style={{marginTop: "20%"}}>
                <div> 
                    <span style={codeStyle}>404</span> <span style={errStyle}> Page not found </span>
                
                </div>
            </div>
        )
    }
}

const codeStyle = {
    borderRight: "3px solid black",
    fontSize: "50px",
    paddingRight:"4px",
}


const errStyle = {
    fontSize: "25px",
    paddingLeft:"4px",
}

export default NoMatchPage
