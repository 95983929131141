import React, { Component } from 'react'
import UploadModel from './UploadModel'
import UploadSetup from './UploadSetup'
import S3Forms from './S3Forms'

import auth from '../../helpers/auth'

import { smoothToTop } from '../../helpers/misc';


export class UploadView extends Component {
    state = {
        alert: {
            appear: false,
            status: "primary",
            message: "Nice work"
        }
    }

    componentDidMount() {
        document.title = "BMS App - Uploads"
    }

    setAlert = (status, message) => {
        console.log("new alert")
        this.setState({
            alert: {
                appear: true,
                status: status,
                message: message
            }
        }, () => {
            setTimeout(() => {
                this.setState({
                    alert: {
                        appear: false,
                        status: "",
                        message: ""
                    }
                })
            }, 3000)
            smoothToTop()
        }
        );
    }
    render() {
        const { alert } = this.state
        if (auth.role_id > 1)
            return <div> <p>Unauthorized Content</p> </div>

        return (
            <div className="container-inner-wrapper">
                <h1 className="my-title"> Uploads View </h1>
                <hr className="my-Hr" />

                {alert.appear && <div className={"alert alert-" + alert.status + " text-center"} style={{ margin: "15px 20%" }} role="alert">{alert.message}</div>}


                <div className="row">
                    <div className="col" style={colStyle}>
                        <UploadModel setAlert={this.setAlert} />
                    </div>
                    <div className="col" style={colStyle}>
                        <UploadSetup setAlert={this.setAlert} />
                    </div>
                </div>

                <S3Forms setAlert={this.setAlert} />

            </div>
        )
    }
}


const colStyle = {
    border: "solid 2px #18baba",
    borderRadius: "15px",
    padding: "20px 20px",
    margin: "20px 50px"
}
export default UploadView
