import React, { Component } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import { ProgressBar } from 'react-bootstrap'
import auth from '../../helpers/auth'
import axios from 'axios'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


export class UploadModel extends Component {
    state ={
        uploadPercentage: 0,
        modelDescription: "",
        cameraType: "RGB"

    }
    setUploadFile = ({target: { files } }) => {
        this.setState({
            file: files[0]
        })
    }

    uploadFile = () => {
        const {file, modelDescription, cameraType } = this.state
        let data = new FormData();
        data.append('file', file)
        data.append('description', modelDescription)
        data.append('camera_type', cameraType)
        
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor((loaded * 100) / total)
                console.log( loaded + "Kb of " + total + "Kb | " + percent + "%")
                if(percent < 100 ){
                    this.setState({
                        uploadPercentage: percent
                    })
                }
            }
        }
        axios.post(process.env.REACT_APP_API_URL + 'detection_model', data, config)
        .then(res => {
            console.log(res)
            this.setState({
                uploadPercentage: 100
            }, () => {
                this.setState({
                    uploadPercentage: 0,
                    modelDescription: "",
                    file: {}
                })
                this.props.setAlert("success", "Upload Completed")

            })
        }).catch(error => {
            console.log("ERROR: got error")
            console.log(error)
            console.log("===>", error.response.data.error)
            this.props.setAlert("danger", "Got Error on Upload. Info: Read console")
        });
    }
    onChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }
    submitModel = () => {
        this.uploadFile()
    }
    
    render() {
        const { uploadPercentage, modelDescription, cameraType } = this.state;
        return (
            <div className="text-center">
                <h2 style={{fontWeight: "300", fontSize: "1.7rem"}}><FontAwesomeIcon icon={faCloudUploadAlt} size={"xs"} style={{marginRight: "10px"}}/>Model</h2>

                <div style={{margin: "20px 0"}}>
                    <input type="file" style={fileUploaderStyle} className="text-center" onChange={this.setUploadFile}/>
                </div>

                <div style={{margin: "20px 0"}}>
                    <FormControl component="fieldset">
                    <FormLabel component="legend">Camera Type</FormLabel>
                    <RadioGroup aria-label="cameraType" name="cameraType" value={cameraType} onChange={this.onChange}>
                        <FormControlLabel value="RGB" control={<Radio color="primary"/>} label="RGB" />
                        <FormControlLabel value="Thermal" control={<Radio color="primary"/>} label="Thermal" />
                    </RadioGroup>
                    </FormControl>
                </div>

                <div style={{margin: "20px 0"}}>
                    <FormLabel component="legend">Write a Description</FormLabel>
                    <textarea
                        style = {descreptionStyle}
                        name="modelDescription"
                        onChange={this.onChange}
                        value={modelDescription}
                        rows={3}
                        cols={30}
                    />
                </div>


                <button className="btn btn-primary" onClick={this.submitModel}> Submit</button>

                {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={uploadPercentage + "%"} />}
            </div>
        )
    }
}
const fileUploaderStyle = {
    border: "solid 1px #999",
    borderRadius: "5px",
    padding:"20px 20px",
    margin: "20px 0"
}

const descreptionStyle = {
    border: "solid 1px #999",
    borderRadius: "5px",

}

export default UploadModel
