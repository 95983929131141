import React, { Component } from 'react'
import axios from 'axios'
import auth from './helpers/auth'
import Spinner from './Spinner'
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faFire } from "@fortawesome/free-solid-svg-icons";



export class CCTVEventVideoModal extends Component {

    state = {
        // view percentage length
        viewWidth: 0.8,
        spinner: true,
    }

    /**
     * On mount of the component a get request is done in order to get the clips that
     * can be displayed for the event
     */
    componentDidMount() {
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        let eid = this.props.eventid
        const url = process.env.REACT_APP_API_URL + 'cctv_events/' + eid + '/video'
        axios.get(url, config)
            .then(response => {
                console.log("event-modal resp:: ", response.data)
                const event = response.data.event
                const video_url = response.data.video_url
                this.setState({
                    eventid: eid,
                    event: event,
                    video_url: video_url,
                    error: false,
                    spinner: false
                })
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                this.setState({
                    eventid: eid,
                    error: true,
                    errorLog: error.response.data.error,
                    spinner: false
                })
            });
    }
    /**
     * Starts a download with the video
     * @param {string} clipname 
     * @param {string} s3Url 
     */
    downloadVideoClip = (s3Url) => {
        const link = document.createElement('a');
        link.href = s3Url;
        link.setAttribute('download', "cctv_video.dav"); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    /**
     * Renders the content of the modal
     */
    render() {
        const { spinner, eventid, video_url, error } = this.state
        const evt = this.state.event

        if (spinner)
            return <Spinner />

        let modalBody
        if (!error) {
            modalBody =
                <div className="text-center" style={modalBodyStyle}>
                    <div className="table-responsive">
                        <table className="table events-table" style={{ width: String(this.state.viewWidth * 100) + "%", textAlign: "center", margin: "0 auto" }}>
                            <tbody>
                                <tr style={bgColor}>
                                    <th className="d-none d-md-table-cell">Generator</th>
                                    <th className="d-none d-md-table-cell">Start Time</th>
                                    <th className="d-none d-md-table-cell">End Time</th>
                                    <th className="d-none d-md-table-cell">Duration</th>
                                    <th className="d-none d-md-table-cell">Length</th>
                                </tr>
                                <tr>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Generator: </span>{evt.generator.name}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Start Time: </span>{evt.StartTime}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">End Time: </span>{evt.EndTime}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Duration: </span>{evt.Duration}</td>
                                    <td className="text-lg-center text-left"><span className="d-lg-none">Length: </span>{evt.Length}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ margintTop: "25px" }}>
                        <ReactPlayer url={video_url} width="50%" height="50%" style={videoPlayerStyle} controls={true} />
                    </div>
                    <p> To Download Video click <button className="btn btn-info" onClick={() => this.downloadVideoClip(video_url)}>here</button></p>

                </div>
        }
        else
            modalBody =
                <div style={modalBodyStyle}>
                    <p>{this.state.errorLog}</p>
                </div>
        return (
            <div className="text-center modalWindow" style={{ marginBlock: "50px" }}>
                <h1 className="my-title-centered"> CCTV Event #{eventid ? eventid : " - "} </h1>
                <hr styel={{ margin: "5% 0" }} />
                {modalBody}
                <button className="btn btn-danger" onClick={() => this.props.onRequestClose()}> Close </button>
            </div>
        )
    }
}


const modalBodyStyle = {
    marginTop: "50px",
}
const bgColor = {
    backgroundColor: "#f4f4f6"
}
const videoPlayerStyle = {
    marginBottom: "25px",
    marginTop: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#000"
}

export default CCTVEventVideoModal
