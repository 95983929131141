import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import axios from 'axios'

import auth from '../../helpers/auth'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faEdit, faSave, faWindowClose, faSpinner } from "@fortawesome/free-solid-svg-icons";
import LittleSpinner from '../../LittleSpinner';



export class GetGeneratorConfig extends Component {

    state = {
        selectedGenerator: "",
        fetchedConfigId: "",
        config: null,
        fetching: false,
        alert: {
            visible: false,
            status: "",
            msg: ""
        }
    }

    handleChangeGenerator = (event) => {
        const selectedGenerator = event.target.value
        this.setState({
            selectedGenerator: selectedGenerator
        });
    }


    fetchConfig = () => {
        const { selectedGenerator, fetchedConfigId } = this.state
        if (selectedGenerator == "")
            return;

        // dont fetch again the same
        if (fetchedConfigId == selectedGenerator) {

            return
        }

        console.log("will fetch CONFIG for ", selectedGenerator)
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        this.setState({
            fetching: true
        })
        const url = process.env.REACT_APP_API_URL + 'generators/' + selectedGenerator + '/config_file'
        axios.get(url, config)
            .then(response => {
                this.setState({
                    fetchedConfigId: selectedGenerator,
                    config: response.data,
                    fetching: false
                })
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                this.setAlert("danger", "Error: " + error.response.data['error'])
                this.setState({
                    fetching: false
                })

            });
    }
    updateConfig(config_keys, updatedValue) {
        const { config } = this.state;
        console.log('update config log');
        console.log(config_keys);
        if (config_keys.length == 1) { // level 1 key config item
            config[config_keys[0]] = updatedValue;
        }
        else if (config_keys.length == 2) { // level 2 key config item
            config[config_keys[0]][config_keys[1]] = updatedValue;
        }
        else if (config_keys.length == 3) { // level 3 key config item
            config[config_keys[0]][config_keys[1]][config_keys[2]] = updatedValue;
        }

        this.setState({ config });
    }
    setAlert = (status, message) => {
        this.setState({
            alert: {
                visible: true,
                status: status,
                msg: message
            }
        }, () => setTimeout(() => {
            this.setState({
                alert: {
                    visible: false,
                    status: "",
                    msg: ""
                }
            })
        }, 5000)
        )
    }

    render() {

        const { generators } = this.props
        const { selectedGenerator, fetching, config, alert } = this.state
        let config_data_table = "";
        if (config !== null) {
            config_data_table = Object.keys(config).map(key => {
                let firstLevel = config[key];
                let uniqueKey1 = key;
                if (typeof (firstLevel) === 'object') {
                    let cnt2 = 0;
                    return Object.keys(firstLevel).map(key1 => {
                        let secondLevel = firstLevel[key1];
                        let uniqueKey2 = key + "-" + key1;
                        if (typeof (secondLevel) === 'object') {
                            let cnt3 = 0;
                            return Object.keys(secondLevel).map(key2 => {
                                let thirdLevel = secondLevel[key2];
                                let uniqueKey3 = key + "-" + key1 + "-" + key2;
                                let uniqueKey1Heading = uniqueKey1 + "-" + cnt2;
                                let uniqueKey2Heading = uniqueKey2 + "-" + cnt3;
                                cnt3++;
                                let editable = true;
                                if (key == "images") { // images has to be handled differently
                                    var config_ref_name = "images/" + firstLevel[key1].name + "/" + key2;
                                    editable = key2 !== "name";
                                } else {
                                    var config_ref_name = "nested/" + key + "/" + key1 + "/" + key2

                                }
                                return (cnt3 == 1) ?
                                    (
                                        <div key={uniqueKey1Heading} >
                                            <div className='row'>
                                                <div style={level1Heading} className='col-12'>{key}</div>
                                            </div>
                                            <div className='row'>
                                                <div style={level2Heading} key={uniqueKey2Heading} className='col-12'>{key1}</div>
                                            </div>
                                            <GetGeneratorConfigRow updateConfig={this.updateConfig.bind(this)} editable={editable} generator={selectedGenerator} style={level3Style} key={uniqueKey3} config_keys={uniqueKey3} config_name={key2} config_value={thirdLevel} config_ref_name={config_ref_name} />
                                        </div>
                                    )
                                    :
                                    <GetGeneratorConfigRow updateConfig={this.updateConfig.bind(this)} editable={editable} generator={selectedGenerator} style={level3Style} key={uniqueKey3} config_keys={uniqueKey3} config_name={key2} config_value={thirdLevel} config_ref_name={config_ref_name} />


                            });
                        } else {
                            cnt2++;
                            let uniqueKey1Heading = uniqueKey1 + "-" + cnt2 + "-";

                            return (cnt2 == 1) ?
                                (
                                    <div key={uniqueKey1Heading}>
                                        <div className='row'>
                                            <div className='col-12' style={level1Heading}>{key}</div>
                                        </div>
                                        <GetGeneratorConfigRow updateConfig={this.updateConfig.bind(this)} editable={true} generator={selectedGenerator} style={level2Style} key={uniqueKey2} config_keys={uniqueKey2} config_name={key1} config_value={secondLevel} config_ref_name={key + "/" + key1} />
                                    </div>
                                )
                                :
                                <GetGeneratorConfigRow updateConfig={this.updateConfig.bind(this)} editable={true} generator={selectedGenerator} style={level2Style} key={uniqueKey2} config_keys={uniqueKey2} config_name={key1} config_value={secondLevel} config_ref_name={key + "/" + key1} />

                        }
                    })
                }
                return <GetGeneratorConfigRow updateConfig={this.updateConfig.bind(this)} editable={true} generator={selectedGenerator} style={level1Style} key={uniqueKey1} config_keys={uniqueKey1} config_name={key} config_value={firstLevel} config_ref_name={key} />

            });
        }


        return (
            <div>

                <h2 className="my-title2">Get Config</h2>
                <p> Fetch and preview the current configuration file from the selected generator.</p>

                {
                    alert.visible && <div className={"alert alert-" + alert.status}>
                        <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} style={{ marginRight: "10px" }} />
                        {alert.msg}
                    </div>
                }

                <FormControl style={{ margin: "5px auto", width: "300px" }}>
                    <InputLabel id="demo-simple-select-helper-label">Select Generator</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedGenerator}
                        onChange={this.handleChangeGenerator}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {generators.map(gen => <MenuItem key={gen.id} value={gen.id}>{gen.id + " ) " + gen.site + " / " + gen.farm + " / " + gen.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>The available Generators</FormHelperText>
                </FormControl>
                <button className="btn btn-primary" onClick={this.fetchConfig} style={fetchButtonStyle}>
                    Fetch
                    {fetching && <span className="spinner-border spinner-border-sm" role="status" style={{ margin: "0px 5px" }} />}
                </button>
                {config_data_table}

            </div>
        );
    }
}

export class GetGeneratorConfigRow extends Component {
    state = {
        editorFormOpened: false,
        savingInProgress: false,
        newValue: this.props.config_value,
        alert: {
            visible: false,
            status: "",
            msg: ""
        }
    }
    openEditForm(e) {
        e.preventDefault();
        this.setState({ editorFormOpened: true })
    }
    closeEditForm(e) {
        const { savingInProgress } = this.state;
        if (!savingInProgress) { //@only close the edit form after the progress is finished 
            this.setState({
                editorFormOpened: false,
                alert: {
                    visible: false,
                    status: "",
                    msg: ""
                }
            })
        }

    }
    setAlert = (status, message) => {
        this.setState({
            alert: {
                visible: true,
                status: status,
                msg: message
            }
        }, () => setTimeout(() => {
            this.setState({
                alert: {
                    visible: false,
                    status: "",
                    msg: ""
                }
            })
        }, 3000)
        )
    }
    handleInput = input => e => {
        this.setState({ [input]: e.target.value })
    }
    saveEditForm(e) {
        this.setState({ savingInProgress: true })
        const { newValue } = this.state
        if (newValue === "") {
            this.setAlert("danger", "Error: the new value is empty")
            this.setState({
                savingInProgress: false,
            })
            return
        }
        const data = {
            gens: [this.props.generator],
            value: newValue
        }
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const url = process.env.REACT_APP_API_URL + "generators/config/" + this.props.config_ref_name
        axios.post(url, data, config)
            .then(response => {
                console.log(response.data)
                // update the config values in the table
                this.updateConfig();
                this.setAlert("success", "The config item successfully updated")
                this.setState({
                    savingInProgress: false,
                    editorFormOpened: false,
                    results: response.data
                });

            })
            .catch(error => {
                this.setState({
                    savingInProgress: false,
                    showResults: false
                })
                console.log(error)
                if (error.response === undefined)
                    return
                console.log(error.response)
                this.setAlert("danger", "Error: " + error.response.data['error'])
            })
    }
    updateConfig() {
        const config_keys = this.props.config_keys.split("-");
        const { newValue } = this.state;
        this.props.updateConfig(config_keys, newValue);
    }
    render() {
        const { editorFormOpened, savingInProgress, alert, newValue } = this.state;
        return (
            (<div style={this.props.style} className="row" >

                <div className="col-6 ">
                    <span style={configKey}>{this.props.config_name} </span>: <span style={configValue}>{this.props.config_value}</span>
                </div>
                <div className="col-lg-6 col-12 text-right">
                    {alert.visible && <span role="alert" style={{ marginLeft: "10px" }} className={"alert alert-" + alert.status} > {alert.msg}</span>}
                    {(!editorFormOpened && this.props.editable) && <FontAwesomeIcon icon={faEdit} size={"lg"} style={{ margin: "7px  0 0 10px" }} className='float-right' onClick={this.openEditForm.bind(this)} />}
                    {editorFormOpened && <span><input
                        className="form-control col-8 d-inline-block"
                        placeholder="Enter new config value"
                        ref="newValue"
                        value={newValue}
                        onChange={this.handleInput("newValue")}

                    />

                        <FontAwesomeIcon icon={faWindowClose} size={"lg"} style={{ margin: "7px 0 0 10px " }} className='float-right' onClick={this.closeEditForm.bind(this)} />
                        {!savingInProgress && <FontAwesomeIcon icon={faSave} size={"lg"} style={{ margin: "7px 0 0 10px" }} className='float-right' onClick={this.saveEditForm.bind(this)} />}
                        {savingInProgress && <LittleSpinner className="float-right" style={{ margin: "7px 0 0 10px" }} />}
                    </span>
                    }

                </div>

            </div>
            )
        );
    }
}
const fetchButtonStyle = {
    margin: "30px 30px",
    padding: "auto 30px"
}

const level1Style = {
    padding: "20px",
    paddingLeft: "30px",
    color: "white",
    border: "1px solid #fff"
}

const level2Style = {
    padding: "20px",
    paddingLeft: "60px",
    color: "white",
    border: "1px solid #fff"
}

const level3Style = {
    padding: "20px",
    paddingLeft: "90px",
    color: "white",
    border: "1px solid #fff"
}
const level1Heading = {
    padding: "20px",
    paddingLeft: "30px",
    color: "white",
    border: "1px solid #fff",
    fontWeight: "bold",
    fontSize: "18px"
}
const level2Heading = {
    padding: "20px",
    paddingLeft: "60px",
    color: "white",
    border: "1px solid #fff",
    fontWeight: "bold",
    fontSize: "16px"
}
const level3Heading = {
    padding: "20px",
    paddingLeft: "60px",
    color: "white",
    border: "1px solid #fff",
    fontWeight: "bold",
    fontSize: "14px"
}
const configKey = {
    color: "white",
    fontWeight: "bold",
}
const configValue = {
    color: "white"
}

export default GetGeneratorConfig;
