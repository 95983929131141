import React, { Component } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ProgressBar } from 'react-bootstrap'
import auth from '../../helpers/auth'
import axios from 'axios'
import qs from 'qs';


export class S3Forms extends Component {
    state ={
        uploadPercentage: 0,
        showUploadCompleteText: false,
        uploadPublicPath: "",
        enableUploadButton: false,
        givenS3Key: "",
        keyPublicPath: "",
        showKeyCompleteText: false,
        copieKey: false,
        copieUpload: false
    }
    setUploadFile = ({target: { files } }) => {
        this.setState({
            file: files[0],
            showUploadCompleteText: false,
            enableUploadButton: true
        })
    }
    uploadFile = () => {
        const {file} = this.state
        let data = new FormData();
        data.append('file', file)
        
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor((loaded * 100) / total)
                console.log( loaded + "Kb of " + total + "Kb | " + percent + "%")
                if(percent < 100 ){
                    this.setState({
                        uploadPercentage: percent
                    })
                }
            }
        }
        axios.post(process.env.REACT_APP_API_URL + 'user2S3', data, config)
        .then(res => {
            console.log(res)
            this.setState({
                uploadPercentage: 100
            }, () => {
                this.setState({
                    uploadPercentage: 0,
                    showUploadCompleteText: true,
                    uploadPublicPath: res.data.public_url,
                    file: {}
                })
                this.props.setAlert("success", "Upload Completed")

            })
        }).catch(error => {
            console.log("ERROR: got error")
            console.log(error)
            console.log("===>", error.response.data.error)
            this.props.setAlert("danger", "Got Error on Upload. Info: Read console")
        });
    }
    onChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    getPublicURl = () => {
        let params={}
        params["s3key"] = this.state.givenS3Key
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` },
            params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }
        axios.get(process.env.REACT_APP_API_URL + 'S3-public', config)
        .then(res => {
            console.log(res)
            this.setState({
                showKeyCompleteText: true,
                keyPublicPath: res.data.public_url,
                // givenS3Key: ""
            })
        }).catch(error => {
            console.log("ERROR: got error")
            console.log(error)
            this.props.setAlert("danger", "Got Error: " + error.response.data.error)
        });
    }
    copied = (key) => {
        console.log("given key ==> ", key)
        if( key === "key")
            this.setState({ copieKey: true })
        else
            this.setState({ copieUpload: true })

        setTimeout(() => this.setState({ copieKey: false, copieUpload: false }), 3000);
    }
    
    render() {
        const { uploadPercentage, showUploadCompleteText, uploadPublicPath, enableUploadButton, givenS3Key, keyPublicPath, showKeyCompleteText } = this.state;
        const enableKeyButton = givenS3Key.length > 0;

        return (
            <div style={rowStyle}>
                <div className="row" >
                    <h2 style={{margin: "auto", fontWeight: "300", fontSize: "1.7rem"}}><FontAwesomeIcon icon={faCloudUploadAlt} size={"xs"} style={{marginRight: "10px"}}/>S3 Forms</h2>
                </div>
                <br/>
                <br/>
                <div className="row text-center">
                    <div className="col">
                        <h2 style={{margin: "auto", fontWeight: "300", fontSize: "1.5rem"}}> Get Public Url</h2>
                        <p>Write the Path of the file on the S3</p>
                        <div className="form-group">
                            <label htmlFor="givenS3Key" className="control-label">S3 Path</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="givenS3Key"
                                    name="givenS3Key"
                                    value={givenS3Key}
                                    onChange={this.onChange}
                                    required autoFocus
                                />
                            </div>
                        </div>
                        <button className={enableKeyButton ? "btn btn-primary" : "btn btn-primary disabled"} onClick={this.getPublicURl}> Submit</button>
                        {showKeyCompleteText && 
                        <div className="alert alert-success text-center" style={{ marginTop: "15px" }} role="alert">
                            <br/>
                            {keyPublicPath}
                            <CopyToClipboard text={keyPublicPath} onCopy={() => this.copied("key")}>
                                <FontAwesomeIcon icon={faCopy} size='lg'id="copy_button" />
                            </CopyToClipboard>
                            {this.state.copieKey && <p>Copied!</p>}
                        </div>}
                    </div>

                    {/* --- */}

                    <div className="col">
                        <h2 style={{margin: "auto", fontWeight: "300", fontSize: "1.5rem"}}> Upload</h2>
                        <div style={{margin: "20px 0"}}>
                            <p>Select a file to upload to the S3</p>
                            <input type="file" style={fileUploaderStyle} className="text-center" onChange={this.setUploadFile}/>
                        </div>

                        <button className={enableUploadButton ? "btn btn-primary" : "btn btn-primary disabled"} onClick={this.uploadFile}> Submit</button>

                        {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={uploadPercentage + "%"} />}

                        {showUploadCompleteText && 
                        <div className="alert alert-success text-center" style={{ marginTop: "15px" }} role="alert">
                            Upload complete! <hr/>
                            <br/>
                            {uploadPublicPath}
                            <CopyToClipboard text={uploadPublicPath} onCopy={() => this.copied("uploadkey")}>
                                <FontAwesomeIcon icon={faCopy} size='lg'id="copy_button" />
                            </CopyToClipboard>
                            {this.state.copieUpload && <p>Copied!</p>}

                        </div>}

                        
                    </div>
                </div>
            </div>
        )
    }
}

const rowStyle = {
    border: "solid 2px #18baba",
    borderRadius: "15px",
    padding:"20px 20px",
    margin: "20px 30px"
}

const fileUploaderStyle = {
    border: "solid 1px #999",
    borderRadius: "5px",
    padding:"20px 20px",
    margin: "0 0"
}


export default S3Forms
