import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import axios from 'axios'
import auth from '../../helpers/auth'
import qs from 'qs';

import ActionDropdown from './ActionDropdown'
import GeneratorsMultiselect from './GeneratorsMultiselect'
import ResultsTable from './ResultsTable';


class GetSetModel extends Component {
    state = {
        selectedAction: "get",
        selectedGenerators: [],
        results: [],
        showResults: false,
        fetching: false,
        alert:{
            visible: false,
            status: "",
            msg: ""
        },
        modelKey: "",
        modelName:""
    }

    setAlert = (status, message) =>{
        this.setState({
                alert: {
                    visible: true,
                    status: status,
                    msg: message
                }
            }, () => setTimeout( () => {
                this.setState({
                    alert: {
                        visible: false,
                        status: "",
                        msg: ""
                    }
                })
            }, 5000)
        )
    }

    handleChangeAction = (event) => {
        const selectedAction = event.target.value
        this.setState({
            selectedAction: selectedAction
        });
    }

    syncSelectedList = (selectedList) => {
        this.setState({
            selectedGenerators: selectedList,
        })
    }

    hideResults = () => {
        this.setState({
            showResults: false
        })
    }

    handleInput = input => e => {
        this.setState({ [input]: e.target.value })
    }

    applyAction = () => {
        const { selectedAction, selectedGenerators } = this.state
        if (selectedGenerators.length === 0 ){
            this.setAlert("warning", "No generators has been selected!")
            return
        }
        let targetGenerators = selectedGenerators.map(gen => gen.id);
        console.log("target generators: ", targetGenerators)
        console.log("APPLING ACTION " + selectedAction)

        this.setState({
            fetching:true,
            showResults: false
        })

        if(selectedAction === "get"){
            const url = process.env.REACT_APP_API_URL + "generators/get/models"
            let params = {}
            params["gens"] = targetGenerators

            axios.get(url, {
                headers: { Authorization: `Bearer ${auth.token}` },
                params,
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then(response => {
                console.log(response.data)
                this.setAlert("success", "Request completed with success")
                this.setState({
                    fetching:false,
                    showResults: true,
                    results: response.data
                })
            })
                .catch(error => {
                    console.log(error)
                    this.setState({
                        fetching: false,
                        showResults: false
                    })
                    if(error.response === undefined)
                        return
                    console.log(error.response)
                    this.setAlert("danger", "Error: " + error.response.data['error'])
                })
        }
        else if(selectedAction === "set"){
            const { modelKey, modelName } = this.state
            if(modelKey === "" || modelName === ""){
                this.setAlert("danger", "Error: model-key or model-name is empty")
                this.setState({
                    fetching:false,
                })
                return
            }
            const data = {
                gens: targetGenerators,
                key: modelKey,
                value: modelName
            }
            const config = {
                headers: { Authorization: `Bearer ${auth.token}` }
            };
            const url = process.env.REACT_APP_API_URL + "generators/set/models"
            axios.post(url, data, config)
                .then(response => {
                    console.log(response.data)
                    this.setAlert("success", "Request completed with success Model info: " + response.data.model_info.key + ": " + response.data.model_info.value )
                    this.setState({
                        fetching: false,
                        showResults: true,
                        results: response.data.responses
                    })
                })
                .catch(error => {
                    this.setState({
                        fetching: false,
                        showResults: false
                    })
                    console.log(error)
                    if(error.response === undefined)
                        return
                    console.log(error.response)
                    this.setAlert("danger", "Error: " + error.response.data['error'])
                })
        }
    }


    render() {
        let { generators } = this.props
        const { selectedAction, modelName, modelKey, results, showResults, alert, selectedGenerators, fetching } = this.state
        const buttonClass = (selectedAction === "set") ? "danger" : "primary"
        const buttonContext = (selectedAction === "set") ? "Apply" : "Fetch"

        return (
            <div>
                <h2 className="my-title2">Get/Set Models</h2>
                <p> Handle the models in multiple generators.  When adding a new model, make sure that:</p>
                <ul>
                    <li>
                        the model has been uploaded before
                    </li>
                    <li>
                        you spell the model name correctly
                    </li>
                </ul>
                <p><em> The new model will take effect when the adapter restarts.</em></p>

                {alert.visible && <div className={"alert alert-" + alert.status}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} style={{marginRight: "10px"}}/>
                    {alert.msg}
                </div>}

                <div className="row">
                    <div className="col-1">
                        <ActionDropdown selectedAction={selectedAction} handleChangeAction={this.handleChangeAction}/>
                    </div>

                    <div className="col-4">
                        <GeneratorsMultiselect
                            syncSelectedList={this.syncSelectedList}
                            selectedGenerators={selectedGenerators}
                            generators = {generators}
                        />
                    </div>

                    {selectedAction === "set" &&
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="modelKey">Model Key</label>
                            <input
                                className="form-control"
                                placeholder="Enter model key"
                                ref="modelKey"
                                value={modelKey}
                                onChange={this.handleInput("modelKey")}
                            />
                            <small className="form-text text-muted">e.g.: rgb_adapter</small>
                        </div>
                        <div className="form-group"> {/** TODO: add a drop down and set the values from the db */}
                            <label htmlFor="modelName">Model Name</label>
                            <input
                                className="form-control"
                                placeholder="Enter model name"
                                ref="modelName"
                                value={modelName}
                                onChange={this.handleInput("modelName")}
                            />
                            <small className="form-text text-muted">e.g.: final_model.pth</small>
                        </div>
                    </div>
                    }
                    <div className="col-2">
                        <button className={"btn btn-" + buttonClass} style={applyButtonStyle} onClick={this.applyAction}>
                            {buttonContext}
                            {fetching && <span className="spinner-border spinner-border-sm" role="status" style={{margin: "0px 5px"}}/> }
                        </button>
                    </div>
                </div>

                {showResults && <ResultsTable results={results} handleHide={this.hideResults}/>}

            </div>
        );
    }
}

const applyButtonStyle = {
    margin: "15px 30px",
    padding: "auto 30px"
}

export default GetSetModel;