import React, { Component } from "react";
import auth from '../helpers/auth'
import { NavLink, Link } from "react-router-dom";


class Navbar extends Component {


	render() {

		let userControl;
		if (!auth.isAuthenticated()) {
			userControl =
				<ul className="navbar-nav ml-auto mt-2 mt-lg-0">
					<li className="nav-item active">
						<NavLink className="nav-link" to="/login">
							Log in
						</NavLink>
					</li>
				</ul>
		} else {
			userControl =
				<ul className="navbar-nav ml-auto mt-2 mt-lg-0">
					<li className="nav-item profile">
						<span className="font-weight-bold"> Hello, </span>
						<div className="btn-group nav-item profile-drop-down ">
							<button
								id="dropdownButton"
								className="btn dropdown-toggle"
								data-toggle="dropdown"
								data-display="static"
								aria-haspopup="true"
								aria-expanded="false"
								style={{ paddingBottom: "8px" }}
							>
								{auth.username}
							</button>
							<ul className="dropdown-menu dropdown-menu-right text-center" style={dropdownListStyle}>
								<li>
									<NavLink to="/user_profile" className="btn my-navlink">Profile</NavLink>
								</li>
								<li>
									<NavLink to="/company_profile" className="btn my-navlink">Company</NavLink>
								</li>
								<li>
									<NavLink
										to="/login"
										className="btn my-navlink"
										onClick={
											() => {
												auth.logout()
												this.props.updateAuth(false)
											}
										}
									>
										Log out
									</NavLink>
								</li>
							</ul>

						</div>
					</li>
				</ul>
		}

		return (
			<div id="navigation-bar">

				<nav
					className=" navbar navbar-expand-lg navbar-dark border-bottom-yellow fixed-top"
					style={NavbarStyle}>

					<Link to="/dashboard">
						<img
							src={process.env.PUBLIC_URL + '/logo_transparent.png'}
							height="73"
							alt="Digisec logo"
						/></Link>

					{/**Burger code */}
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse" id="navbarSupportedContent">

						{userControl}

					</div>
				</nav>
			</div>
		);
	}
}

const NavbarStyle = {
	padding: ".53rem 1rem",

};

const dropdownListStyle = {
	padding: "0",
	backgroundColor: " #f8f9fa",
	color: "#212529"
}

export default Navbar;