import React, { Component } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

class ResultsTable extends Component {
    state = {  }
    render() { 
        const { results } = this.props
        return ( 
            <div  style={{"marginTop": "10px", "width": "100%"}}>
                <table className="table text-center">
                    <tbody style={wordBreak}>
                        <tr style={normalBreak}>
                            <th>Found</th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Success</th>
                            <th>Status Code</th>
                            <th>Message</th>
                        </tr>
                            {results.map(res => {
                                return <tr key={res.id} style={{wordBreak: "normal"}}>
                                    <td ><FontAwesomeIcon icon={res.found ? faCheck : faTimes} size={"sm"} style={{marginRight: "5px"}}/> </td>
                                    { res.found && <td>{res.id}</td>}
                                    { res.found && <td>{res.name}</td>}
                                    { res.found && <td ><FontAwesomeIcon icon={res.success ? faCheck : faTimes} size={"sm"} style={{marginRight: "5px"}}/> </td>}
                                    { res.found && <td>{res.status_code}</td>}
                                    { res.found && <td style={{backgroundColor:"#CAE4F1", maxWidth:"300px"}}>{<pre>{JSON.stringify(res.message, null, 2)}</pre>}</td>}
                                </tr>
                            })}
                    </tbody>
                </table>
                <button className="btn btn-link float-right sm" onClick={this.props.handleHide}>Hide</button>
            </div>
         );
    }
}


const normalBreak = {
    wordBreak: "normal",
    backgroundColor: "#f4f4f6"

}

const wordBreak = {
    wordBreak: "break-word"
}
 
export default ResultsTable ;