import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'

export class StatsLine extends Component {

    render() {
        const chartData = {
            labels: this.props.labels,
            datasets: []
        }
        if (this.props.data.detections) {
            chartData.datasets.push(
                {
                    data: this.props.data.detections,
                    fill: true,
                    backgroundColor: '#00FF0055',
                    borderColor: '#00FF00',
                    lineTension: 0
                }

            );
        }
        if (this.props.data.deterrences) {
            chartData.datasets.push(
                {
                    data: this.props.data.deterrences,
                    fill: true,
                    backgroundColor: '#0000FF55',
                    borderColor: '#0000FF',
                    lineTension: 0
                }

            );
        }
        if (this.props.data.shutdowns) {
            chartData.datasets.push(
                {
                    data: this.props.data.shutdowns,
                    fill: true,
                    backgroundColor: '#FF000055',
                    borderColor: '#FF0000',
                    lineTension: 0
                }

            );
        }
        return (
            <div className="bar-chart">
                <Line
                    data={chartData}
                    options={{
                        maintainAspectRatio: true,
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Deterrence Per Hour',
                            lineHeight: 1.7,
                            fontColor: "#efefef"
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    fontColor: "#efefef"
                                }
                            }],
                            xAxes: [{
                                ticks: { fontColor: "#efefef" }
                            }]
                        }
                    }}
                />
            </div>
        )
    }
}

export default StatsLine
