import React, { Component } from 'react'

export class CoefsModal extends Component {

    render() {
        const {coefs} = this.props
        const header = 
            <div>
                <h1 style={{"fontWeight": 300, "fontSize": "2rem"}}> Event Coefficients </h1>
                <hr className="my-Hr"/>
            </div>

        let content;
        if (coefs == undefined){
            content = 
                <div style={{padding: "15px 100px"}}>
                    <p>Sorry, it is NULL</p>
                </div>
        }
        else{
            let coefArray = coefs.split("|")
            console.log(coefArray)
            console.log(coefArray.length)
            
            let infCoefs = coefArray[0].split(";");
            let trackCoefs = coefArray[1].split(";");
            content = 
                <div style={{padding: "15px 100px"}}>
                    {infCoefs.map(coef => {
                        return  <p>{coef}</p>
                    })}
                    <hr className="my-Hr"/>
                    {trackCoefs.map(coef => {
                        return  <p>{coef}</p>
                    })}
               </div>
        }

        return (
            <div>
                {header}
                {content}
                

            </div>
                
        )
    }
}

// 

export default CoefsModal
