import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faSignal, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import AlgorithmSettingsSlider from '../layout/AlgorithmSettingsSlider';
import CriteriaSettingsSlider from '../layout/CriteriaSettingsSlider';
import axios from 'axios';

import auth from '../helpers/auth'
import Spinner from '../Spinner'
import Filters from '../Filters';
import { useEffect, useState } from 'react';
import LittleSpinner from '../LittleSpinner';
import { smoothToTop } from '../helpers/misc'
const GeneratorAlgorithmSettings = function(){
    const [spinner, setSpinner] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [algorithmData, setAlgorithmData] = useState([]);
    const [savingGeneratorAlgorithm, setSavingGeneratorAlgorithm] = useState([]);
    // const [reloadingGeneratorIds, setReloadingGeneratorIds] = useState([]);
    useEffect(() => {
        document.title = "BMS App - Wind Turbine Algorithm Status"
    });
    /**
     * remove the generator from filter
     */
    const removeGeneratorFromFilter = (removedItem) => {
        let _algorithmData = algorithmData.filter(generator => generator.id !==  removedItem.id)
        setAlgorithmData(_algorithmData);
    }

    /**
     * get algorithm settings based on the id
     */
    const getGeneratorAlgorithmSettings = (selectedCompanies, selectedSites, selectedFarms, selectedGenerators) => {
        setSpinner(true);
    
        let generator_id = selectedGenerators[selectedGenerators.length - 1].id;
        getGeneratorAlgorithmSettingsData(generator_id);
    }
    const saveAlgorithmSettings = (generator_id) => {
        smoothToTop();
        //console.log(generator_id);
        // savingGeneratorAlgorithm.concat(generator_id);
        // setSavingGeneratorAlgorithm(savingGeneratorAlgorithm);
        setSavingGeneratorAlgorithm(setSavingGeneratorAlgorithm => [...setSavingGeneratorAlgorithm, generator_id]);
        setSaveInProgress(true);
        setAlertMessage("");
        console.log('saving');
        console.log(savingGeneratorAlgorithm);
        const requestRoute = process.env.REACT_APP_API_URL + "generators/save-algorithm-settings";
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        const data = {
            generator: generator_id,
            min_wingspan: editedGenerator.algo_settings.min_wingspan,
            max_wingspan: editedGenerator.algo_settings.max_wingspan,
            stop_time: editedGenerator.algo_settings.stop_time,
            algo_intensity: editedGenerator.algo_settings.algo_intensity,
            camera_bats_count: editedGenerator.algo_settings.camera_bats_count,
            camera_timer: editedGenerator.algo_settings.camera_timer,
            detection_intensity: editedGenerator.algo_settings.detection_intensity,
        };
        // console.log(data);
        axios.post(requestRoute, data, {
          headers: { Authorization: `Bearer ${auth.token}` }
        }).then(response => {
            // remove generator id from saving 
            setSavingGeneratorAlgorithm(savingGeneratorAlgorithm.filter(generatorId => generatorId !== generator_id));
            setSaveInProgress(false);
            if( response.data.error ){
                console.log('error')
                setAlertMessage("Error Saving Data");
            } else {
                console.log('success');
                setAlertMessage("Successfully saved");
            }
        }) .catch(error => {
            console.log(error);
           setSavingGeneratorAlgorithm(savingGeneratorAlgorithm.filter(generatorId => generatorId !== generator_id));
           setSaveInProgress(false);
        });
    }
    const getGeneratorAlgorithmSettingsData = (generator_id, reload) => {
        const requestRoute = process.env.REACT_APP_API_URL + "generators/" + generator_id + "/algorithm-settings";
    
        axios.get(requestRoute, {
          headers: { Authorization: `Bearer ${auth.token}` }
        }).then(response => {
          let newData = algorithmData;
    
          // events.map(event => event['generator'] = this.state.selectedGenerators.filter(gen => gen.id === event.generator_id)[0])
        //   let newReloadingGeneratorIds = reloadingGeneratorIds;
        //   if (reload) {
        //     newData = newData.map((gen) => {
        //       if (gen.generator.id == generator_id) {
        //         gen = response.data;
        //       }
        //       return gen;
        //     });
        //     newReloadingGeneratorIds = newReloadingGeneratorIds.filter(genId => genId !== generator_id)
        //   } else {
            newData.push(response.data)
        //   }
          setSpinner(false);
          setAlgorithmData(newData);
        //   setReloadingGeneratorIds(newReloadingGeneratorIds);

        })
          .catch(error => {
            // this.setAlert("danger", "Errorfull request")
            console.log("Generator Not reachable")
            console.log(error.response)
            setSpinner(false);
          })
      }
    /**
     * when slider changed the value
     * called from slider child component
     */
    const onMinWingSpanChanged = (generator_id, min_wingspan) => {
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        editedGenerator.algo_settings.min_wingspan = min_wingspan;
        // setAlgorithmData(algorithmData);
    }
    const onMaxWingSpanChanged = (generator_id, max_wingspan) => {
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        editedGenerator.algo_settings.max_wingspan = max_wingspan;
        
    }
    const onStopTimeChanged = (generator_id, stop_time) => {
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        editedGenerator.algo_settings.stop_time = stop_time;
    }
    const onAlgoIntensityChanged = (generator_id, algo_intensity) => {
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        editedGenerator.algo_settings.algo_intensity = algo_intensity;
    }

    const onCameraBatsCountChanged = (generator_id, camera_bats_count) => {
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        editedGenerator.algo_settings.camera_bats_count = camera_bats_count;
    }
    const onCameraTimerChanged = (generator_id, camera_timer) => {
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        editedGenerator.algo_settings.camera_timer = camera_timer;
    }
    const onDetectionIntensityChanged = (generator_id, detection_intensity) => {
        let editedGenerator = algorithmData.filter(generator => generator.id === generator_id)[0];
        editedGenerator.algo_settings.detection_intensity = detection_intensity;
    }
    
    let results = <h2 className="no-data">No generators found</h2>
    let minWingspanIcon = <img src={process.env.PUBLIC_URL + '/bird.png'} alt="Bird" style={imgStyleSmall} />;
    let maxWingSpanIcon = <img src={process.env.PUBLIC_URL + '/bird.png'} alt="Bird" style={imgStyle} />;
    let generatorIcon = <img src={process.env.PUBLIC_URL + '/generator.png'} alt="Generator" style={imgStyle} />;
    let intensityIcon =  <FontAwesomeIcon icon={faTachometerAlt} size={"sm"} style={imgStyleSmall}/>
    let cameraBatIcon =  <img src={process.env.PUBLIC_URL + '/bats.png'} alt="Bats" style={imgStyle} />;
    let timerIcon =  <FontAwesomeIcon icon={faClock} size={"sm"} style={imgStyleSmall}/>
    let detectionIntensityIcon =  <FontAwesomeIcon icon={faSignal} size={"sm"} style={imgStyleSmall}/>
    console.log("length", algorithmData.length);
    if( algorithmData.length > 0 ){    
        results =  algorithmData.map( generator =>
            <div key={generator.id}>
                <h2>{generator.name} {savingGeneratorAlgorithm.includes(generator.id) }</h2>
                <h3>WIND TURBINE ALGORITHM SETTINGS</h3>
                <AlgorithmSettingsSlider measure_label="cm" generator_id ={generator.id} onChange = { (gid, min) => { onMinWingSpanChanged(gid, min) }} value={generator.algo_settings.min_wingspan} minimum={20} maximum={300} title="Minimum Wingspan:" icon={minWingspanIcon} />
                <AlgorithmSettingsSlider measure_label="cm" generator_id ={generator.id} onChange = { (gid, max) => { onMaxWingSpanChanged(gid, max) }}  value={generator.algo_settings.max_wingspan} minimum={20} maximum={300} title="Maximum Wingspan:" icon={maxWingSpanIcon} />
                <AlgorithmSettingsSlider measure_label="sec" generator_id ={generator.id} onChange = { (gid, time) => { onStopTimeChanged(gid, time) }}  value={generator.algo_settings.stop_time} minimum={5} maximum={50} title="WTG Stop Time:" icon={generatorIcon} />
                <AlgorithmSettingsSlider generator_id ={generator.id}  onChange = { (gid, intensity) => { onAlgoIntensityChanged(gid, intensity) }}   value={generator.algo_settings.algo_intensity} title="Algorithm Intensity:" icon={intensityIcon} />
                {generator.thermal_camera_found
                ? <div>
                    <h3>Bat Activity WTG Pause Creteria Settings</h3>
                    <CriteriaSettingsSlider measure_label="bats"  generator_id ={generator.id}  onChange = { (gid, camera_bats_count) => { onCameraBatsCountChanged(gid, camera_bats_count) }}   value={generator.algo_settings.camera_bats_count} title="Bats in the Camera Field of View" icon={cameraBatIcon} />
                    
                    <CriteriaSettingsSlider measure_label="sec" generator_id ={generator.id}  onChange = { (gid, camera_timer) => { onCameraTimerChanged(gid, camera_timer) }}   value={generator.algo_settings.camera_timer} title="Time in the Camera Field of View" icon={timerIcon} />
                    <CriteriaSettingsSlider measure_label="%" generator_id ={generator.id}  onChange = { (gid, detection_intensity) => { onDetectionIntensityChanged(gid, detection_intensity) }}   value={generator.algo_settings.detection_intensity} title="Detection intensity" icon={detectionIntensityIcon} />
                    
                </div>
                : null}
                {savingGeneratorAlgorithm.includes(generator.id) && saveInProgress
                ?  <button className="btn btn-primary float-right" ><LittleSpinner></LittleSpinner> Save</button>
                : <button className="btn btn-primary float-right" onClick={() => saveAlgorithmSettings( generator.id)}>Save</button>
                }
            <br clear="all" />
            </div>
        ) 
        
    }

    return (
        <div style={{ "marginTop": "10px", "width": "100%" }} className="container-inner-wrapper">
            <h2 className="my-title2">Wind Turbine Algorithm Settings</h2>
            <hr />
            <Filters showGenerators="{true}" onGeneratorRemove={(removedItem) => {removeGeneratorFromFilter(removedItem) }} onGeneratorSelect={(c, s, f, g) => { getGeneratorAlgorithmSettings(c, s, f, g) }} />
            {saveInProgress && <div className='alert alert-info' ><LittleSpinner /> Saving Data...</div>}
            {saveInProgress == false && alertMessage != "" && <div className="alert alert-success">{alertMessage}</div>}
            {results}
            {spinner && <Spinner />}
            
            
      </div>
    );
}

const imgStyle = {
    borderColor: "transparent",
    width: "100px",
    height: "100px",
    maxWidth: "100%",
    minWidth: "100px"
}
const imgStyleSmall = {
    borderColor: "transparent",
    width: "70px",
    height: "70px",
    maxWidth: "100%",
    minWidth: "70px"
}

export default GeneratorAlgorithmSettings;