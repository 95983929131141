import React, {Component} from 'react';
import FarmsPerSite from "../FarmsPerSite";
import Spinner from '../Spinner'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";



class Reports extends Component {

    state = {
        alert: false,
        alertClass: '',
        alertMsg: '',
        selectedData: [],
        spinner: true,
        file: null,
    }

    componentDidMount() {
        this.convertData()
    }

    setAlert = (type, msg) => {
        this.setState({
            alert: true,
            alertClass: "alert alert-" + type + " text-center",
            alertMsg: msg,
        })
        setTimeout(() => this.setState({
            alert: false,
            alertClass: '',
            alertMsg: '',
        }), 3000)
    }

    convertData() {
        let { companies } = this.props
        let selectedData = []
        selectedData['companies'] = []
        companies.map((company) => {
            if (companies.includes(company)){
                let companyDict = {}
                companyDict['show'] = false
                companyDict['id'] = company.id
                companyDict['name'] = company.name
                companyDict['sites'] = []
                company['children'].map((site) => {
                    let siteDict = {}
                    siteDict['id'] = site.id
                    siteDict['name'] = site.name
                    siteDict['farms'] = []
                    siteDict['total_generators'] = []
                    site['children'].map((farm) => {
                        let farmDict = {}
                        farmDict['id'] = farm.id
                        farmDict['name'] = farm.name
                        farmDict['generators'] = []
                        farm['children'].map((generator) => {
                            companyDict['show'] = true
                            siteDict['total_generators'].push(generator)
                            farmDict['generators'].push(generator)
                        })
                        siteDict['farms'].push(farmDict)
                    })
                    companyDict['sites'].push(siteDict)
                })
                selectedData['companies'].push(companyDict)
            }
        })
        this.setState({
            selectedData: selectedData,
            spinner: false
        }, () => console.log(this.state))
    }

    render() {

        let alert = '';
        if (this.state.alert)
            alert = <div className={this.state.alertClass} style={{ margin: "2% 20%" }} role="alert">{this.state.alertMsg}</div>
        
        const { selectedData, spinner } = this.state
        const { sites, farms, date } = this.props

        const siteIds = sites.map(site => site.id)
        if (spinner)
            return (
                <Spinner />
            )

        let results = <h2 className="no-data">No data available</h2>
        let companies = selectedData['companies']
        console.log("companies", companies)
        if (companies.length > 0) {
            results =
                <div>
                    {companies.map((company, index) =>
                        <div key={index}>
                            { company['show'] === true && <h2 style={titleStyle}> <span style={spanStyle}>Company: </span> {company.name} </h2>}
                            { company.sites.length > 0 ? 
                            company.sites.map((site) => 
                                siteIds.includes(site.id) &&
                                site['total_generators'].length > 0 &&
                                <FarmsPerSite site={site} item={"reports"} id={site.id} key={site.id} company_name={company.name} selectedFarms={farms} date={date} generators={site['total_generators']} eventTypes={this.props.eventTypes} />
                            )
                            :  <h2 className="no-data">No sites found</h2>
                            }

                        </div>
                    )}
                </div >
        }
        return (
            <div id="divToPrint">
                <div className="row" >
                    <h2 className="col my-title"> Statistics </h2>
                </div>
                <hr />
                <div>
                    {results}
                </div>
                <div className="text-center">
                    <div>{alert}</div>
                    <button className="btn btn-primary" 
                        onClick={() => this.props.downloadReport()}
                    >
                        <span className="d-none d-lg-inline">Download Excel</span>
                        <FontAwesomeIcon icon={faFileExcel} style={{ marginLeft: "5px" }} />
                    </button>
                </div>
            </div>
        );
    }
}

const titleStyle = {
    fontWeight: "200",
    fontSize: "2rem",
    color:"#000"
}

const spanStyle = {"color": "#555"}



export default Reports;
