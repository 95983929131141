import React, { Component } from 'react'
import { getStrongPassword } from '../../helpers/misc'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";



export class FormUserDetails extends Component {
    /**
     * This form stores a simple user or a client admin of a company
     * according to the calling component.
     */

    state = {
        copied: false
    }

    previous = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    getPassword = () => {
        let strong_pass = getStrongPassword(15)
        this.props.setPassword(strong_pass)
    }

    copied = () => {
        this.setState({ copied: true })
        setTimeout(() => this.setState({ copied: false }), 3000);
    }

    validate() {
        const { values } = this.props

        if (
            values.username.length > 0 &&
            values.speciality.length > 0 &&
            values.firstName.length > 0 &&
            values.lastName.length > 0 &&
            values.email.length > 0 &&
            values.password.length > 0
        )
            return true
        else return false
    }

    continue = e => {
        e.preventDefault();
        this.validate() ? this.props.register() : this.props.setAlert('alert alert-danger', 'All the fields should be filled')
    }

    render() {
        const { values, handleChange, step } = this.props

        let copy_info
        if (this.state.copied) {
            if (values.password.length === 0)
                copy_info = <span> Password is empty! </span>
            else
                copy_info = <span> Copied! </span>
        }

        // Fix button's content and position
        let registerButton
        let buttonContent
        if (step) {
            buttonContent = 
                <div>
                    <span className="d-none d-lg-inline">Continue   </span>
                    <FontAwesomeIcon icon={faAngleDoubleRight} style={{ marginLeft: "5px" }} />
                </div>
            registerButton =
                <button className="btn btn-primary float-right" onClick={this.continue} >
                    {buttonContent}
                </button>
        }
        else {
            buttonContent = <span>Add User</span>
            registerButton =
                <div className="row d-flex">
                    <button className="btn btn-primary mx-auto register-button" onClick={this.continue}>
                        {buttonContent}
                    </button>
                </div>
        }

        return (
            <div className="form-horizontal">


                {/* <TextField
                        hintText="Enter main user's username"
                        floatingLabelText="Usenname"
                        onChange={handleChange('username')}
                        defaultValue={values.username}
                    /> */}

                <div className="form-group">
                    <label htmlFor="username" className="control-label">Username</label>
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            ref="username"
                            name="username"
                            value={values.username}
                            onChange={handleChange('username')}
                            required autoFocus
                        />
                    </div>
                </div>

                <br />
                {/* <TextField
                        hintText="About the  user"
                        floatingLabelText="Speciality"
                        onChange={handleChange('speciality')}
                        defaultValue={values.speciality}
                    /> */}

                <div className="form-group">
                    <label htmlFor="speciality" className="control-label">Position</label>
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            ref="speciality"
                            name="speciality"
                            value={values.speciality}
                            onChange={handleChange('speciality')}
                            required autoFocus
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        {/* <TextField
                                hintText="Enter main user's First Name"
                                floatingLabelText="First Name"
                                onChange={handleChange('firstName')}
                                defaultValue={values.firstName}
                            />
                            <TextField
                                hintText="Enter main user's Last Name"
                                floatingLabelText="Last Name"
                                onChange={handleChange('lastName')}
                                defaultValue={values.lastName}
                            /> */}

                        <div className="form-group">
                            <label htmlFor="firstName" className="control-label">First Name</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="firstName"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}
                                    required autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col">

                        <div className="form-group">
                            <label htmlFor="lastName" className="control-label">Last Name</label>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ref="lastName"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange('lastName')}
                                    required autoFocus
                                />
                            </div>
                        </div>

                    </div>
                </div>


                {/* <TextField
                        hintText="Enter main user's email"
                        floatingLabelText="Email"
                        onChange={handleChange('email')}
                        defaultValue={values.email}
                    /> */}
                <div className="form-group">
                    <label htmlFor="email" className="control-label">Email</label>
                    <div>
                        <input
                            className="form-control"
                            ref="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange('email')}
                            required autoFocus
                            type="email"
                        />
                    </div>
                </div>

                {/* <TextField
                        hintText="Password"
                        // floatingLabelText="Auto generated password"
                        onChange={handleChange('password')}
                        defaultValue={values.password}
                        id="password_input"
                    /> */}

                <div className="form-group">
                    <label htmlFor="password" className="control-label">Password</label>
                    <div className="row">
                        <div className="col-8">
                            <input
                                type="text"
                                className="form-control"
                                ref="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange('password')}
                                required autoFocus
                            />

                        </div>

                        <div className="col-4">
                            <CopyToClipboard text={values.password} onCopy={this.copied}>
                                <FontAwesomeIcon icon={faCopy} size='lg'id="copy_button" />
                            </CopyToClipboard>

                            <button className="btn btn-primary" onClick={this.getPassword}>
                                Get Password
                                </button>
                            <br />
                            {copy_info}
                        </div>
                    </div>
                </div>


                <br />
                <br />
                {/* <button className="btn btn-primary float-left" onClick={this.previous} >
                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "5px" }} />
                    <span className="d-none d-lg-inline">Previous</span>
                </button> */}

                {registerButton}
                <br/>
                <div className="clearfix" />

                <div className="text-center">
                    <img
                        src={process.env.PUBLIC_URL + '/default_user_logo.png'}
                        alt="default company logo"
                        style={this.props.imgStyle}
                    />
                </div>
            </div>
        )
    }
}

export default FormUserDetails
